import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  timestamp: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

/** columns and relationships of "CookingStyle" */
export type CookingStyle = {
  __typename?: "CookingStyle";
  /** An array relationship */
  Ingredient_CookingStyles: Array<Ingredient_CookingStyles>;
  /** An aggregate relationship */
  Ingredient_CookingStyles_aggregate: Ingredient_CookingStyles_Aggregate;
  id: Scalars["uuid"];
  name: Scalars["String"];
};

/** columns and relationships of "CookingStyle" */
export type CookingStyleIngredient_CookingStylesArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

/** columns and relationships of "CookingStyle" */
export type CookingStyleIngredient_CookingStyles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

/** aggregated selection of "CookingStyle" */
export type CookingStyle_Aggregate = {
  __typename?: "CookingStyle_aggregate";
  aggregate?: Maybe<CookingStyle_Aggregate_Fields>;
  nodes: Array<CookingStyle>;
};

/** aggregate fields of "CookingStyle" */
export type CookingStyle_Aggregate_Fields = {
  __typename?: "CookingStyle_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<CookingStyle_Max_Fields>;
  min?: Maybe<CookingStyle_Min_Fields>;
};

/** aggregate fields of "CookingStyle" */
export type CookingStyle_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CookingStyle_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "CookingStyle". All fields are combined with a logical 'AND'. */
export type CookingStyle_Bool_Exp = {
  Ingredient_CookingStyles?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
  Ingredient_CookingStyles_aggregate?: InputMaybe<Ingredient_CookingStyles_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CookingStyle_Bool_Exp>>;
  _not?: InputMaybe<CookingStyle_Bool_Exp>;
  _or?: InputMaybe<Array<CookingStyle_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CookingStyle" */
export enum CookingStyle_Constraint {
  /** unique or primary key constraint on columns "id" */
  CookingStylePkey = "CookingStyle_pkey",
}

/** input type for inserting data into table "CookingStyle" */
export type CookingStyle_Insert_Input = {
  Ingredient_CookingStyles?: InputMaybe<Ingredient_CookingStyles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CookingStyle_Max_Fields = {
  __typename?: "CookingStyle_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type CookingStyle_Min_Fields = {
  __typename?: "CookingStyle_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "CookingStyle" */
export type CookingStyle_Mutation_Response = {
  __typename?: "CookingStyle_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CookingStyle>;
};

/** input type for inserting object relation for remote table "CookingStyle" */
export type CookingStyle_Obj_Rel_Insert_Input = {
  data: CookingStyle_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CookingStyle_On_Conflict>;
};

/** on_conflict condition type for table "CookingStyle" */
export type CookingStyle_On_Conflict = {
  constraint: CookingStyle_Constraint;
  update_columns?: Array<CookingStyle_Update_Column>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

/** Ordering options when selecting data from "CookingStyle". */
export type CookingStyle_Order_By = {
  Ingredient_CookingStyles_aggregate?: InputMaybe<Ingredient_CookingStyles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CookingStyle */
export type CookingStyle_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "CookingStyle" */
export enum CookingStyle_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "CookingStyle" */
export type CookingStyle_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "CookingStyle" */
export type CookingStyle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CookingStyle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CookingStyle_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "CookingStyle" */
export enum CookingStyle_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type CookingStyle_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CookingStyle_Set_Input>;
  /** filter the rows which have to be updated */
  where: CookingStyle_Bool_Exp;
};

/** columns and relationships of "Dish" */
export type Dish = {
  __typename?: "Dish";
  /** An object relationship */
  DishCategory?: Maybe<DishCategory>;
  /** An array relationship */
  Preps: Array<Prep>;
  /** An aggregate relationship */
  Preps_aggregate: Prep_Aggregate;
  category_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamp"];
  id: Scalars["uuid"];
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamp"];
};

/** columns and relationships of "Dish" */
export type DishPrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "Dish" */
export type DishPreps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "DishCategory" */
export type DishCategory = {
  __typename?: "DishCategory";
  /** An array relationship */
  Dishes: Array<Dish>;
  /** An aggregate relationship */
  Dishes_aggregate: Dish_Aggregate;
  created_at: Scalars["timestamp"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at: Scalars["timestamp"];
};

/** columns and relationships of "DishCategory" */
export type DishCategoryDishesArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

/** columns and relationships of "DishCategory" */
export type DishCategoryDishes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

/** aggregated selection of "DishCategory" */
export type DishCategory_Aggregate = {
  __typename?: "DishCategory_aggregate";
  aggregate?: Maybe<DishCategory_Aggregate_Fields>;
  nodes: Array<DishCategory>;
};

/** aggregate fields of "DishCategory" */
export type DishCategory_Aggregate_Fields = {
  __typename?: "DishCategory_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<DishCategory_Max_Fields>;
  min?: Maybe<DishCategory_Min_Fields>;
};

/** aggregate fields of "DishCategory" */
export type DishCategory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DishCategory_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "DishCategory". All fields are combined with a logical 'AND'. */
export type DishCategory_Bool_Exp = {
  Dishes?: InputMaybe<Dish_Bool_Exp>;
  Dishes_aggregate?: InputMaybe<Dish_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<DishCategory_Bool_Exp>>;
  _not?: InputMaybe<DishCategory_Bool_Exp>;
  _or?: InputMaybe<Array<DishCategory_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "DishCategory" */
export enum DishCategory_Constraint {
  /** unique or primary key constraint on columns "id" */
  DishCategoryPkey = "DishCategory_pkey",
}

/** input type for inserting data into table "DishCategory" */
export type DishCategory_Insert_Input = {
  Dishes?: InputMaybe<Dish_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type DishCategory_Max_Fields = {
  __typename?: "DishCategory_max_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** aggregate min on columns */
export type DishCategory_Min_Fields = {
  __typename?: "DishCategory_min_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** response of any mutation on the table "DishCategory" */
export type DishCategory_Mutation_Response = {
  __typename?: "DishCategory_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<DishCategory>;
};

/** input type for inserting object relation for remote table "DishCategory" */
export type DishCategory_Obj_Rel_Insert_Input = {
  data: DishCategory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<DishCategory_On_Conflict>;
};

/** on_conflict condition type for table "DishCategory" */
export type DishCategory_On_Conflict = {
  constraint: DishCategory_Constraint;
  update_columns?: Array<DishCategory_Update_Column>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

/** Ordering options when selecting data from "DishCategory". */
export type DishCategory_Order_By = {
  Dishes_aggregate?: InputMaybe<Dish_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DishCategory */
export type DishCategory_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "DishCategory" */
export enum DishCategory_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "DishCategory" */
export type DishCategory_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** Streaming cursor of the table "DishCategory" */
export type DishCategory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DishCategory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DishCategory_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** update columns of table "DishCategory" */
export enum DishCategory_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type DishCategory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DishCategory_Set_Input>;
  /** filter the rows which have to be updated */
  where: DishCategory_Bool_Exp;
};

/** aggregated selection of "Dish" */
export type Dish_Aggregate = {
  __typename?: "Dish_aggregate";
  aggregate?: Maybe<Dish_Aggregate_Fields>;
  nodes: Array<Dish>;
};

export type Dish_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dish_Aggregate_Bool_Exp_Count>;
};

export type Dish_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dish_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Dish_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Dish" */
export type Dish_Aggregate_Fields = {
  __typename?: "Dish_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Dish_Max_Fields>;
  min?: Maybe<Dish_Min_Fields>;
};

/** aggregate fields of "Dish" */
export type Dish_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dish_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Dish" */
export type Dish_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dish_Max_Order_By>;
  min?: InputMaybe<Dish_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Dish" */
export type Dish_Arr_Rel_Insert_Input = {
  data: Array<Dish_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dish_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Dish". All fields are combined with a logical 'AND'. */
export type Dish_Bool_Exp = {
  DishCategory?: InputMaybe<DishCategory_Bool_Exp>;
  Preps?: InputMaybe<Prep_Bool_Exp>;
  Preps_aggregate?: InputMaybe<Prep_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Dish_Bool_Exp>>;
  _not?: InputMaybe<Dish_Bool_Exp>;
  _or?: InputMaybe<Array<Dish_Bool_Exp>>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dish" */
export enum Dish_Constraint {
  /** unique or primary key constraint on columns "id" */
  DishPkey = "Dish_pkey",
}

/** input type for inserting data into table "Dish" */
export type Dish_Insert_Input = {
  DishCategory?: InputMaybe<DishCategory_Obj_Rel_Insert_Input>;
  Preps?: InputMaybe<Prep_Arr_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Dish_Max_Fields = {
  __typename?: "Dish_max_fields";
  category_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** order by max() on columns of table "Dish" */
export type Dish_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dish_Min_Fields = {
  __typename?: "Dish_min_fields";
  category_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** order by min() on columns of table "Dish" */
export type Dish_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Dish" */
export type Dish_Mutation_Response = {
  __typename?: "Dish_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Dish>;
};

/** input type for inserting object relation for remote table "Dish" */
export type Dish_Obj_Rel_Insert_Input = {
  data: Dish_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dish_On_Conflict>;
};

/** on_conflict condition type for table "Dish" */
export type Dish_On_Conflict = {
  constraint: Dish_Constraint;
  update_columns?: Array<Dish_Update_Column>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

/** Ordering options when selecting data from "Dish". */
export type Dish_Order_By = {
  DishCategory?: InputMaybe<DishCategory_Order_By>;
  Preps_aggregate?: InputMaybe<Prep_Aggregate_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Dish */
export type Dish_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Dish" */
export enum Dish_Select_Column {
  /** column name */
  CategoryId = "category_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "Dish" */
export type Dish_Set_Input = {
  category_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** Streaming cursor of the table "Dish" */
export type Dish_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dish_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dish_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** update columns of table "Dish" */
export enum Dish_Update_Column {
  /** column name */
  CategoryId = "category_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Dish_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dish_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dish_Bool_Exp;
};

/** columns and relationships of "Ingredient" */
export type Ingredient = {
  __typename?: "Ingredient";
  /** An array relationship */
  Prep_Ingredients: Array<Prep_Ingredients>;
  /** An aggregate relationship */
  Prep_Ingredients_aggregate: Prep_Ingredients_Aggregate;
  ambient_temp_time?: Maybe<Scalars["Int"]>;
  availability: Scalars["String"];
  avg_item_quantity?: Maybe<Scalars["Int"]>;
  can_split: Scalars["Boolean"];
  consistency: Scalars["String"];
  difficulty: Scalars["String"];
  id: Scalars["uuid"];
  measure_unit: Scalars["String"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Int"];
  type: Scalars["String"];
};

/** columns and relationships of "Ingredient" */
export type IngredientPrep_IngredientsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

/** columns and relationships of "Ingredient" */
export type IngredientPrep_Ingredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

/** columns and relationships of "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles = {
  __typename?: "Ingredient_CookingStyles";
  /** An object relationship */
  CookingStyle: CookingStyle;
  cooking_style_id: Scalars["uuid"];
  ingredient_id: Scalars["uuid"];
};

/** aggregated selection of "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Aggregate = {
  __typename?: "Ingredient_CookingStyles_aggregate";
  aggregate?: Maybe<Ingredient_CookingStyles_Aggregate_Fields>;
  nodes: Array<Ingredient_CookingStyles>;
};

export type Ingredient_CookingStyles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ingredient_CookingStyles_Aggregate_Bool_Exp_Count>;
};

export type Ingredient_CookingStyles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Aggregate_Fields = {
  __typename?: "Ingredient_CookingStyles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ingredient_CookingStyles_Max_Fields>;
  min?: Maybe<Ingredient_CookingStyles_Min_Fields>;
};

/** aggregate fields of "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ingredient_CookingStyles_Max_Order_By>;
  min?: InputMaybe<Ingredient_CookingStyles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Arr_Rel_Insert_Input = {
  data: Array<Ingredient_CookingStyles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ingredient_CookingStyles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Ingredient_CookingStyles". All fields are combined with a logical 'AND'. */
export type Ingredient_CookingStyles_Bool_Exp = {
  CookingStyle?: InputMaybe<CookingStyle_Bool_Exp>;
  _and?: InputMaybe<Array<Ingredient_CookingStyles_Bool_Exp>>;
  _not?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
  _or?: InputMaybe<Array<Ingredient_CookingStyles_Bool_Exp>>;
  cooking_style_id?: InputMaybe<Uuid_Comparison_Exp>;
  ingredient_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Ingredient_CookingStyles" */
export enum Ingredient_CookingStyles_Constraint {
  /** unique or primary key constraint on columns "ingredient_id" */
  IngredientCookingStylesPkey = "Ingredient_CookingStyles_pkey",
}

/** input type for inserting data into table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Insert_Input = {
  CookingStyle?: InputMaybe<CookingStyle_Obj_Rel_Insert_Input>;
  cooking_style_id?: InputMaybe<Scalars["uuid"]>;
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ingredient_CookingStyles_Max_Fields = {
  __typename?: "Ingredient_CookingStyles_max_fields";
  cooking_style_id?: Maybe<Scalars["uuid"]>;
  ingredient_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Max_Order_By = {
  cooking_style_id?: InputMaybe<Order_By>;
  ingredient_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ingredient_CookingStyles_Min_Fields = {
  __typename?: "Ingredient_CookingStyles_min_fields";
  cooking_style_id?: Maybe<Scalars["uuid"]>;
  ingredient_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Min_Order_By = {
  cooking_style_id?: InputMaybe<Order_By>;
  ingredient_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Mutation_Response = {
  __typename?: "Ingredient_CookingStyles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ingredient_CookingStyles>;
};

/** on_conflict condition type for table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_On_Conflict = {
  constraint: Ingredient_CookingStyles_Constraint;
  update_columns?: Array<Ingredient_CookingStyles_Update_Column>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

/** Ordering options when selecting data from "Ingredient_CookingStyles". */
export type Ingredient_CookingStyles_Order_By = {
  CookingStyle?: InputMaybe<CookingStyle_Order_By>;
  cooking_style_id?: InputMaybe<Order_By>;
  ingredient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Ingredient_CookingStyles */
export type Ingredient_CookingStyles_Pk_Columns_Input = {
  ingredient_id: Scalars["uuid"];
};

/** select columns of table "Ingredient_CookingStyles" */
export enum Ingredient_CookingStyles_Select_Column {
  /** column name */
  CookingStyleId = "cooking_style_id",
  /** column name */
  IngredientId = "ingredient_id",
}

/** input type for updating data in table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Set_Input = {
  cooking_style_id?: InputMaybe<Scalars["uuid"]>;
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "Ingredient_CookingStyles" */
export type Ingredient_CookingStyles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ingredient_CookingStyles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ingredient_CookingStyles_Stream_Cursor_Value_Input = {
  cooking_style_id?: InputMaybe<Scalars["uuid"]>;
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "Ingredient_CookingStyles" */
export enum Ingredient_CookingStyles_Update_Column {
  /** column name */
  CookingStyleId = "cooking_style_id",
  /** column name */
  IngredientId = "ingredient_id",
}

export type Ingredient_CookingStyles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ingredient_CookingStyles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ingredient_CookingStyles_Bool_Exp;
};

/** columns and relationships of "Ingredient_Seasons" */
export type Ingredient_Seasons = {
  __typename?: "Ingredient_Seasons";
  /** An object relationship */
  Season: Season;
  ingredient_id: Scalars["uuid"];
  season_id: Scalars["uuid"];
};

/** aggregated selection of "Ingredient_Seasons" */
export type Ingredient_Seasons_Aggregate = {
  __typename?: "Ingredient_Seasons_aggregate";
  aggregate?: Maybe<Ingredient_Seasons_Aggregate_Fields>;
  nodes: Array<Ingredient_Seasons>;
};

export type Ingredient_Seasons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ingredient_Seasons_Aggregate_Bool_Exp_Count>;
};

export type Ingredient_Seasons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Ingredient_Seasons" */
export type Ingredient_Seasons_Aggregate_Fields = {
  __typename?: "Ingredient_Seasons_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ingredient_Seasons_Max_Fields>;
  min?: Maybe<Ingredient_Seasons_Min_Fields>;
};

/** aggregate fields of "Ingredient_Seasons" */
export type Ingredient_Seasons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Ingredient_Seasons" */
export type Ingredient_Seasons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ingredient_Seasons_Max_Order_By>;
  min?: InputMaybe<Ingredient_Seasons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Ingredient_Seasons" */
export type Ingredient_Seasons_Arr_Rel_Insert_Input = {
  data: Array<Ingredient_Seasons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ingredient_Seasons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Ingredient_Seasons". All fields are combined with a logical 'AND'. */
export type Ingredient_Seasons_Bool_Exp = {
  Season?: InputMaybe<Season_Bool_Exp>;
  _and?: InputMaybe<Array<Ingredient_Seasons_Bool_Exp>>;
  _not?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
  _or?: InputMaybe<Array<Ingredient_Seasons_Bool_Exp>>;
  ingredient_id?: InputMaybe<Uuid_Comparison_Exp>;
  season_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Ingredient_Seasons" */
export enum Ingredient_Seasons_Constraint {
  /** unique or primary key constraint on columns "ingredient_id" */
  IngredientSeasonsPkey = "Ingredient_Seasons_pkey",
}

/** input type for inserting data into table "Ingredient_Seasons" */
export type Ingredient_Seasons_Insert_Input = {
  Season?: InputMaybe<Season_Obj_Rel_Insert_Input>;
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  season_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ingredient_Seasons_Max_Fields = {
  __typename?: "Ingredient_Seasons_max_fields";
  ingredient_id?: Maybe<Scalars["uuid"]>;
  season_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Ingredient_Seasons" */
export type Ingredient_Seasons_Max_Order_By = {
  ingredient_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ingredient_Seasons_Min_Fields = {
  __typename?: "Ingredient_Seasons_min_fields";
  ingredient_id?: Maybe<Scalars["uuid"]>;
  season_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Ingredient_Seasons" */
export type Ingredient_Seasons_Min_Order_By = {
  ingredient_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Ingredient_Seasons" */
export type Ingredient_Seasons_Mutation_Response = {
  __typename?: "Ingredient_Seasons_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ingredient_Seasons>;
};

/** on_conflict condition type for table "Ingredient_Seasons" */
export type Ingredient_Seasons_On_Conflict = {
  constraint: Ingredient_Seasons_Constraint;
  update_columns?: Array<Ingredient_Seasons_Update_Column>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

/** Ordering options when selecting data from "Ingredient_Seasons". */
export type Ingredient_Seasons_Order_By = {
  Season?: InputMaybe<Season_Order_By>;
  ingredient_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Ingredient_Seasons */
export type Ingredient_Seasons_Pk_Columns_Input = {
  ingredient_id: Scalars["uuid"];
};

/** select columns of table "Ingredient_Seasons" */
export enum Ingredient_Seasons_Select_Column {
  /** column name */
  IngredientId = "ingredient_id",
  /** column name */
  SeasonId = "season_id",
}

/** input type for updating data in table "Ingredient_Seasons" */
export type Ingredient_Seasons_Set_Input = {
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  season_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "Ingredient_Seasons" */
export type Ingredient_Seasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ingredient_Seasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ingredient_Seasons_Stream_Cursor_Value_Input = {
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  season_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "Ingredient_Seasons" */
export enum Ingredient_Seasons_Update_Column {
  /** column name */
  IngredientId = "ingredient_id",
  /** column name */
  SeasonId = "season_id",
}

export type Ingredient_Seasons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ingredient_Seasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ingredient_Seasons_Bool_Exp;
};

/** aggregated selection of "Ingredient" */
export type Ingredient_Aggregate = {
  __typename?: "Ingredient_aggregate";
  aggregate?: Maybe<Ingredient_Aggregate_Fields>;
  nodes: Array<Ingredient>;
};

/** aggregate fields of "Ingredient" */
export type Ingredient_Aggregate_Fields = {
  __typename?: "Ingredient_aggregate_fields";
  avg?: Maybe<Ingredient_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ingredient_Max_Fields>;
  min?: Maybe<Ingredient_Min_Fields>;
  stddev?: Maybe<Ingredient_Stddev_Fields>;
  stddev_pop?: Maybe<Ingredient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ingredient_Stddev_Samp_Fields>;
  sum?: Maybe<Ingredient_Sum_Fields>;
  var_pop?: Maybe<Ingredient_Var_Pop_Fields>;
  var_samp?: Maybe<Ingredient_Var_Samp_Fields>;
  variance?: Maybe<Ingredient_Variance_Fields>;
};

/** aggregate fields of "Ingredient" */
export type Ingredient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ingredient_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Ingredient_Avg_Fields = {
  __typename?: "Ingredient_avg_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Ingredient". All fields are combined with a logical 'AND'. */
export type Ingredient_Bool_Exp = {
  Prep_Ingredients?: InputMaybe<Prep_Ingredients_Bool_Exp>;
  Prep_Ingredients_aggregate?: InputMaybe<Prep_Ingredients_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Ingredient_Bool_Exp>>;
  _not?: InputMaybe<Ingredient_Bool_Exp>;
  _or?: InputMaybe<Array<Ingredient_Bool_Exp>>;
  ambient_temp_time?: InputMaybe<Int_Comparison_Exp>;
  availability?: InputMaybe<String_Comparison_Exp>;
  avg_item_quantity?: InputMaybe<Int_Comparison_Exp>;
  can_split?: InputMaybe<Boolean_Comparison_Exp>;
  consistency?: InputMaybe<String_Comparison_Exp>;
  difficulty?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  measure_unit?: InputMaybe<String_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Ingredient" */
export enum Ingredient_Constraint {
  /** unique or primary key constraint on columns "id" */
  IngredientPkey = "Ingredient_pkey",
}

/** input type for incrementing numeric columns in table "Ingredient" */
export type Ingredient_Inc_Input = {
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  price?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Ingredient" */
export type Ingredient_Insert_Input = {
  Prep_Ingredients?: InputMaybe<Prep_Ingredients_Arr_Rel_Insert_Input>;
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
  availability?: InputMaybe<Scalars["String"]>;
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  can_split?: InputMaybe<Scalars["Boolean"]>;
  consistency?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ingredient_Max_Fields = {
  __typename?: "Ingredient_max_fields";
  ambient_temp_time?: Maybe<Scalars["Int"]>;
  availability?: Maybe<Scalars["String"]>;
  avg_item_quantity?: Maybe<Scalars["Int"]>;
  consistency?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  measure_unit?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ingredient_Min_Fields = {
  __typename?: "Ingredient_min_fields";
  ambient_temp_time?: Maybe<Scalars["Int"]>;
  availability?: Maybe<Scalars["String"]>;
  avg_item_quantity?: Maybe<Scalars["Int"]>;
  consistency?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  measure_unit?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Ingredient" */
export type Ingredient_Mutation_Response = {
  __typename?: "Ingredient_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ingredient>;
};

/** input type for inserting object relation for remote table "Ingredient" */
export type Ingredient_Obj_Rel_Insert_Input = {
  data: Ingredient_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ingredient_On_Conflict>;
};

/** on_conflict condition type for table "Ingredient" */
export type Ingredient_On_Conflict = {
  constraint: Ingredient_Constraint;
  update_columns?: Array<Ingredient_Update_Column>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

/** Ordering options when selecting data from "Ingredient". */
export type Ingredient_Order_By = {
  Prep_Ingredients_aggregate?: InputMaybe<Prep_Ingredients_Aggregate_Order_By>;
  ambient_temp_time?: InputMaybe<Order_By>;
  availability?: InputMaybe<Order_By>;
  avg_item_quantity?: InputMaybe<Order_By>;
  can_split?: InputMaybe<Order_By>;
  consistency?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measure_unit?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Ingredient */
export type Ingredient_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Ingredient" */
export enum Ingredient_Select_Column {
  /** column name */
  AmbientTempTime = "ambient_temp_time",
  /** column name */
  Availability = "availability",
  /** column name */
  AvgItemQuantity = "avg_item_quantity",
  /** column name */
  CanSplit = "can_split",
  /** column name */
  Consistency = "consistency",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  MeasureUnit = "measure_unit",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "Ingredient" */
export type Ingredient_Set_Input = {
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
  availability?: InputMaybe<Scalars["String"]>;
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  can_split?: InputMaybe<Scalars["Boolean"]>;
  consistency?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ingredient_Stddev_Fields = {
  __typename?: "Ingredient_stddev_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Ingredient_Stddev_Pop_Fields = {
  __typename?: "Ingredient_stddev_pop_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Ingredient_Stddev_Samp_Fields = {
  __typename?: "Ingredient_stddev_samp_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Ingredient" */
export type Ingredient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ingredient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ingredient_Stream_Cursor_Value_Input = {
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
  availability?: InputMaybe<Scalars["String"]>;
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  can_split?: InputMaybe<Scalars["Boolean"]>;
  consistency?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Ingredient_Sum_Fields = {
  __typename?: "Ingredient_sum_fields";
  ambient_temp_time?: Maybe<Scalars["Int"]>;
  avg_item_quantity?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Ingredient" */
export enum Ingredient_Update_Column {
  /** column name */
  AmbientTempTime = "ambient_temp_time",
  /** column name */
  Availability = "availability",
  /** column name */
  AvgItemQuantity = "avg_item_quantity",
  /** column name */
  CanSplit = "can_split",
  /** column name */
  Consistency = "consistency",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  MeasureUnit = "measure_unit",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  Type = "type",
}

export type Ingredient_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ingredient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ingredient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ingredient_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ingredient_Var_Pop_Fields = {
  __typename?: "Ingredient_var_pop_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Ingredient_Var_Samp_Fields = {
  __typename?: "Ingredient_var_samp_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Ingredient_Variance_Fields = {
  __typename?: "Ingredient_variance_fields";
  ambient_temp_time?: Maybe<Scalars["Float"]>;
  avg_item_quantity?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

/** columns and relationships of "Meal" */
export type Meal = {
  __typename?: "Meal";
  /** An array relationship */
  MealDishes: Array<MealDish>;
  /** An aggregate relationship */
  MealDishes_aggregate: MealDish_Aggregate;
  id: Scalars["uuid"];
  is_cooked: Scalars["Boolean"];
  is_leftovers: Scalars["Boolean"];
  planned_at?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  user_id: Scalars["String"];
};

/** columns and relationships of "Meal" */
export type MealMealDishesArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

/** columns and relationships of "Meal" */
export type MealMealDishes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

/** columns and relationships of "MealDish" */
export type MealDish = {
  __typename?: "MealDish";
  /** An object relationship */
  Dish: Dish;
  /** An object relationship */
  Meal: Meal;
  dish_id: Scalars["uuid"];
  id: Scalars["uuid"];
  meal_id: Scalars["uuid"];
  needs_prep: Scalars["Boolean"];
};

/** aggregated selection of "MealDish" */
export type MealDish_Aggregate = {
  __typename?: "MealDish_aggregate";
  aggregate?: Maybe<MealDish_Aggregate_Fields>;
  nodes: Array<MealDish>;
};

export type MealDish_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<MealDish_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<MealDish_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<MealDish_Aggregate_Bool_Exp_Count>;
};

export type MealDish_Aggregate_Bool_Exp_Bool_And = {
  arguments: MealDish_Select_Column_MealDish_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<MealDish_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type MealDish_Aggregate_Bool_Exp_Bool_Or = {
  arguments: MealDish_Select_Column_MealDish_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<MealDish_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type MealDish_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MealDish_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<MealDish_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "MealDish" */
export type MealDish_Aggregate_Fields = {
  __typename?: "MealDish_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<MealDish_Max_Fields>;
  min?: Maybe<MealDish_Min_Fields>;
};

/** aggregate fields of "MealDish" */
export type MealDish_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MealDish_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MealDish" */
export type MealDish_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MealDish_Max_Order_By>;
  min?: InputMaybe<MealDish_Min_Order_By>;
};

/** input type for inserting array relation for remote table "MealDish" */
export type MealDish_Arr_Rel_Insert_Input = {
  data: Array<MealDish_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MealDish_On_Conflict>;
};

/** Boolean expression to filter rows from the table "MealDish". All fields are combined with a logical 'AND'. */
export type MealDish_Bool_Exp = {
  Dish?: InputMaybe<Dish_Bool_Exp>;
  Meal?: InputMaybe<Meal_Bool_Exp>;
  _and?: InputMaybe<Array<MealDish_Bool_Exp>>;
  _not?: InputMaybe<MealDish_Bool_Exp>;
  _or?: InputMaybe<Array<MealDish_Bool_Exp>>;
  dish_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meal_id?: InputMaybe<Uuid_Comparison_Exp>;
  needs_prep?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "MealDish" */
export enum MealDish_Constraint {
  /** unique or primary key constraint on columns "id" */
  MealDishPkey = "MealDish_pkey",
}

/** input type for inserting data into table "MealDish" */
export type MealDish_Insert_Input = {
  Dish?: InputMaybe<Dish_Obj_Rel_Insert_Input>;
  Meal?: InputMaybe<Meal_Obj_Rel_Insert_Input>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  meal_id?: InputMaybe<Scalars["uuid"]>;
  needs_prep?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type MealDish_Max_Fields = {
  __typename?: "MealDish_max_fields";
  dish_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  meal_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "MealDish" */
export type MealDish_Max_Order_By = {
  dish_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meal_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MealDish_Min_Fields = {
  __typename?: "MealDish_min_fields";
  dish_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  meal_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "MealDish" */
export type MealDish_Min_Order_By = {
  dish_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meal_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "MealDish" */
export type MealDish_Mutation_Response = {
  __typename?: "MealDish_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MealDish>;
};

/** on_conflict condition type for table "MealDish" */
export type MealDish_On_Conflict = {
  constraint: MealDish_Constraint;
  update_columns?: Array<MealDish_Update_Column>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

/** Ordering options when selecting data from "MealDish". */
export type MealDish_Order_By = {
  Dish?: InputMaybe<Dish_Order_By>;
  Meal?: InputMaybe<Meal_Order_By>;
  dish_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meal_id?: InputMaybe<Order_By>;
  needs_prep?: InputMaybe<Order_By>;
};

/** primary key columns input for table: MealDish */
export type MealDish_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "MealDish" */
export enum MealDish_Select_Column {
  /** column name */
  DishId = "dish_id",
  /** column name */
  Id = "id",
  /** column name */
  MealId = "meal_id",
  /** column name */
  NeedsPrep = "needs_prep",
}

/** select "MealDish_aggregate_bool_exp_bool_and_arguments_columns" columns of table "MealDish" */
export enum MealDish_Select_Column_MealDish_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  NeedsPrep = "needs_prep",
}

/** select "MealDish_aggregate_bool_exp_bool_or_arguments_columns" columns of table "MealDish" */
export enum MealDish_Select_Column_MealDish_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  NeedsPrep = "needs_prep",
}

/** input type for updating data in table "MealDish" */
export type MealDish_Set_Input = {
  dish_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  meal_id?: InputMaybe<Scalars["uuid"]>;
  needs_prep?: InputMaybe<Scalars["Boolean"]>;
};

/** Streaming cursor of the table "MealDish" */
export type MealDish_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MealDish_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MealDish_Stream_Cursor_Value_Input = {
  dish_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  meal_id?: InputMaybe<Scalars["uuid"]>;
  needs_prep?: InputMaybe<Scalars["Boolean"]>;
};

/** update columns of table "MealDish" */
export enum MealDish_Update_Column {
  /** column name */
  DishId = "dish_id",
  /** column name */
  Id = "id",
  /** column name */
  MealId = "meal_id",
  /** column name */
  NeedsPrep = "needs_prep",
}

export type MealDish_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MealDish_Set_Input>;
  /** filter the rows which have to be updated */
  where: MealDish_Bool_Exp;
};

/** aggregated selection of "Meal" */
export type Meal_Aggregate = {
  __typename?: "Meal_aggregate";
  aggregate?: Maybe<Meal_Aggregate_Fields>;
  nodes: Array<Meal>;
};

/** aggregate fields of "Meal" */
export type Meal_Aggregate_Fields = {
  __typename?: "Meal_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Meal_Max_Fields>;
  min?: Maybe<Meal_Min_Fields>;
};

/** aggregate fields of "Meal" */
export type Meal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Meal_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "Meal". All fields are combined with a logical 'AND'. */
export type Meal_Bool_Exp = {
  MealDishes?: InputMaybe<MealDish_Bool_Exp>;
  MealDishes_aggregate?: InputMaybe<MealDish_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Meal_Bool_Exp>>;
  _not?: InputMaybe<Meal_Bool_Exp>;
  _or?: InputMaybe<Array<Meal_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_cooked?: InputMaybe<Boolean_Comparison_Exp>;
  is_leftovers?: InputMaybe<Boolean_Comparison_Exp>;
  planned_at?: InputMaybe<Date_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Meal" */
export enum Meal_Constraint {
  /** unique or primary key constraint on columns "id" */
  MealPkey = "Meal_pkey",
}

/** input type for inserting data into table "Meal" */
export type Meal_Insert_Input = {
  MealDishes?: InputMaybe<MealDish_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_cooked?: InputMaybe<Scalars["Boolean"]>;
  is_leftovers?: InputMaybe<Scalars["Boolean"]>;
  planned_at?: InputMaybe<Scalars["date"]>;
  type?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Meal_Max_Fields = {
  __typename?: "Meal_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  planned_at?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Meal_Min_Fields = {
  __typename?: "Meal_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  planned_at?: Maybe<Scalars["date"]>;
  type?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Meal" */
export type Meal_Mutation_Response = {
  __typename?: "Meal_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Meal>;
};

/** input type for inserting object relation for remote table "Meal" */
export type Meal_Obj_Rel_Insert_Input = {
  data: Meal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Meal_On_Conflict>;
};

/** on_conflict condition type for table "Meal" */
export type Meal_On_Conflict = {
  constraint: Meal_Constraint;
  update_columns?: Array<Meal_Update_Column>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

/** Ordering options when selecting data from "Meal". */
export type Meal_Order_By = {
  MealDishes_aggregate?: InputMaybe<MealDish_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_cooked?: InputMaybe<Order_By>;
  is_leftovers?: InputMaybe<Order_By>;
  planned_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Meal */
export type Meal_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Meal" */
export enum Meal_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsCooked = "is_cooked",
  /** column name */
  IsLeftovers = "is_leftovers",
  /** column name */
  PlannedAt = "planned_at",
  /** column name */
  Type = "type",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "Meal" */
export type Meal_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  is_cooked?: InputMaybe<Scalars["Boolean"]>;
  is_leftovers?: InputMaybe<Scalars["Boolean"]>;
  planned_at?: InputMaybe<Scalars["date"]>;
  type?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "Meal" */
export type Meal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Meal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Meal_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  is_cooked?: InputMaybe<Scalars["Boolean"]>;
  is_leftovers?: InputMaybe<Scalars["Boolean"]>;
  planned_at?: InputMaybe<Scalars["date"]>;
  type?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "Meal" */
export enum Meal_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  IsCooked = "is_cooked",
  /** column name */
  IsLeftovers = "is_leftovers",
  /** column name */
  PlannedAt = "planned_at",
  /** column name */
  Type = "type",
  /** column name */
  UserId = "user_id",
}

export type Meal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Meal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Meal_Bool_Exp;
};

/** columns and relationships of "Prep" */
export type Prep = {
  __typename?: "Prep";
  /** An array relationship */
  AlternativePreps: Array<Prep>;
  /** An aggregate relationship */
  AlternativePreps_aggregate: Prep_Aggregate;
  /** An object relationship */
  Dish?: Maybe<Dish>;
  /** An array relationship */
  Ingredients: Array<Prep_Ingredients>;
  /** An aggregate relationship */
  Ingredients_aggregate: Prep_Ingredients_Aggregate;
  /** An array relationship */
  InputPreps: Array<Prep_Preps>;
  /** An aggregate relationship */
  InputPreps_aggregate: Prep_Preps_Aggregate;
  /** An object relationship */
  MainPrep?: Maybe<Prep>;
  /** An array relationship */
  Steps: Array<PrepStep>;
  /** An aggregate relationship */
  Steps_aggregate: PrepStep_Aggregate;
  /** An object relationship */
  Technic?: Maybe<Technic>;
  /** An array relationship */
  Technics: Array<Prep_Technics>;
  /** An aggregate relationship */
  Technics_aggregate: Prep_Technics_Aggregate;
  /** An array relationship */
  Tools: Array<Prep_Tools>;
  /** An aggregate relationship */
  Tools_aggregate: Prep_Tools_Aggregate;
  created_at: Scalars["timestamp"];
  description?: Maybe<Scalars["String"]>;
  dish_id?: Maybe<Scalars["uuid"]>;
  duration: Scalars["Int"];
  id: Scalars["uuid"];
  main_prep_id?: Maybe<Scalars["uuid"]>;
  measure_unit: Scalars["String"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  rest_conditions?: Maybe<Scalars["String"]>;
  rest_time: Scalars["Int"];
  serving_size: Scalars["Int"];
  technic_id?: Maybe<Scalars["uuid"]>;
  time_ahead?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["timestamp"];
};

/** columns and relationships of "Prep" */
export type PrepAlternativePrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepAlternativePreps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepIngredientsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepIngredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepInputPrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepInputPreps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepStepsArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepSteps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepTechnicsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepTechnics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepToolsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

/** columns and relationships of "Prep" */
export type PrepTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

/** columns and relationships of "PrepStep" */
export type PrepStep = {
  __typename?: "PrepStep";
  /** An object relationship */
  Technic?: Maybe<Technic>;
  description: Scalars["String"];
  difficulty?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  prep_id: Scalars["uuid"];
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "PrepStep" */
export type PrepStep_Aggregate = {
  __typename?: "PrepStep_aggregate";
  aggregate?: Maybe<PrepStep_Aggregate_Fields>;
  nodes: Array<PrepStep>;
};

export type PrepStep_Aggregate_Bool_Exp = {
  count?: InputMaybe<PrepStep_Aggregate_Bool_Exp_Count>;
};

export type PrepStep_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PrepStep_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<PrepStep_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PrepStep" */
export type PrepStep_Aggregate_Fields = {
  __typename?: "PrepStep_aggregate_fields";
  avg?: Maybe<PrepStep_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PrepStep_Max_Fields>;
  min?: Maybe<PrepStep_Min_Fields>;
  stddev?: Maybe<PrepStep_Stddev_Fields>;
  stddev_pop?: Maybe<PrepStep_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PrepStep_Stddev_Samp_Fields>;
  sum?: Maybe<PrepStep_Sum_Fields>;
  var_pop?: Maybe<PrepStep_Var_Pop_Fields>;
  var_samp?: Maybe<PrepStep_Var_Samp_Fields>;
  variance?: Maybe<PrepStep_Variance_Fields>;
};

/** aggregate fields of "PrepStep" */
export type PrepStep_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PrepStep_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "PrepStep" */
export type PrepStep_Aggregate_Order_By = {
  avg?: InputMaybe<PrepStep_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PrepStep_Max_Order_By>;
  min?: InputMaybe<PrepStep_Min_Order_By>;
  stddev?: InputMaybe<PrepStep_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PrepStep_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PrepStep_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PrepStep_Sum_Order_By>;
  var_pop?: InputMaybe<PrepStep_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PrepStep_Var_Samp_Order_By>;
  variance?: InputMaybe<PrepStep_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PrepStep" */
export type PrepStep_Arr_Rel_Insert_Input = {
  data: Array<PrepStep_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PrepStep_On_Conflict>;
};

/** aggregate avg on columns */
export type PrepStep_Avg_Fields = {
  __typename?: "PrepStep_avg_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "PrepStep" */
export type PrepStep_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PrepStep". All fields are combined with a logical 'AND'. */
export type PrepStep_Bool_Exp = {
  Technic?: InputMaybe<Technic_Bool_Exp>;
  _and?: InputMaybe<Array<PrepStep_Bool_Exp>>;
  _not?: InputMaybe<PrepStep_Bool_Exp>;
  _or?: InputMaybe<Array<PrepStep_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  difficulty?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  technic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "PrepStep" */
export enum PrepStep_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrepStepPkey = "PrepStep_pkey",
}

/** input type for incrementing numeric columns in table "PrepStep" */
export type PrepStep_Inc_Input = {
  order?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "PrepStep" */
export type PrepStep_Insert_Input = {
  Technic?: InputMaybe<Technic_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type PrepStep_Max_Fields = {
  __typename?: "PrepStep_max_fields";
  description?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "PrepStep" */
export type PrepStep_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PrepStep_Min_Fields = {
  __typename?: "PrepStep_min_fields";
  description?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "PrepStep" */
export type PrepStep_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PrepStep" */
export type PrepStep_Mutation_Response = {
  __typename?: "PrepStep_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PrepStep>;
};

/** on_conflict condition type for table "PrepStep" */
export type PrepStep_On_Conflict = {
  constraint: PrepStep_Constraint;
  update_columns?: Array<PrepStep_Update_Column>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

/** Ordering options when selecting data from "PrepStep". */
export type PrepStep_Order_By = {
  Technic?: InputMaybe<Technic_Order_By>;
  description?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PrepStep */
export type PrepStep_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "PrepStep" */
export enum PrepStep_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  TechnicId = "technic_id",
}

/** input type for updating data in table "PrepStep" */
export type PrepStep_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type PrepStep_Stddev_Fields = {
  __typename?: "PrepStep_stddev_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "PrepStep" */
export type PrepStep_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PrepStep_Stddev_Pop_Fields = {
  __typename?: "PrepStep_stddev_pop_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "PrepStep" */
export type PrepStep_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PrepStep_Stddev_Samp_Fields = {
  __typename?: "PrepStep_stddev_samp_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "PrepStep" */
export type PrepStep_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PrepStep" */
export type PrepStep_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PrepStep_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PrepStep_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type PrepStep_Sum_Fields = {
  __typename?: "PrepStep_sum_fields";
  order?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "PrepStep" */
export type PrepStep_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "PrepStep" */
export enum PrepStep_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  TechnicId = "technic_id",
}

export type PrepStep_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PrepStep_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PrepStep_Set_Input>;
  /** filter the rows which have to be updated */
  where: PrepStep_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PrepStep_Var_Pop_Fields = {
  __typename?: "PrepStep_var_pop_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "PrepStep" */
export type PrepStep_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PrepStep_Var_Samp_Fields = {
  __typename?: "PrepStep_var_samp_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "PrepStep" */
export type PrepStep_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PrepStep_Variance_Fields = {
  __typename?: "PrepStep_variance_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "PrepStep" */
export type PrepStep_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "Prep_Ingredients" */
export type Prep_Ingredients = {
  __typename?: "Prep_Ingredients";
  /** An object relationship */
  Ingredient: Ingredient;
  /** An object relationship */
  Prep: Prep;
  ingredient_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
  quantity: Scalars["Int"];
};

/** aggregated selection of "Prep_Ingredients" */
export type Prep_Ingredients_Aggregate = {
  __typename?: "Prep_Ingredients_aggregate";
  aggregate?: Maybe<Prep_Ingredients_Aggregate_Fields>;
  nodes: Array<Prep_Ingredients>;
};

export type Prep_Ingredients_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prep_Ingredients_Aggregate_Bool_Exp_Count>;
};

export type Prep_Ingredients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Prep_Ingredients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Prep_Ingredients" */
export type Prep_Ingredients_Aggregate_Fields = {
  __typename?: "Prep_Ingredients_aggregate_fields";
  avg?: Maybe<Prep_Ingredients_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Prep_Ingredients_Max_Fields>;
  min?: Maybe<Prep_Ingredients_Min_Fields>;
  stddev?: Maybe<Prep_Ingredients_Stddev_Fields>;
  stddev_pop?: Maybe<Prep_Ingredients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prep_Ingredients_Stddev_Samp_Fields>;
  sum?: Maybe<Prep_Ingredients_Sum_Fields>;
  var_pop?: Maybe<Prep_Ingredients_Var_Pop_Fields>;
  var_samp?: Maybe<Prep_Ingredients_Var_Samp_Fields>;
  variance?: Maybe<Prep_Ingredients_Variance_Fields>;
};

/** aggregate fields of "Prep_Ingredients" */
export type Prep_Ingredients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Prep_Ingredients" */
export type Prep_Ingredients_Aggregate_Order_By = {
  avg?: InputMaybe<Prep_Ingredients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prep_Ingredients_Max_Order_By>;
  min?: InputMaybe<Prep_Ingredients_Min_Order_By>;
  stddev?: InputMaybe<Prep_Ingredients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prep_Ingredients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prep_Ingredients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prep_Ingredients_Sum_Order_By>;
  var_pop?: InputMaybe<Prep_Ingredients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prep_Ingredients_Var_Samp_Order_By>;
  variance?: InputMaybe<Prep_Ingredients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Prep_Ingredients" */
export type Prep_Ingredients_Arr_Rel_Insert_Input = {
  data: Array<Prep_Ingredients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_Ingredients_On_Conflict>;
};

/** aggregate avg on columns */
export type Prep_Ingredients_Avg_Fields = {
  __typename?: "Prep_Ingredients_avg_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Prep_Ingredients". All fields are combined with a logical 'AND'. */
export type Prep_Ingredients_Bool_Exp = {
  Ingredient?: InputMaybe<Ingredient_Bool_Exp>;
  Prep?: InputMaybe<Prep_Bool_Exp>;
  _and?: InputMaybe<Array<Prep_Ingredients_Bool_Exp>>;
  _not?: InputMaybe<Prep_Ingredients_Bool_Exp>;
  _or?: InputMaybe<Array<Prep_Ingredients_Bool_Exp>>;
  ingredient_id?: InputMaybe<Uuid_Comparison_Exp>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prep_Ingredients" */
export enum Prep_Ingredients_Constraint {
  /** unique or primary key constraint on columns "ingredient_id", "prep_id" */
  PrepIngredientsPkey = "Prep_Ingredients_pkey",
}

/** input type for incrementing numeric columns in table "Prep_Ingredients" */
export type Prep_Ingredients_Inc_Input = {
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Prep_Ingredients" */
export type Prep_Ingredients_Insert_Input = {
  Ingredient?: InputMaybe<Ingredient_Obj_Rel_Insert_Input>;
  Prep?: InputMaybe<Prep_Obj_Rel_Insert_Input>;
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Prep_Ingredients_Max_Fields = {
  __typename?: "Prep_Ingredients_max_fields";
  ingredient_id?: Maybe<Scalars["uuid"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Max_Order_By = {
  ingredient_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prep_Ingredients_Min_Fields = {
  __typename?: "Prep_Ingredients_min_fields";
  ingredient_id?: Maybe<Scalars["uuid"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Min_Order_By = {
  ingredient_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Prep_Ingredients" */
export type Prep_Ingredients_Mutation_Response = {
  __typename?: "Prep_Ingredients_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Prep_Ingredients>;
};

/** on_conflict condition type for table "Prep_Ingredients" */
export type Prep_Ingredients_On_Conflict = {
  constraint: Prep_Ingredients_Constraint;
  update_columns?: Array<Prep_Ingredients_Update_Column>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

/** Ordering options when selecting data from "Prep_Ingredients". */
export type Prep_Ingredients_Order_By = {
  Ingredient?: InputMaybe<Ingredient_Order_By>;
  Prep?: InputMaybe<Prep_Order_By>;
  ingredient_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Prep_Ingredients */
export type Prep_Ingredients_Pk_Columns_Input = {
  ingredient_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

/** select columns of table "Prep_Ingredients" */
export enum Prep_Ingredients_Select_Column {
  /** column name */
  IngredientId = "ingredient_id",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  Quantity = "quantity",
}

/** input type for updating data in table "Prep_Ingredients" */
export type Prep_Ingredients_Set_Input = {
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Prep_Ingredients_Stddev_Fields = {
  __typename?: "Prep_Ingredients_stddev_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Prep_Ingredients_Stddev_Pop_Fields = {
  __typename?: "Prep_Ingredients_stddev_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Prep_Ingredients_Stddev_Samp_Fields = {
  __typename?: "Prep_Ingredients_stddev_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Prep_Ingredients" */
export type Prep_Ingredients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prep_Ingredients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prep_Ingredients_Stream_Cursor_Value_Input = {
  ingredient_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Prep_Ingredients_Sum_Fields = {
  __typename?: "Prep_Ingredients_sum_fields";
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "Prep_Ingredients" */
export enum Prep_Ingredients_Update_Column {
  /** column name */
  IngredientId = "ingredient_id",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  Quantity = "quantity",
}

export type Prep_Ingredients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prep_Ingredients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prep_Ingredients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prep_Ingredients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prep_Ingredients_Var_Pop_Fields = {
  __typename?: "Prep_Ingredients_var_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Prep_Ingredients_Var_Samp_Fields = {
  __typename?: "Prep_Ingredients_var_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Prep_Ingredients_Variance_Fields = {
  __typename?: "Prep_Ingredients_variance_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Prep_Ingredients" */
export type Prep_Ingredients_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "Prep_Preps" */
export type Prep_Preps = {
  __typename?: "Prep_Preps";
  /** An object relationship */
  Prep: Prep;
  input_prep_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
  quantity: Scalars["Int"];
};

/** aggregated selection of "Prep_Preps" */
export type Prep_Preps_Aggregate = {
  __typename?: "Prep_Preps_aggregate";
  aggregate?: Maybe<Prep_Preps_Aggregate_Fields>;
  nodes: Array<Prep_Preps>;
};

export type Prep_Preps_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prep_Preps_Aggregate_Bool_Exp_Count>;
};

export type Prep_Preps_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Prep_Preps_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Prep_Preps" */
export type Prep_Preps_Aggregate_Fields = {
  __typename?: "Prep_Preps_aggregate_fields";
  avg?: Maybe<Prep_Preps_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Prep_Preps_Max_Fields>;
  min?: Maybe<Prep_Preps_Min_Fields>;
  stddev?: Maybe<Prep_Preps_Stddev_Fields>;
  stddev_pop?: Maybe<Prep_Preps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prep_Preps_Stddev_Samp_Fields>;
  sum?: Maybe<Prep_Preps_Sum_Fields>;
  var_pop?: Maybe<Prep_Preps_Var_Pop_Fields>;
  var_samp?: Maybe<Prep_Preps_Var_Samp_Fields>;
  variance?: Maybe<Prep_Preps_Variance_Fields>;
};

/** aggregate fields of "Prep_Preps" */
export type Prep_Preps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Prep_Preps" */
export type Prep_Preps_Aggregate_Order_By = {
  avg?: InputMaybe<Prep_Preps_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prep_Preps_Max_Order_By>;
  min?: InputMaybe<Prep_Preps_Min_Order_By>;
  stddev?: InputMaybe<Prep_Preps_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prep_Preps_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prep_Preps_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prep_Preps_Sum_Order_By>;
  var_pop?: InputMaybe<Prep_Preps_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prep_Preps_Var_Samp_Order_By>;
  variance?: InputMaybe<Prep_Preps_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Prep_Preps" */
export type Prep_Preps_Arr_Rel_Insert_Input = {
  data: Array<Prep_Preps_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_Preps_On_Conflict>;
};

/** aggregate avg on columns */
export type Prep_Preps_Avg_Fields = {
  __typename?: "Prep_Preps_avg_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Prep_Preps" */
export type Prep_Preps_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Prep_Preps". All fields are combined with a logical 'AND'. */
export type Prep_Preps_Bool_Exp = {
  Prep?: InputMaybe<Prep_Bool_Exp>;
  _and?: InputMaybe<Array<Prep_Preps_Bool_Exp>>;
  _not?: InputMaybe<Prep_Preps_Bool_Exp>;
  _or?: InputMaybe<Array<Prep_Preps_Bool_Exp>>;
  input_prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prep_Preps" */
export enum Prep_Preps_Constraint {
  /** unique or primary key constraint on columns "input_prep_id", "prep_id" */
  PrepPrepsPkey = "Prep_Preps_pkey",
}

/** input type for incrementing numeric columns in table "Prep_Preps" */
export type Prep_Preps_Inc_Input = {
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Prep_Preps" */
export type Prep_Preps_Insert_Input = {
  Prep?: InputMaybe<Prep_Obj_Rel_Insert_Input>;
  input_prep_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Prep_Preps_Max_Fields = {
  __typename?: "Prep_Preps_max_fields";
  input_prep_id?: Maybe<Scalars["uuid"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "Prep_Preps" */
export type Prep_Preps_Max_Order_By = {
  input_prep_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prep_Preps_Min_Fields = {
  __typename?: "Prep_Preps_min_fields";
  input_prep_id?: Maybe<Scalars["uuid"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "Prep_Preps" */
export type Prep_Preps_Min_Order_By = {
  input_prep_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Prep_Preps" */
export type Prep_Preps_Mutation_Response = {
  __typename?: "Prep_Preps_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Prep_Preps>;
};

/** on_conflict condition type for table "Prep_Preps" */
export type Prep_Preps_On_Conflict = {
  constraint: Prep_Preps_Constraint;
  update_columns?: Array<Prep_Preps_Update_Column>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

/** Ordering options when selecting data from "Prep_Preps". */
export type Prep_Preps_Order_By = {
  Prep?: InputMaybe<Prep_Order_By>;
  input_prep_id?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Prep_Preps */
export type Prep_Preps_Pk_Columns_Input = {
  input_prep_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

/** select columns of table "Prep_Preps" */
export enum Prep_Preps_Select_Column {
  /** column name */
  InputPrepId = "input_prep_id",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  Quantity = "quantity",
}

/** input type for updating data in table "Prep_Preps" */
export type Prep_Preps_Set_Input = {
  input_prep_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Prep_Preps_Stddev_Fields = {
  __typename?: "Prep_Preps_stddev_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Prep_Preps" */
export type Prep_Preps_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Prep_Preps_Stddev_Pop_Fields = {
  __typename?: "Prep_Preps_stddev_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Prep_Preps" */
export type Prep_Preps_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Prep_Preps_Stddev_Samp_Fields = {
  __typename?: "Prep_Preps_stddev_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Prep_Preps" */
export type Prep_Preps_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Prep_Preps" */
export type Prep_Preps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prep_Preps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prep_Preps_Stream_Cursor_Value_Input = {
  input_prep_id?: InputMaybe<Scalars["uuid"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Prep_Preps_Sum_Fields = {
  __typename?: "Prep_Preps_sum_fields";
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Prep_Preps" */
export type Prep_Preps_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "Prep_Preps" */
export enum Prep_Preps_Update_Column {
  /** column name */
  InputPrepId = "input_prep_id",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  Quantity = "quantity",
}

export type Prep_Preps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prep_Preps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prep_Preps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prep_Preps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prep_Preps_Var_Pop_Fields = {
  __typename?: "Prep_Preps_var_pop_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Prep_Preps" */
export type Prep_Preps_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Prep_Preps_Var_Samp_Fields = {
  __typename?: "Prep_Preps_var_samp_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Prep_Preps" */
export type Prep_Preps_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Prep_Preps_Variance_Fields = {
  __typename?: "Prep_Preps_variance_fields";
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Prep_Preps" */
export type Prep_Preps_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "Prep_Technics" */
export type Prep_Technics = {
  __typename?: "Prep_Technics";
  /** An object relationship */
  Technic: Technic;
  prep_id: Scalars["uuid"];
  technic_id: Scalars["uuid"];
};

/** aggregated selection of "Prep_Technics" */
export type Prep_Technics_Aggregate = {
  __typename?: "Prep_Technics_aggregate";
  aggregate?: Maybe<Prep_Technics_Aggregate_Fields>;
  nodes: Array<Prep_Technics>;
};

export type Prep_Technics_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prep_Technics_Aggregate_Bool_Exp_Count>;
};

export type Prep_Technics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Prep_Technics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Prep_Technics" */
export type Prep_Technics_Aggregate_Fields = {
  __typename?: "Prep_Technics_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Prep_Technics_Max_Fields>;
  min?: Maybe<Prep_Technics_Min_Fields>;
};

/** aggregate fields of "Prep_Technics" */
export type Prep_Technics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Prep_Technics" */
export type Prep_Technics_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prep_Technics_Max_Order_By>;
  min?: InputMaybe<Prep_Technics_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Prep_Technics" */
export type Prep_Technics_Arr_Rel_Insert_Input = {
  data: Array<Prep_Technics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_Technics_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Prep_Technics". All fields are combined with a logical 'AND'. */
export type Prep_Technics_Bool_Exp = {
  Technic?: InputMaybe<Technic_Bool_Exp>;
  _and?: InputMaybe<Array<Prep_Technics_Bool_Exp>>;
  _not?: InputMaybe<Prep_Technics_Bool_Exp>;
  _or?: InputMaybe<Array<Prep_Technics_Bool_Exp>>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  technic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prep_Technics" */
export enum Prep_Technics_Constraint {
  /** unique or primary key constraint on columns "technic_id", "prep_id" */
  PrepTechnicsPkey = "Prep_Technics_pkey",
}

/** input type for inserting data into table "Prep_Technics" */
export type Prep_Technics_Insert_Input = {
  Technic?: InputMaybe<Technic_Obj_Rel_Insert_Input>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Prep_Technics_Max_Fields = {
  __typename?: "Prep_Technics_max_fields";
  prep_id?: Maybe<Scalars["uuid"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Prep_Technics" */
export type Prep_Technics_Max_Order_By = {
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prep_Technics_Min_Fields = {
  __typename?: "Prep_Technics_min_fields";
  prep_id?: Maybe<Scalars["uuid"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Prep_Technics" */
export type Prep_Technics_Min_Order_By = {
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Prep_Technics" */
export type Prep_Technics_Mutation_Response = {
  __typename?: "Prep_Technics_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Prep_Technics>;
};

/** on_conflict condition type for table "Prep_Technics" */
export type Prep_Technics_On_Conflict = {
  constraint: Prep_Technics_Constraint;
  update_columns?: Array<Prep_Technics_Update_Column>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

/** Ordering options when selecting data from "Prep_Technics". */
export type Prep_Technics_Order_By = {
  Technic?: InputMaybe<Technic_Order_By>;
  prep_id?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Prep_Technics */
export type Prep_Technics_Pk_Columns_Input = {
  prep_id: Scalars["uuid"];
  technic_id: Scalars["uuid"];
};

/** select columns of table "Prep_Technics" */
export enum Prep_Technics_Select_Column {
  /** column name */
  PrepId = "prep_id",
  /** column name */
  TechnicId = "technic_id",
}

/** input type for updating data in table "Prep_Technics" */
export type Prep_Technics_Set_Input = {
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "Prep_Technics" */
export type Prep_Technics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prep_Technics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prep_Technics_Stream_Cursor_Value_Input = {
  prep_id?: InputMaybe<Scalars["uuid"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "Prep_Technics" */
export enum Prep_Technics_Update_Column {
  /** column name */
  PrepId = "prep_id",
  /** column name */
  TechnicId = "technic_id",
}

export type Prep_Technics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prep_Technics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prep_Technics_Bool_Exp;
};

/** columns and relationships of "Prep_Tools" */
export type Prep_Tools = {
  __typename?: "Prep_Tools";
  /** An object relationship */
  Tool: Tool;
  prep_id: Scalars["uuid"];
  tool_id: Scalars["uuid"];
};

/** aggregated selection of "Prep_Tools" */
export type Prep_Tools_Aggregate = {
  __typename?: "Prep_Tools_aggregate";
  aggregate?: Maybe<Prep_Tools_Aggregate_Fields>;
  nodes: Array<Prep_Tools>;
};

export type Prep_Tools_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prep_Tools_Aggregate_Bool_Exp_Count>;
};

export type Prep_Tools_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Prep_Tools_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Prep_Tools" */
export type Prep_Tools_Aggregate_Fields = {
  __typename?: "Prep_Tools_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Prep_Tools_Max_Fields>;
  min?: Maybe<Prep_Tools_Min_Fields>;
};

/** aggregate fields of "Prep_Tools" */
export type Prep_Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Prep_Tools" */
export type Prep_Tools_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prep_Tools_Max_Order_By>;
  min?: InputMaybe<Prep_Tools_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Prep_Tools" */
export type Prep_Tools_Arr_Rel_Insert_Input = {
  data: Array<Prep_Tools_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_Tools_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Prep_Tools". All fields are combined with a logical 'AND'. */
export type Prep_Tools_Bool_Exp = {
  Tool?: InputMaybe<Tool_Bool_Exp>;
  _and?: InputMaybe<Array<Prep_Tools_Bool_Exp>>;
  _not?: InputMaybe<Prep_Tools_Bool_Exp>;
  _or?: InputMaybe<Array<Prep_Tools_Bool_Exp>>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prep_Tools" */
export enum Prep_Tools_Constraint {
  /** unique or primary key constraint on columns "prep_id", "tool_id" */
  PrepToolsPkey = "Prep_Tools_pkey",
}

/** input type for inserting data into table "Prep_Tools" */
export type Prep_Tools_Insert_Input = {
  Tool?: InputMaybe<Tool_Obj_Rel_Insert_Input>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  tool_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Prep_Tools_Max_Fields = {
  __typename?: "Prep_Tools_max_fields";
  prep_id?: Maybe<Scalars["uuid"]>;
  tool_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Prep_Tools" */
export type Prep_Tools_Max_Order_By = {
  prep_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prep_Tools_Min_Fields = {
  __typename?: "Prep_Tools_min_fields";
  prep_id?: Maybe<Scalars["uuid"]>;
  tool_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Prep_Tools" */
export type Prep_Tools_Min_Order_By = {
  prep_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Prep_Tools" */
export type Prep_Tools_Mutation_Response = {
  __typename?: "Prep_Tools_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Prep_Tools>;
};

/** on_conflict condition type for table "Prep_Tools" */
export type Prep_Tools_On_Conflict = {
  constraint: Prep_Tools_Constraint;
  update_columns?: Array<Prep_Tools_Update_Column>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "Prep_Tools". */
export type Prep_Tools_Order_By = {
  Tool?: InputMaybe<Tool_Order_By>;
  prep_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Prep_Tools */
export type Prep_Tools_Pk_Columns_Input = {
  prep_id: Scalars["uuid"];
  tool_id: Scalars["uuid"];
};

/** select columns of table "Prep_Tools" */
export enum Prep_Tools_Select_Column {
  /** column name */
  PrepId = "prep_id",
  /** column name */
  ToolId = "tool_id",
}

/** input type for updating data in table "Prep_Tools" */
export type Prep_Tools_Set_Input = {
  prep_id?: InputMaybe<Scalars["uuid"]>;
  tool_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "Prep_Tools" */
export type Prep_Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prep_Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prep_Tools_Stream_Cursor_Value_Input = {
  prep_id?: InputMaybe<Scalars["uuid"]>;
  tool_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "Prep_Tools" */
export enum Prep_Tools_Update_Column {
  /** column name */
  PrepId = "prep_id",
  /** column name */
  ToolId = "tool_id",
}

export type Prep_Tools_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prep_Tools_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prep_Tools_Bool_Exp;
};

/** aggregated selection of "Prep" */
export type Prep_Aggregate = {
  __typename?: "Prep_aggregate";
  aggregate?: Maybe<Prep_Aggregate_Fields>;
  nodes: Array<Prep>;
};

export type Prep_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prep_Aggregate_Bool_Exp_Count>;
};

export type Prep_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prep_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Prep_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Prep" */
export type Prep_Aggregate_Fields = {
  __typename?: "Prep_aggregate_fields";
  avg?: Maybe<Prep_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Prep_Max_Fields>;
  min?: Maybe<Prep_Min_Fields>;
  stddev?: Maybe<Prep_Stddev_Fields>;
  stddev_pop?: Maybe<Prep_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prep_Stddev_Samp_Fields>;
  sum?: Maybe<Prep_Sum_Fields>;
  var_pop?: Maybe<Prep_Var_Pop_Fields>;
  var_samp?: Maybe<Prep_Var_Samp_Fields>;
  variance?: Maybe<Prep_Variance_Fields>;
};

/** aggregate fields of "Prep" */
export type Prep_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prep_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Prep" */
export type Prep_Aggregate_Order_By = {
  avg?: InputMaybe<Prep_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prep_Max_Order_By>;
  min?: InputMaybe<Prep_Min_Order_By>;
  stddev?: InputMaybe<Prep_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prep_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prep_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prep_Sum_Order_By>;
  var_pop?: InputMaybe<Prep_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prep_Var_Samp_Order_By>;
  variance?: InputMaybe<Prep_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Prep" */
export type Prep_Arr_Rel_Insert_Input = {
  data: Array<Prep_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_On_Conflict>;
};

/** aggregate avg on columns */
export type Prep_Avg_Fields = {
  __typename?: "Prep_avg_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Prep" */
export type Prep_Avg_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Prep". All fields are combined with a logical 'AND'. */
export type Prep_Bool_Exp = {
  AlternativePreps?: InputMaybe<Prep_Bool_Exp>;
  AlternativePreps_aggregate?: InputMaybe<Prep_Aggregate_Bool_Exp>;
  Dish?: InputMaybe<Dish_Bool_Exp>;
  Ingredients?: InputMaybe<Prep_Ingredients_Bool_Exp>;
  Ingredients_aggregate?: InputMaybe<Prep_Ingredients_Aggregate_Bool_Exp>;
  InputPreps?: InputMaybe<Prep_Preps_Bool_Exp>;
  InputPreps_aggregate?: InputMaybe<Prep_Preps_Aggregate_Bool_Exp>;
  MainPrep?: InputMaybe<Prep_Bool_Exp>;
  Steps?: InputMaybe<PrepStep_Bool_Exp>;
  Steps_aggregate?: InputMaybe<PrepStep_Aggregate_Bool_Exp>;
  Technic?: InputMaybe<Technic_Bool_Exp>;
  Technics?: InputMaybe<Prep_Technics_Bool_Exp>;
  Technics_aggregate?: InputMaybe<Prep_Technics_Aggregate_Bool_Exp>;
  Tools?: InputMaybe<Prep_Tools_Bool_Exp>;
  Tools_aggregate?: InputMaybe<Prep_Tools_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Prep_Bool_Exp>>;
  _not?: InputMaybe<Prep_Bool_Exp>;
  _or?: InputMaybe<Array<Prep_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dish_id?: InputMaybe<Uuid_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  main_prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  measure_unit?: InputMaybe<String_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rest_conditions?: InputMaybe<String_Comparison_Exp>;
  rest_time?: InputMaybe<Int_Comparison_Exp>;
  serving_size?: InputMaybe<Int_Comparison_Exp>;
  technic_id?: InputMaybe<Uuid_Comparison_Exp>;
  time_ahead?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prep" */
export enum Prep_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrepPkey = "Prep_pkey",
}

/** input type for incrementing numeric columns in table "Prep" */
export type Prep_Inc_Input = {
  duration?: InputMaybe<Scalars["Int"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Prep" */
export type Prep_Insert_Input = {
  AlternativePreps?: InputMaybe<Prep_Arr_Rel_Insert_Input>;
  Dish?: InputMaybe<Dish_Obj_Rel_Insert_Input>;
  Ingredients?: InputMaybe<Prep_Ingredients_Arr_Rel_Insert_Input>;
  InputPreps?: InputMaybe<Prep_Preps_Arr_Rel_Insert_Input>;
  MainPrep?: InputMaybe<Prep_Obj_Rel_Insert_Input>;
  Steps?: InputMaybe<PrepStep_Arr_Rel_Insert_Input>;
  Technic?: InputMaybe<Technic_Obj_Rel_Insert_Input>;
  Technics?: InputMaybe<Prep_Technics_Arr_Rel_Insert_Input>;
  Tools?: InputMaybe<Prep_Tools_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamp"]>;
  description?: InputMaybe<Scalars["String"]>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  main_prep_id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  rest_conditions?: InputMaybe<Scalars["String"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Prep_Max_Fields = {
  __typename?: "Prep_max_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  dish_id?: Maybe<Scalars["uuid"]>;
  duration?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  main_prep_id?: Maybe<Scalars["uuid"]>;
  measure_unit?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  rest_conditions?: Maybe<Scalars["String"]>;
  rest_time?: Maybe<Scalars["Int"]>;
  serving_size?: Maybe<Scalars["Int"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
  time_ahead?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** order by max() on columns of table "Prep" */
export type Prep_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dish_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_prep_id?: InputMaybe<Order_By>;
  measure_unit?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rest_conditions?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prep_Min_Fields = {
  __typename?: "Prep_min_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  description?: Maybe<Scalars["String"]>;
  dish_id?: Maybe<Scalars["uuid"]>;
  duration?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  main_prep_id?: Maybe<Scalars["uuid"]>;
  measure_unit?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  rest_conditions?: Maybe<Scalars["String"]>;
  rest_time?: Maybe<Scalars["Int"]>;
  serving_size?: Maybe<Scalars["Int"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
  time_ahead?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** order by min() on columns of table "Prep" */
export type Prep_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dish_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_prep_id?: InputMaybe<Order_By>;
  measure_unit?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rest_conditions?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Prep" */
export type Prep_Mutation_Response = {
  __typename?: "Prep_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Prep>;
};

/** input type for inserting object relation for remote table "Prep" */
export type Prep_Obj_Rel_Insert_Input = {
  data: Prep_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prep_On_Conflict>;
};

/** on_conflict condition type for table "Prep" */
export type Prep_On_Conflict = {
  constraint: Prep_Constraint;
  update_columns?: Array<Prep_Update_Column>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** Ordering options when selecting data from "Prep". */
export type Prep_Order_By = {
  AlternativePreps_aggregate?: InputMaybe<Prep_Aggregate_Order_By>;
  Dish?: InputMaybe<Dish_Order_By>;
  Ingredients_aggregate?: InputMaybe<Prep_Ingredients_Aggregate_Order_By>;
  InputPreps_aggregate?: InputMaybe<Prep_Preps_Aggregate_Order_By>;
  MainPrep?: InputMaybe<Prep_Order_By>;
  Steps_aggregate?: InputMaybe<PrepStep_Aggregate_Order_By>;
  Technic?: InputMaybe<Technic_Order_By>;
  Technics_aggregate?: InputMaybe<Prep_Technics_Aggregate_Order_By>;
  Tools_aggregate?: InputMaybe<Prep_Tools_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dish_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_prep_id?: InputMaybe<Order_By>;
  measure_unit?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rest_conditions?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Prep */
export type Prep_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Prep" */
export enum Prep_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  DishId = "dish_id",
  /** column name */
  Duration = "duration",
  /** column name */
  Id = "id",
  /** column name */
  MainPrepId = "main_prep_id",
  /** column name */
  MeasureUnit = "measure_unit",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  RestConditions = "rest_conditions",
  /** column name */
  RestTime = "rest_time",
  /** column name */
  ServingSize = "serving_size",
  /** column name */
  TechnicId = "technic_id",
  /** column name */
  TimeAhead = "time_ahead",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "Prep" */
export type Prep_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  description?: InputMaybe<Scalars["String"]>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  main_prep_id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  rest_conditions?: InputMaybe<Scalars["String"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** aggregate stddev on columns */
export type Prep_Stddev_Fields = {
  __typename?: "Prep_stddev_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Prep" */
export type Prep_Stddev_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Prep_Stddev_Pop_Fields = {
  __typename?: "Prep_stddev_pop_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Prep" */
export type Prep_Stddev_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Prep_Stddev_Samp_Fields = {
  __typename?: "Prep_stddev_samp_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Prep" */
export type Prep_Stddev_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Prep" */
export type Prep_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prep_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prep_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  description?: InputMaybe<Scalars["String"]>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  main_prep_id?: InputMaybe<Scalars["uuid"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  rest_conditions?: InputMaybe<Scalars["String"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
};

/** aggregate sum on columns */
export type Prep_Sum_Fields = {
  __typename?: "Prep_sum_fields";
  duration?: Maybe<Scalars["Int"]>;
  rest_time?: Maybe<Scalars["Int"]>;
  serving_size?: Maybe<Scalars["Int"]>;
  time_ahead?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Prep" */
export type Prep_Sum_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** update columns of table "Prep" */
export enum Prep_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  DishId = "dish_id",
  /** column name */
  Duration = "duration",
  /** column name */
  Id = "id",
  /** column name */
  MainPrepId = "main_prep_id",
  /** column name */
  MeasureUnit = "measure_unit",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  RestConditions = "rest_conditions",
  /** column name */
  RestTime = "rest_time",
  /** column name */
  ServingSize = "serving_size",
  /** column name */
  TechnicId = "technic_id",
  /** column name */
  TimeAhead = "time_ahead",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Prep_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prep_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prep_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prep_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prep_Var_Pop_Fields = {
  __typename?: "Prep_var_pop_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Prep" */
export type Prep_Var_Pop_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Prep_Var_Samp_Fields = {
  __typename?: "Prep_var_samp_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Prep" */
export type Prep_Var_Samp_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Prep_Variance_Fields = {
  __typename?: "Prep_variance_fields";
  duration?: Maybe<Scalars["Float"]>;
  rest_time?: Maybe<Scalars["Float"]>;
  serving_size?: Maybe<Scalars["Float"]>;
  time_ahead?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Prep" */
export type Prep_Variance_Order_By = {
  duration?: InputMaybe<Order_By>;
  rest_time?: InputMaybe<Order_By>;
  serving_size?: InputMaybe<Order_By>;
  time_ahead?: InputMaybe<Order_By>;
};

/** columns and relationships of "Recipe" */
export type Recipe = {
  __typename?: "Recipe";
  id: Scalars["uuid"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** columns and relationships of "Recipe_Preps" */
export type Recipe_Preps = {
  __typename?: "Recipe_Preps";
  /** An object relationship */
  Prep: Prep;
  order?: Maybe<Scalars["Int"]>;
  prep_id: Scalars["uuid"];
  recipe_id: Scalars["uuid"];
};

/** aggregated selection of "Recipe_Preps" */
export type Recipe_Preps_Aggregate = {
  __typename?: "Recipe_Preps_aggregate";
  aggregate?: Maybe<Recipe_Preps_Aggregate_Fields>;
  nodes: Array<Recipe_Preps>;
};

/** aggregate fields of "Recipe_Preps" */
export type Recipe_Preps_Aggregate_Fields = {
  __typename?: "Recipe_Preps_aggregate_fields";
  avg?: Maybe<Recipe_Preps_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Recipe_Preps_Max_Fields>;
  min?: Maybe<Recipe_Preps_Min_Fields>;
  stddev?: Maybe<Recipe_Preps_Stddev_Fields>;
  stddev_pop?: Maybe<Recipe_Preps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recipe_Preps_Stddev_Samp_Fields>;
  sum?: Maybe<Recipe_Preps_Sum_Fields>;
  var_pop?: Maybe<Recipe_Preps_Var_Pop_Fields>;
  var_samp?: Maybe<Recipe_Preps_Var_Samp_Fields>;
  variance?: Maybe<Recipe_Preps_Variance_Fields>;
};

/** aggregate fields of "Recipe_Preps" */
export type Recipe_Preps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recipe_Preps_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Recipe_Preps_Avg_Fields = {
  __typename?: "Recipe_Preps_avg_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Recipe_Preps". All fields are combined with a logical 'AND'. */
export type Recipe_Preps_Bool_Exp = {
  Prep?: InputMaybe<Prep_Bool_Exp>;
  _and?: InputMaybe<Array<Recipe_Preps_Bool_Exp>>;
  _not?: InputMaybe<Recipe_Preps_Bool_Exp>;
  _or?: InputMaybe<Array<Recipe_Preps_Bool_Exp>>;
  order?: InputMaybe<Int_Comparison_Exp>;
  prep_id?: InputMaybe<Uuid_Comparison_Exp>;
  recipe_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Recipe_Preps" */
export enum Recipe_Preps_Constraint {
  /** unique or primary key constraint on columns "recipe_id" */
  RecipePrepsPkey = "Recipe_Preps_pkey",
}

/** input type for incrementing numeric columns in table "Recipe_Preps" */
export type Recipe_Preps_Inc_Input = {
  order?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Recipe_Preps" */
export type Recipe_Preps_Insert_Input = {
  Prep?: InputMaybe<Prep_Obj_Rel_Insert_Input>;
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  recipe_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Recipe_Preps_Max_Fields = {
  __typename?: "Recipe_Preps_max_fields";
  order?: Maybe<Scalars["Int"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  recipe_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Recipe_Preps_Min_Fields = {
  __typename?: "Recipe_Preps_min_fields";
  order?: Maybe<Scalars["Int"]>;
  prep_id?: Maybe<Scalars["uuid"]>;
  recipe_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "Recipe_Preps" */
export type Recipe_Preps_Mutation_Response = {
  __typename?: "Recipe_Preps_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe_Preps>;
};

/** on_conflict condition type for table "Recipe_Preps" */
export type Recipe_Preps_On_Conflict = {
  constraint: Recipe_Preps_Constraint;
  update_columns?: Array<Recipe_Preps_Update_Column>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

/** Ordering options when selecting data from "Recipe_Preps". */
export type Recipe_Preps_Order_By = {
  Prep?: InputMaybe<Prep_Order_By>;
  order?: InputMaybe<Order_By>;
  prep_id?: InputMaybe<Order_By>;
  recipe_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Recipe_Preps */
export type Recipe_Preps_Pk_Columns_Input = {
  recipe_id: Scalars["uuid"];
};

/** select columns of table "Recipe_Preps" */
export enum Recipe_Preps_Select_Column {
  /** column name */
  Order = "order",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  RecipeId = "recipe_id",
}

/** input type for updating data in table "Recipe_Preps" */
export type Recipe_Preps_Set_Input = {
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  recipe_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Recipe_Preps_Stddev_Fields = {
  __typename?: "Recipe_Preps_stddev_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Recipe_Preps_Stddev_Pop_Fields = {
  __typename?: "Recipe_Preps_stddev_pop_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Recipe_Preps_Stddev_Samp_Fields = {
  __typename?: "Recipe_Preps_stddev_samp_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Recipe_Preps" */
export type Recipe_Preps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Preps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Preps_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars["Int"]>;
  prep_id?: InputMaybe<Scalars["uuid"]>;
  recipe_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Recipe_Preps_Sum_Fields = {
  __typename?: "Recipe_Preps_sum_fields";
  order?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Recipe_Preps" */
export enum Recipe_Preps_Update_Column {
  /** column name */
  Order = "order",
  /** column name */
  PrepId = "prep_id",
  /** column name */
  RecipeId = "recipe_id",
}

export type Recipe_Preps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recipe_Preps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recipe_Preps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Preps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recipe_Preps_Var_Pop_Fields = {
  __typename?: "Recipe_Preps_var_pop_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Recipe_Preps_Var_Samp_Fields = {
  __typename?: "Recipe_Preps_var_samp_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Recipe_Preps_Variance_Fields = {
  __typename?: "Recipe_Preps_variance_fields";
  order?: Maybe<Scalars["Float"]>;
};

/** aggregated selection of "Recipe" */
export type Recipe_Aggregate = {
  __typename?: "Recipe_aggregate";
  aggregate?: Maybe<Recipe_Aggregate_Fields>;
  nodes: Array<Recipe>;
};

/** aggregate fields of "Recipe" */
export type Recipe_Aggregate_Fields = {
  __typename?: "Recipe_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Recipe_Max_Fields>;
  min?: Maybe<Recipe_Min_Fields>;
};

/** aggregate fields of "Recipe" */
export type Recipe_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recipe_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "Recipe". All fields are combined with a logical 'AND'. */
export type Recipe_Bool_Exp = {
  _and?: InputMaybe<Array<Recipe_Bool_Exp>>;
  _not?: InputMaybe<Recipe_Bool_Exp>;
  _or?: InputMaybe<Array<Recipe_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Recipe" */
export enum Recipe_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipePkey = "Recipe_pkey",
}

/** input type for inserting data into table "Recipe" */
export type Recipe_Insert_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Recipe_Max_Fields = {
  __typename?: "Recipe_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Recipe_Min_Fields = {
  __typename?: "Recipe_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Recipe" */
export type Recipe_Mutation_Response = {
  __typename?: "Recipe_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe>;
};

/** on_conflict condition type for table "Recipe" */
export type Recipe_On_Conflict = {
  constraint: Recipe_Constraint;
  update_columns?: Array<Recipe_Update_Column>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

/** Ordering options when selecting data from "Recipe". */
export type Recipe_Order_By = {
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Recipe */
export type Recipe_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Recipe" */
export enum Recipe_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "Recipe" */
export type Recipe_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "Recipe" */
export type Recipe_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "Recipe" */
export enum Recipe_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
}

export type Recipe_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recipe_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Bool_Exp;
};

/** columns and relationships of "Season" */
export type Season = {
  __typename?: "Season";
  /** An array relationship */
  Ingredient_Seasons: Array<Ingredient_Seasons>;
  /** An aggregate relationship */
  Ingredient_Seasons_aggregate: Ingredient_Seasons_Aggregate;
  id: Scalars["uuid"];
  name: Scalars["String"];
};

/** columns and relationships of "Season" */
export type SeasonIngredient_SeasonsArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

/** columns and relationships of "Season" */
export type SeasonIngredient_Seasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

/** aggregated selection of "Season" */
export type Season_Aggregate = {
  __typename?: "Season_aggregate";
  aggregate?: Maybe<Season_Aggregate_Fields>;
  nodes: Array<Season>;
};

/** aggregate fields of "Season" */
export type Season_Aggregate_Fields = {
  __typename?: "Season_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Season_Max_Fields>;
  min?: Maybe<Season_Min_Fields>;
};

/** aggregate fields of "Season" */
export type Season_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Season_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "Season". All fields are combined with a logical 'AND'. */
export type Season_Bool_Exp = {
  Ingredient_Seasons?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
  Ingredient_Seasons_aggregate?: InputMaybe<Ingredient_Seasons_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Season_Bool_Exp>>;
  _not?: InputMaybe<Season_Bool_Exp>;
  _or?: InputMaybe<Array<Season_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Season" */
export enum Season_Constraint {
  /** unique or primary key constraint on columns "id" */
  SeasonPkey = "Season_pkey",
}

/** input type for inserting data into table "Season" */
export type Season_Insert_Input = {
  Ingredient_Seasons?: InputMaybe<Ingredient_Seasons_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Season_Max_Fields = {
  __typename?: "Season_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Season_Min_Fields = {
  __typename?: "Season_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Season" */
export type Season_Mutation_Response = {
  __typename?: "Season_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Season>;
};

/** input type for inserting object relation for remote table "Season" */
export type Season_Obj_Rel_Insert_Input = {
  data: Season_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Season_On_Conflict>;
};

/** on_conflict condition type for table "Season" */
export type Season_On_Conflict = {
  constraint: Season_Constraint;
  update_columns?: Array<Season_Update_Column>;
  where?: InputMaybe<Season_Bool_Exp>;
};

/** Ordering options when selecting data from "Season". */
export type Season_Order_By = {
  Ingredient_Seasons_aggregate?: InputMaybe<Ingredient_Seasons_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Season */
export type Season_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Season" */
export enum Season_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "Season" */
export type Season_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "Season" */
export type Season_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Season_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Season_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "Season" */
export enum Season_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type Season_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Season_Set_Input>;
  /** filter the rows which have to be updated */
  where: Season_Bool_Exp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "Technic" */
export type Technic = {
  __typename?: "Technic";
  /** An array relationship */
  Preps: Array<Prep>;
  /** An aggregate relationship */
  Preps_aggregate: Prep_Aggregate;
  /** An array relationship */
  TechnicTips: Array<TechnicTip>;
  /** An aggregate relationship */
  TechnicTips_aggregate: TechnicTip_Aggregate;
  difficulty: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  type: Scalars["String"];
};

/** columns and relationships of "Technic" */
export type TechnicPrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "Technic" */
export type TechnicPreps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** columns and relationships of "Technic" */
export type TechnicTechnicTipsArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

/** columns and relationships of "Technic" */
export type TechnicTechnicTips_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

/** columns and relationships of "TechnicTip" */
export type TechnicTip = {
  __typename?: "TechnicTip";
  /** An object relationship */
  Technic: Technic;
  description: Scalars["String"];
  id: Scalars["uuid"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  technic_id: Scalars["uuid"];
};

/** aggregated selection of "TechnicTip" */
export type TechnicTip_Aggregate = {
  __typename?: "TechnicTip_aggregate";
  aggregate?: Maybe<TechnicTip_Aggregate_Fields>;
  nodes: Array<TechnicTip>;
};

export type TechnicTip_Aggregate_Bool_Exp = {
  count?: InputMaybe<TechnicTip_Aggregate_Bool_Exp_Count>;
};

export type TechnicTip_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TechnicTip_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TechnicTip_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TechnicTip" */
export type TechnicTip_Aggregate_Fields = {
  __typename?: "TechnicTip_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<TechnicTip_Max_Fields>;
  min?: Maybe<TechnicTip_Min_Fields>;
};

/** aggregate fields of "TechnicTip" */
export type TechnicTip_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TechnicTip_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "TechnicTip" */
export type TechnicTip_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TechnicTip_Max_Order_By>;
  min?: InputMaybe<TechnicTip_Min_Order_By>;
};

/** input type for inserting array relation for remote table "TechnicTip" */
export type TechnicTip_Arr_Rel_Insert_Input = {
  data: Array<TechnicTip_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TechnicTip_On_Conflict>;
};

/** Boolean expression to filter rows from the table "TechnicTip". All fields are combined with a logical 'AND'. */
export type TechnicTip_Bool_Exp = {
  Technic?: InputMaybe<Technic_Bool_Exp>;
  _and?: InputMaybe<Array<TechnicTip_Bool_Exp>>;
  _not?: InputMaybe<TechnicTip_Bool_Exp>;
  _or?: InputMaybe<Array<TechnicTip_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  technic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "TechnicTip" */
export enum TechnicTip_Constraint {
  /** unique or primary key constraint on columns "id" */
  TechnicTipPkey = "TechnicTip_pkey",
}

/** input type for inserting data into table "TechnicTip" */
export type TechnicTip_Insert_Input = {
  Technic?: InputMaybe<Technic_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type TechnicTip_Max_Fields = {
  __typename?: "TechnicTip_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "TechnicTip" */
export type TechnicTip_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TechnicTip_Min_Fields = {
  __typename?: "TechnicTip_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  technic_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "TechnicTip" */
export type TechnicTip_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TechnicTip" */
export type TechnicTip_Mutation_Response = {
  __typename?: "TechnicTip_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TechnicTip>;
};

/** on_conflict condition type for table "TechnicTip" */
export type TechnicTip_On_Conflict = {
  constraint: TechnicTip_Constraint;
  update_columns?: Array<TechnicTip_Update_Column>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

/** Ordering options when selecting data from "TechnicTip". */
export type TechnicTip_Order_By = {
  Technic?: InputMaybe<Technic_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  technic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TechnicTip */
export type TechnicTip_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "TechnicTip" */
export enum TechnicTip_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  TechnicId = "technic_id",
}

/** input type for updating data in table "TechnicTip" */
export type TechnicTip_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "TechnicTip" */
export type TechnicTip_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TechnicTip_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TechnicTip_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "TechnicTip" */
export enum TechnicTip_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  TechnicId = "technic_id",
}

export type TechnicTip_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TechnicTip_Set_Input>;
  /** filter the rows which have to be updated */
  where: TechnicTip_Bool_Exp;
};

/** aggregated selection of "Technic" */
export type Technic_Aggregate = {
  __typename?: "Technic_aggregate";
  aggregate?: Maybe<Technic_Aggregate_Fields>;
  nodes: Array<Technic>;
};

/** aggregate fields of "Technic" */
export type Technic_Aggregate_Fields = {
  __typename?: "Technic_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Technic_Max_Fields>;
  min?: Maybe<Technic_Min_Fields>;
};

/** aggregate fields of "Technic" */
export type Technic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Technic_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "Technic". All fields are combined with a logical 'AND'. */
export type Technic_Bool_Exp = {
  Preps?: InputMaybe<Prep_Bool_Exp>;
  Preps_aggregate?: InputMaybe<Prep_Aggregate_Bool_Exp>;
  TechnicTips?: InputMaybe<TechnicTip_Bool_Exp>;
  TechnicTips_aggregate?: InputMaybe<TechnicTip_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Technic_Bool_Exp>>;
  _not?: InputMaybe<Technic_Bool_Exp>;
  _or?: InputMaybe<Array<Technic_Bool_Exp>>;
  difficulty?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Technic" */
export enum Technic_Constraint {
  /** unique or primary key constraint on columns "id" */
  TechnicPkey = "Technic_pkey",
}

/** input type for inserting data into table "Technic" */
export type Technic_Insert_Input = {
  Preps?: InputMaybe<Prep_Arr_Rel_Insert_Input>;
  TechnicTips?: InputMaybe<TechnicTip_Arr_Rel_Insert_Input>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Technic_Max_Fields = {
  __typename?: "Technic_max_fields";
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Technic_Min_Fields = {
  __typename?: "Technic_min_fields";
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Technic" */
export type Technic_Mutation_Response = {
  __typename?: "Technic_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Technic>;
};

/** input type for inserting object relation for remote table "Technic" */
export type Technic_Obj_Rel_Insert_Input = {
  data: Technic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Technic_On_Conflict>;
};

/** on_conflict condition type for table "Technic" */
export type Technic_On_Conflict = {
  constraint: Technic_Constraint;
  update_columns?: Array<Technic_Update_Column>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

/** Ordering options when selecting data from "Technic". */
export type Technic_Order_By = {
  Preps_aggregate?: InputMaybe<Prep_Aggregate_Order_By>;
  TechnicTips_aggregate?: InputMaybe<TechnicTip_Aggregate_Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Technic */
export type Technic_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Technic" */
export enum Technic_Select_Column {
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "Technic" */
export type Technic_Set_Input = {
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "Technic" */
export type Technic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Technic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Technic_Stream_Cursor_Value_Input = {
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "Technic" */
export enum Technic_Update_Column {
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Type = "type",
}

export type Technic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Technic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Technic_Bool_Exp;
};

/** columns and relationships of "Tool" */
export type Tool = {
  __typename?: "Tool";
  /** An array relationship */
  Prep_Tools: Array<Prep_Tools>;
  /** An aggregate relationship */
  Prep_Tools_aggregate: Prep_Tools_Aggregate;
  availability: Scalars["String"];
  difficulty: Scalars["String"];
  id: Scalars["uuid"];
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pre_heat_time: Scalars["Int"];
  type: Scalars["String"];
};

/** columns and relationships of "Tool" */
export type ToolPrep_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

/** columns and relationships of "Tool" */
export type ToolPrep_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

/** aggregated selection of "Tool" */
export type Tool_Aggregate = {
  __typename?: "Tool_aggregate";
  aggregate?: Maybe<Tool_Aggregate_Fields>;
  nodes: Array<Tool>;
};

/** aggregate fields of "Tool" */
export type Tool_Aggregate_Fields = {
  __typename?: "Tool_aggregate_fields";
  avg?: Maybe<Tool_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Tool_Max_Fields>;
  min?: Maybe<Tool_Min_Fields>;
  stddev?: Maybe<Tool_Stddev_Fields>;
  stddev_pop?: Maybe<Tool_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tool_Stddev_Samp_Fields>;
  sum?: Maybe<Tool_Sum_Fields>;
  var_pop?: Maybe<Tool_Var_Pop_Fields>;
  var_samp?: Maybe<Tool_Var_Samp_Fields>;
  variance?: Maybe<Tool_Variance_Fields>;
};

/** aggregate fields of "Tool" */
export type Tool_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tool_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Tool_Avg_Fields = {
  __typename?: "Tool_avg_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "Tool". All fields are combined with a logical 'AND'. */
export type Tool_Bool_Exp = {
  Prep_Tools?: InputMaybe<Prep_Tools_Bool_Exp>;
  Prep_Tools_aggregate?: InputMaybe<Prep_Tools_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Tool_Bool_Exp>>;
  _not?: InputMaybe<Tool_Bool_Exp>;
  _or?: InputMaybe<Array<Tool_Bool_Exp>>;
  availability?: InputMaybe<String_Comparison_Exp>;
  difficulty?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pre_heat_time?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tool" */
export enum Tool_Constraint {
  /** unique or primary key constraint on columns "id" */
  ToolPkey = "Tool_pkey",
}

/** input type for incrementing numeric columns in table "Tool" */
export type Tool_Inc_Input = {
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Tool" */
export type Tool_Insert_Input = {
  Prep_Tools?: InputMaybe<Prep_Tools_Arr_Rel_Insert_Input>;
  availability?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Tool_Max_Fields = {
  __typename?: "Tool_max_fields";
  availability?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  pre_heat_time?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Tool_Min_Fields = {
  __typename?: "Tool_min_fields";
  availability?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  pre_heat_time?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "Tool" */
export type Tool_Mutation_Response = {
  __typename?: "Tool_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tool>;
};

/** input type for inserting object relation for remote table "Tool" */
export type Tool_Obj_Rel_Insert_Input = {
  data: Tool_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tool_On_Conflict>;
};

/** on_conflict condition type for table "Tool" */
export type Tool_On_Conflict = {
  constraint: Tool_Constraint;
  update_columns?: Array<Tool_Update_Column>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

/** Ordering options when selecting data from "Tool". */
export type Tool_Order_By = {
  Prep_Tools_aggregate?: InputMaybe<Prep_Tools_Aggregate_Order_By>;
  availability?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pre_heat_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tool */
export type Tool_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Tool" */
export enum Tool_Select_Column {
  /** column name */
  Availability = "availability",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  PreHeatTime = "pre_heat_time",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "Tool" */
export type Tool_Set_Input = {
  availability?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Tool_Stddev_Fields = {
  __typename?: "Tool_stddev_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Tool_Stddev_Pop_Fields = {
  __typename?: "Tool_stddev_pop_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Tool_Stddev_Samp_Fields = {
  __typename?: "Tool_stddev_samp_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "Tool" */
export type Tool_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tool_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tool_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  media?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Tool_Sum_Fields = {
  __typename?: "Tool_sum_fields";
  pre_heat_time?: Maybe<Scalars["Int"]>;
};

/** update columns of table "Tool" */
export enum Tool_Update_Column {
  /** column name */
  Availability = "availability",
  /** column name */
  Difficulty = "difficulty",
  /** column name */
  Id = "id",
  /** column name */
  Media = "media",
  /** column name */
  Name = "name",
  /** column name */
  PreHeatTime = "pre_heat_time",
  /** column name */
  Type = "type",
}

export type Tool_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tool_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tool_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tool_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tool_Var_Pop_Fields = {
  __typename?: "Tool_var_pop_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Tool_Var_Samp_Fields = {
  __typename?: "Tool_var_samp_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Tool_Variance_Fields = {
  __typename?: "Tool_variance_fields";
  pre_heat_time?: Maybe<Scalars["Float"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "CookingStyle" */
  delete_CookingStyle?: Maybe<CookingStyle_Mutation_Response>;
  /** delete single row from the table: "CookingStyle" */
  delete_CookingStyle_by_pk?: Maybe<CookingStyle>;
  /** delete data from the table: "Dish" */
  delete_Dish?: Maybe<Dish_Mutation_Response>;
  /** delete data from the table: "DishCategory" */
  delete_DishCategory?: Maybe<DishCategory_Mutation_Response>;
  /** delete single row from the table: "DishCategory" */
  delete_DishCategory_by_pk?: Maybe<DishCategory>;
  /** delete single row from the table: "Dish" */
  delete_Dish_by_pk?: Maybe<Dish>;
  /** delete data from the table: "Ingredient" */
  delete_Ingredient?: Maybe<Ingredient_Mutation_Response>;
  /** delete data from the table: "Ingredient_CookingStyles" */
  delete_Ingredient_CookingStyles?: Maybe<Ingredient_CookingStyles_Mutation_Response>;
  /** delete single row from the table: "Ingredient_CookingStyles" */
  delete_Ingredient_CookingStyles_by_pk?: Maybe<Ingredient_CookingStyles>;
  /** delete data from the table: "Ingredient_Seasons" */
  delete_Ingredient_Seasons?: Maybe<Ingredient_Seasons_Mutation_Response>;
  /** delete single row from the table: "Ingredient_Seasons" */
  delete_Ingredient_Seasons_by_pk?: Maybe<Ingredient_Seasons>;
  /** delete single row from the table: "Ingredient" */
  delete_Ingredient_by_pk?: Maybe<Ingredient>;
  /** delete data from the table: "Meal" */
  delete_Meal?: Maybe<Meal_Mutation_Response>;
  /** delete data from the table: "MealDish" */
  delete_MealDish?: Maybe<MealDish_Mutation_Response>;
  /** delete single row from the table: "MealDish" */
  delete_MealDish_by_pk?: Maybe<MealDish>;
  /** delete single row from the table: "Meal" */
  delete_Meal_by_pk?: Maybe<Meal>;
  /** delete data from the table: "Prep" */
  delete_Prep?: Maybe<Prep_Mutation_Response>;
  /** delete data from the table: "PrepStep" */
  delete_PrepStep?: Maybe<PrepStep_Mutation_Response>;
  /** delete single row from the table: "PrepStep" */
  delete_PrepStep_by_pk?: Maybe<PrepStep>;
  /** delete data from the table: "Prep_Ingredients" */
  delete_Prep_Ingredients?: Maybe<Prep_Ingredients_Mutation_Response>;
  /** delete single row from the table: "Prep_Ingredients" */
  delete_Prep_Ingredients_by_pk?: Maybe<Prep_Ingredients>;
  /** delete data from the table: "Prep_Preps" */
  delete_Prep_Preps?: Maybe<Prep_Preps_Mutation_Response>;
  /** delete single row from the table: "Prep_Preps" */
  delete_Prep_Preps_by_pk?: Maybe<Prep_Preps>;
  /** delete data from the table: "Prep_Technics" */
  delete_Prep_Technics?: Maybe<Prep_Technics_Mutation_Response>;
  /** delete single row from the table: "Prep_Technics" */
  delete_Prep_Technics_by_pk?: Maybe<Prep_Technics>;
  /** delete data from the table: "Prep_Tools" */
  delete_Prep_Tools?: Maybe<Prep_Tools_Mutation_Response>;
  /** delete single row from the table: "Prep_Tools" */
  delete_Prep_Tools_by_pk?: Maybe<Prep_Tools>;
  /** delete single row from the table: "Prep" */
  delete_Prep_by_pk?: Maybe<Prep>;
  /** delete data from the table: "Recipe" */
  delete_Recipe?: Maybe<Recipe_Mutation_Response>;
  /** delete data from the table: "Recipe_Preps" */
  delete_Recipe_Preps?: Maybe<Recipe_Preps_Mutation_Response>;
  /** delete single row from the table: "Recipe_Preps" */
  delete_Recipe_Preps_by_pk?: Maybe<Recipe_Preps>;
  /** delete single row from the table: "Recipe" */
  delete_Recipe_by_pk?: Maybe<Recipe>;
  /** delete data from the table: "Season" */
  delete_Season?: Maybe<Season_Mutation_Response>;
  /** delete single row from the table: "Season" */
  delete_Season_by_pk?: Maybe<Season>;
  /** delete data from the table: "Technic" */
  delete_Technic?: Maybe<Technic_Mutation_Response>;
  /** delete data from the table: "TechnicTip" */
  delete_TechnicTip?: Maybe<TechnicTip_Mutation_Response>;
  /** delete single row from the table: "TechnicTip" */
  delete_TechnicTip_by_pk?: Maybe<TechnicTip>;
  /** delete single row from the table: "Technic" */
  delete_Technic_by_pk?: Maybe<Technic>;
  /** delete data from the table: "Tool" */
  delete_Tool?: Maybe<Tool_Mutation_Response>;
  /** delete single row from the table: "Tool" */
  delete_Tool_by_pk?: Maybe<Tool>;
  /** insert data into the table: "CookingStyle" */
  insert_CookingStyle?: Maybe<CookingStyle_Mutation_Response>;
  /** insert a single row into the table: "CookingStyle" */
  insert_CookingStyle_one?: Maybe<CookingStyle>;
  /** insert data into the table: "Dish" */
  insert_Dish?: Maybe<Dish_Mutation_Response>;
  /** insert data into the table: "DishCategory" */
  insert_DishCategory?: Maybe<DishCategory_Mutation_Response>;
  /** insert a single row into the table: "DishCategory" */
  insert_DishCategory_one?: Maybe<DishCategory>;
  /** insert a single row into the table: "Dish" */
  insert_Dish_one?: Maybe<Dish>;
  /** insert data into the table: "Ingredient" */
  insert_Ingredient?: Maybe<Ingredient_Mutation_Response>;
  /** insert data into the table: "Ingredient_CookingStyles" */
  insert_Ingredient_CookingStyles?: Maybe<Ingredient_CookingStyles_Mutation_Response>;
  /** insert a single row into the table: "Ingredient_CookingStyles" */
  insert_Ingredient_CookingStyles_one?: Maybe<Ingredient_CookingStyles>;
  /** insert data into the table: "Ingredient_Seasons" */
  insert_Ingredient_Seasons?: Maybe<Ingredient_Seasons_Mutation_Response>;
  /** insert a single row into the table: "Ingredient_Seasons" */
  insert_Ingredient_Seasons_one?: Maybe<Ingredient_Seasons>;
  /** insert a single row into the table: "Ingredient" */
  insert_Ingredient_one?: Maybe<Ingredient>;
  /** insert data into the table: "Meal" */
  insert_Meal?: Maybe<Meal_Mutation_Response>;
  /** insert data into the table: "MealDish" */
  insert_MealDish?: Maybe<MealDish_Mutation_Response>;
  /** insert a single row into the table: "MealDish" */
  insert_MealDish_one?: Maybe<MealDish>;
  /** insert a single row into the table: "Meal" */
  insert_Meal_one?: Maybe<Meal>;
  /** insert data into the table: "Prep" */
  insert_Prep?: Maybe<Prep_Mutation_Response>;
  /** insert data into the table: "PrepStep" */
  insert_PrepStep?: Maybe<PrepStep_Mutation_Response>;
  /** insert a single row into the table: "PrepStep" */
  insert_PrepStep_one?: Maybe<PrepStep>;
  /** insert data into the table: "Prep_Ingredients" */
  insert_Prep_Ingredients?: Maybe<Prep_Ingredients_Mutation_Response>;
  /** insert a single row into the table: "Prep_Ingredients" */
  insert_Prep_Ingredients_one?: Maybe<Prep_Ingredients>;
  /** insert data into the table: "Prep_Preps" */
  insert_Prep_Preps?: Maybe<Prep_Preps_Mutation_Response>;
  /** insert a single row into the table: "Prep_Preps" */
  insert_Prep_Preps_one?: Maybe<Prep_Preps>;
  /** insert data into the table: "Prep_Technics" */
  insert_Prep_Technics?: Maybe<Prep_Technics_Mutation_Response>;
  /** insert a single row into the table: "Prep_Technics" */
  insert_Prep_Technics_one?: Maybe<Prep_Technics>;
  /** insert data into the table: "Prep_Tools" */
  insert_Prep_Tools?: Maybe<Prep_Tools_Mutation_Response>;
  /** insert a single row into the table: "Prep_Tools" */
  insert_Prep_Tools_one?: Maybe<Prep_Tools>;
  /** insert a single row into the table: "Prep" */
  insert_Prep_one?: Maybe<Prep>;
  /** insert data into the table: "Recipe" */
  insert_Recipe?: Maybe<Recipe_Mutation_Response>;
  /** insert data into the table: "Recipe_Preps" */
  insert_Recipe_Preps?: Maybe<Recipe_Preps_Mutation_Response>;
  /** insert a single row into the table: "Recipe_Preps" */
  insert_Recipe_Preps_one?: Maybe<Recipe_Preps>;
  /** insert a single row into the table: "Recipe" */
  insert_Recipe_one?: Maybe<Recipe>;
  /** insert data into the table: "Season" */
  insert_Season?: Maybe<Season_Mutation_Response>;
  /** insert a single row into the table: "Season" */
  insert_Season_one?: Maybe<Season>;
  /** insert data into the table: "Technic" */
  insert_Technic?: Maybe<Technic_Mutation_Response>;
  /** insert data into the table: "TechnicTip" */
  insert_TechnicTip?: Maybe<TechnicTip_Mutation_Response>;
  /** insert a single row into the table: "TechnicTip" */
  insert_TechnicTip_one?: Maybe<TechnicTip>;
  /** insert a single row into the table: "Technic" */
  insert_Technic_one?: Maybe<Technic>;
  /** insert data into the table: "Tool" */
  insert_Tool?: Maybe<Tool_Mutation_Response>;
  /** insert a single row into the table: "Tool" */
  insert_Tool_one?: Maybe<Tool>;
  /** update data of the table: "CookingStyle" */
  update_CookingStyle?: Maybe<CookingStyle_Mutation_Response>;
  /** update single row of the table: "CookingStyle" */
  update_CookingStyle_by_pk?: Maybe<CookingStyle>;
  /** update multiples rows of table: "CookingStyle" */
  update_CookingStyle_many?: Maybe<
    Array<Maybe<CookingStyle_Mutation_Response>>
  >;
  /** update data of the table: "Dish" */
  update_Dish?: Maybe<Dish_Mutation_Response>;
  /** update data of the table: "DishCategory" */
  update_DishCategory?: Maybe<DishCategory_Mutation_Response>;
  /** update single row of the table: "DishCategory" */
  update_DishCategory_by_pk?: Maybe<DishCategory>;
  /** update multiples rows of table: "DishCategory" */
  update_DishCategory_many?: Maybe<
    Array<Maybe<DishCategory_Mutation_Response>>
  >;
  /** update single row of the table: "Dish" */
  update_Dish_by_pk?: Maybe<Dish>;
  /** update multiples rows of table: "Dish" */
  update_Dish_many?: Maybe<Array<Maybe<Dish_Mutation_Response>>>;
  /** update data of the table: "Ingredient" */
  update_Ingredient?: Maybe<Ingredient_Mutation_Response>;
  /** update data of the table: "Ingredient_CookingStyles" */
  update_Ingredient_CookingStyles?: Maybe<Ingredient_CookingStyles_Mutation_Response>;
  /** update single row of the table: "Ingredient_CookingStyles" */
  update_Ingredient_CookingStyles_by_pk?: Maybe<Ingredient_CookingStyles>;
  /** update multiples rows of table: "Ingredient_CookingStyles" */
  update_Ingredient_CookingStyles_many?: Maybe<
    Array<Maybe<Ingredient_CookingStyles_Mutation_Response>>
  >;
  /** update data of the table: "Ingredient_Seasons" */
  update_Ingredient_Seasons?: Maybe<Ingredient_Seasons_Mutation_Response>;
  /** update single row of the table: "Ingredient_Seasons" */
  update_Ingredient_Seasons_by_pk?: Maybe<Ingredient_Seasons>;
  /** update multiples rows of table: "Ingredient_Seasons" */
  update_Ingredient_Seasons_many?: Maybe<
    Array<Maybe<Ingredient_Seasons_Mutation_Response>>
  >;
  /** update single row of the table: "Ingredient" */
  update_Ingredient_by_pk?: Maybe<Ingredient>;
  /** update multiples rows of table: "Ingredient" */
  update_Ingredient_many?: Maybe<Array<Maybe<Ingredient_Mutation_Response>>>;
  /** update data of the table: "Meal" */
  update_Meal?: Maybe<Meal_Mutation_Response>;
  /** update data of the table: "MealDish" */
  update_MealDish?: Maybe<MealDish_Mutation_Response>;
  /** update single row of the table: "MealDish" */
  update_MealDish_by_pk?: Maybe<MealDish>;
  /** update multiples rows of table: "MealDish" */
  update_MealDish_many?: Maybe<Array<Maybe<MealDish_Mutation_Response>>>;
  /** update single row of the table: "Meal" */
  update_Meal_by_pk?: Maybe<Meal>;
  /** update multiples rows of table: "Meal" */
  update_Meal_many?: Maybe<Array<Maybe<Meal_Mutation_Response>>>;
  /** update data of the table: "Prep" */
  update_Prep?: Maybe<Prep_Mutation_Response>;
  /** update data of the table: "PrepStep" */
  update_PrepStep?: Maybe<PrepStep_Mutation_Response>;
  /** update single row of the table: "PrepStep" */
  update_PrepStep_by_pk?: Maybe<PrepStep>;
  /** update multiples rows of table: "PrepStep" */
  update_PrepStep_many?: Maybe<Array<Maybe<PrepStep_Mutation_Response>>>;
  /** update data of the table: "Prep_Ingredients" */
  update_Prep_Ingredients?: Maybe<Prep_Ingredients_Mutation_Response>;
  /** update single row of the table: "Prep_Ingredients" */
  update_Prep_Ingredients_by_pk?: Maybe<Prep_Ingredients>;
  /** update multiples rows of table: "Prep_Ingredients" */
  update_Prep_Ingredients_many?: Maybe<
    Array<Maybe<Prep_Ingredients_Mutation_Response>>
  >;
  /** update data of the table: "Prep_Preps" */
  update_Prep_Preps?: Maybe<Prep_Preps_Mutation_Response>;
  /** update single row of the table: "Prep_Preps" */
  update_Prep_Preps_by_pk?: Maybe<Prep_Preps>;
  /** update multiples rows of table: "Prep_Preps" */
  update_Prep_Preps_many?: Maybe<Array<Maybe<Prep_Preps_Mutation_Response>>>;
  /** update data of the table: "Prep_Technics" */
  update_Prep_Technics?: Maybe<Prep_Technics_Mutation_Response>;
  /** update single row of the table: "Prep_Technics" */
  update_Prep_Technics_by_pk?: Maybe<Prep_Technics>;
  /** update multiples rows of table: "Prep_Technics" */
  update_Prep_Technics_many?: Maybe<
    Array<Maybe<Prep_Technics_Mutation_Response>>
  >;
  /** update data of the table: "Prep_Tools" */
  update_Prep_Tools?: Maybe<Prep_Tools_Mutation_Response>;
  /** update single row of the table: "Prep_Tools" */
  update_Prep_Tools_by_pk?: Maybe<Prep_Tools>;
  /** update multiples rows of table: "Prep_Tools" */
  update_Prep_Tools_many?: Maybe<Array<Maybe<Prep_Tools_Mutation_Response>>>;
  /** update single row of the table: "Prep" */
  update_Prep_by_pk?: Maybe<Prep>;
  /** update multiples rows of table: "Prep" */
  update_Prep_many?: Maybe<Array<Maybe<Prep_Mutation_Response>>>;
  /** update data of the table: "Recipe" */
  update_Recipe?: Maybe<Recipe_Mutation_Response>;
  /** update data of the table: "Recipe_Preps" */
  update_Recipe_Preps?: Maybe<Recipe_Preps_Mutation_Response>;
  /** update single row of the table: "Recipe_Preps" */
  update_Recipe_Preps_by_pk?: Maybe<Recipe_Preps>;
  /** update multiples rows of table: "Recipe_Preps" */
  update_Recipe_Preps_many?: Maybe<
    Array<Maybe<Recipe_Preps_Mutation_Response>>
  >;
  /** update single row of the table: "Recipe" */
  update_Recipe_by_pk?: Maybe<Recipe>;
  /** update multiples rows of table: "Recipe" */
  update_Recipe_many?: Maybe<Array<Maybe<Recipe_Mutation_Response>>>;
  /** update data of the table: "Season" */
  update_Season?: Maybe<Season_Mutation_Response>;
  /** update single row of the table: "Season" */
  update_Season_by_pk?: Maybe<Season>;
  /** update multiples rows of table: "Season" */
  update_Season_many?: Maybe<Array<Maybe<Season_Mutation_Response>>>;
  /** update data of the table: "Technic" */
  update_Technic?: Maybe<Technic_Mutation_Response>;
  /** update data of the table: "TechnicTip" */
  update_TechnicTip?: Maybe<TechnicTip_Mutation_Response>;
  /** update single row of the table: "TechnicTip" */
  update_TechnicTip_by_pk?: Maybe<TechnicTip>;
  /** update multiples rows of table: "TechnicTip" */
  update_TechnicTip_many?: Maybe<Array<Maybe<TechnicTip_Mutation_Response>>>;
  /** update single row of the table: "Technic" */
  update_Technic_by_pk?: Maybe<Technic>;
  /** update multiples rows of table: "Technic" */
  update_Technic_many?: Maybe<Array<Maybe<Technic_Mutation_Response>>>;
  /** update data of the table: "Tool" */
  update_Tool?: Maybe<Tool_Mutation_Response>;
  /** update single row of the table: "Tool" */
  update_Tool_by_pk?: Maybe<Tool>;
  /** update multiples rows of table: "Tool" */
  update_Tool_many?: Maybe<Array<Maybe<Tool_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_CookingStyleArgs = {
  where: CookingStyle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CookingStyle_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_DishArgs = {
  where: Dish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DishCategoryArgs = {
  where: DishCategory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DishCategory_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Dish_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_IngredientArgs = {
  where: Ingredient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ingredient_CookingStylesArgs = {
  where: Ingredient_CookingStyles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ingredient_CookingStyles_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Ingredient_SeasonsArgs = {
  where: Ingredient_Seasons_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ingredient_Seasons_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Ingredient_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MealArgs = {
  where: Meal_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MealDishArgs = {
  where: MealDish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MealDish_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Meal_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_PrepArgs = {
  where: Prep_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PrepStepArgs = {
  where: PrepStep_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PrepStep_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Prep_IngredientsArgs = {
  where: Prep_Ingredients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Prep_Ingredients_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Prep_PrepsArgs = {
  where: Prep_Preps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Prep_Preps_By_PkArgs = {
  input_prep_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Prep_TechnicsArgs = {
  where: Prep_Technics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Prep_Technics_By_PkArgs = {
  prep_id: Scalars["uuid"];
  technic_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Prep_ToolsArgs = {
  where: Prep_Tools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Prep_Tools_By_PkArgs = {
  prep_id: Scalars["uuid"];
  tool_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Prep_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_RecipeArgs = {
  where: Recipe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_PrepsArgs = {
  where: Recipe_Preps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_Preps_By_PkArgs = {
  recipe_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Recipe_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_SeasonArgs = {
  where: Season_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Season_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_TechnicArgs = {
  where: Technic_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TechnicTipArgs = {
  where: TechnicTip_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TechnicTip_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Technic_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ToolArgs = {
  where: Tool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tool_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootInsert_CookingStyleArgs = {
  objects: Array<CookingStyle_Insert_Input>;
  on_conflict?: InputMaybe<CookingStyle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CookingStyle_OneArgs = {
  object: CookingStyle_Insert_Input;
  on_conflict?: InputMaybe<CookingStyle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DishArgs = {
  objects: Array<Dish_Insert_Input>;
  on_conflict?: InputMaybe<Dish_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DishCategoryArgs = {
  objects: Array<DishCategory_Insert_Input>;
  on_conflict?: InputMaybe<DishCategory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DishCategory_OneArgs = {
  object: DishCategory_Insert_Input;
  on_conflict?: InputMaybe<DishCategory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dish_OneArgs = {
  object: Dish_Insert_Input;
  on_conflict?: InputMaybe<Dish_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IngredientArgs = {
  objects: Array<Ingredient_Insert_Input>;
  on_conflict?: InputMaybe<Ingredient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredient_CookingStylesArgs = {
  objects: Array<Ingredient_CookingStyles_Insert_Input>;
  on_conflict?: InputMaybe<Ingredient_CookingStyles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredient_CookingStyles_OneArgs = {
  object: Ingredient_CookingStyles_Insert_Input;
  on_conflict?: InputMaybe<Ingredient_CookingStyles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredient_SeasonsArgs = {
  objects: Array<Ingredient_Seasons_Insert_Input>;
  on_conflict?: InputMaybe<Ingredient_Seasons_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredient_Seasons_OneArgs = {
  object: Ingredient_Seasons_Insert_Input;
  on_conflict?: InputMaybe<Ingredient_Seasons_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredient_OneArgs = {
  object: Ingredient_Insert_Input;
  on_conflict?: InputMaybe<Ingredient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MealArgs = {
  objects: Array<Meal_Insert_Input>;
  on_conflict?: InputMaybe<Meal_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MealDishArgs = {
  objects: Array<MealDish_Insert_Input>;
  on_conflict?: InputMaybe<MealDish_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MealDish_OneArgs = {
  object: MealDish_Insert_Input;
  on_conflict?: InputMaybe<MealDish_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Meal_OneArgs = {
  object: Meal_Insert_Input;
  on_conflict?: InputMaybe<Meal_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PrepArgs = {
  objects: Array<Prep_Insert_Input>;
  on_conflict?: InputMaybe<Prep_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PrepStepArgs = {
  objects: Array<PrepStep_Insert_Input>;
  on_conflict?: InputMaybe<PrepStep_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PrepStep_OneArgs = {
  object: PrepStep_Insert_Input;
  on_conflict?: InputMaybe<PrepStep_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_IngredientsArgs = {
  objects: Array<Prep_Ingredients_Insert_Input>;
  on_conflict?: InputMaybe<Prep_Ingredients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_Ingredients_OneArgs = {
  object: Prep_Ingredients_Insert_Input;
  on_conflict?: InputMaybe<Prep_Ingredients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_PrepsArgs = {
  objects: Array<Prep_Preps_Insert_Input>;
  on_conflict?: InputMaybe<Prep_Preps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_Preps_OneArgs = {
  object: Prep_Preps_Insert_Input;
  on_conflict?: InputMaybe<Prep_Preps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_TechnicsArgs = {
  objects: Array<Prep_Technics_Insert_Input>;
  on_conflict?: InputMaybe<Prep_Technics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_Technics_OneArgs = {
  object: Prep_Technics_Insert_Input;
  on_conflict?: InputMaybe<Prep_Technics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_ToolsArgs = {
  objects: Array<Prep_Tools_Insert_Input>;
  on_conflict?: InputMaybe<Prep_Tools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_Tools_OneArgs = {
  object: Prep_Tools_Insert_Input;
  on_conflict?: InputMaybe<Prep_Tools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prep_OneArgs = {
  object: Prep_Insert_Input;
  on_conflict?: InputMaybe<Prep_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RecipeArgs = {
  objects: Array<Recipe_Insert_Input>;
  on_conflict?: InputMaybe<Recipe_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_PrepsArgs = {
  objects: Array<Recipe_Preps_Insert_Input>;
  on_conflict?: InputMaybe<Recipe_Preps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_Preps_OneArgs = {
  object: Recipe_Preps_Insert_Input;
  on_conflict?: InputMaybe<Recipe_Preps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_OneArgs = {
  object: Recipe_Insert_Input;
  on_conflict?: InputMaybe<Recipe_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SeasonArgs = {
  objects: Array<Season_Insert_Input>;
  on_conflict?: InputMaybe<Season_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Season_OneArgs = {
  object: Season_Insert_Input;
  on_conflict?: InputMaybe<Season_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TechnicArgs = {
  objects: Array<Technic_Insert_Input>;
  on_conflict?: InputMaybe<Technic_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TechnicTipArgs = {
  objects: Array<TechnicTip_Insert_Input>;
  on_conflict?: InputMaybe<TechnicTip_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TechnicTip_OneArgs = {
  object: TechnicTip_Insert_Input;
  on_conflict?: InputMaybe<TechnicTip_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Technic_OneArgs = {
  object: Technic_Insert_Input;
  on_conflict?: InputMaybe<Technic_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ToolArgs = {
  objects: Array<Tool_Insert_Input>;
  on_conflict?: InputMaybe<Tool_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tool_OneArgs = {
  object: Tool_Insert_Input;
  on_conflict?: InputMaybe<Tool_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_CookingStyleArgs = {
  _set?: InputMaybe<CookingStyle_Set_Input>;
  where: CookingStyle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CookingStyle_By_PkArgs = {
  _set?: InputMaybe<CookingStyle_Set_Input>;
  pk_columns: CookingStyle_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CookingStyle_ManyArgs = {
  updates: Array<CookingStyle_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DishArgs = {
  _set?: InputMaybe<Dish_Set_Input>;
  where: Dish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DishCategoryArgs = {
  _set?: InputMaybe<DishCategory_Set_Input>;
  where: DishCategory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DishCategory_By_PkArgs = {
  _set?: InputMaybe<DishCategory_Set_Input>;
  pk_columns: DishCategory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DishCategory_ManyArgs = {
  updates: Array<DishCategory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dish_By_PkArgs = {
  _set?: InputMaybe<Dish_Set_Input>;
  pk_columns: Dish_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dish_ManyArgs = {
  updates: Array<Dish_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_IngredientArgs = {
  _inc?: InputMaybe<Ingredient_Inc_Input>;
  _set?: InputMaybe<Ingredient_Set_Input>;
  where: Ingredient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_CookingStylesArgs = {
  _set?: InputMaybe<Ingredient_CookingStyles_Set_Input>;
  where: Ingredient_CookingStyles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_CookingStyles_By_PkArgs = {
  _set?: InputMaybe<Ingredient_CookingStyles_Set_Input>;
  pk_columns: Ingredient_CookingStyles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_CookingStyles_ManyArgs = {
  updates: Array<Ingredient_CookingStyles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_SeasonsArgs = {
  _set?: InputMaybe<Ingredient_Seasons_Set_Input>;
  where: Ingredient_Seasons_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_Seasons_By_PkArgs = {
  _set?: InputMaybe<Ingredient_Seasons_Set_Input>;
  pk_columns: Ingredient_Seasons_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_Seasons_ManyArgs = {
  updates: Array<Ingredient_Seasons_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_By_PkArgs = {
  _inc?: InputMaybe<Ingredient_Inc_Input>;
  _set?: InputMaybe<Ingredient_Set_Input>;
  pk_columns: Ingredient_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ingredient_ManyArgs = {
  updates: Array<Ingredient_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MealArgs = {
  _set?: InputMaybe<Meal_Set_Input>;
  where: Meal_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MealDishArgs = {
  _set?: InputMaybe<MealDish_Set_Input>;
  where: MealDish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MealDish_By_PkArgs = {
  _set?: InputMaybe<MealDish_Set_Input>;
  pk_columns: MealDish_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MealDish_ManyArgs = {
  updates: Array<MealDish_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Meal_By_PkArgs = {
  _set?: InputMaybe<Meal_Set_Input>;
  pk_columns: Meal_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Meal_ManyArgs = {
  updates: Array<Meal_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PrepArgs = {
  _inc?: InputMaybe<Prep_Inc_Input>;
  _set?: InputMaybe<Prep_Set_Input>;
  where: Prep_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PrepStepArgs = {
  _inc?: InputMaybe<PrepStep_Inc_Input>;
  _set?: InputMaybe<PrepStep_Set_Input>;
  where: PrepStep_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PrepStep_By_PkArgs = {
  _inc?: InputMaybe<PrepStep_Inc_Input>;
  _set?: InputMaybe<PrepStep_Set_Input>;
  pk_columns: PrepStep_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_PrepStep_ManyArgs = {
  updates: Array<PrepStep_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_IngredientsArgs = {
  _inc?: InputMaybe<Prep_Ingredients_Inc_Input>;
  _set?: InputMaybe<Prep_Ingredients_Set_Input>;
  where: Prep_Ingredients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Ingredients_By_PkArgs = {
  _inc?: InputMaybe<Prep_Ingredients_Inc_Input>;
  _set?: InputMaybe<Prep_Ingredients_Set_Input>;
  pk_columns: Prep_Ingredients_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Ingredients_ManyArgs = {
  updates: Array<Prep_Ingredients_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_PrepsArgs = {
  _inc?: InputMaybe<Prep_Preps_Inc_Input>;
  _set?: InputMaybe<Prep_Preps_Set_Input>;
  where: Prep_Preps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Preps_By_PkArgs = {
  _inc?: InputMaybe<Prep_Preps_Inc_Input>;
  _set?: InputMaybe<Prep_Preps_Set_Input>;
  pk_columns: Prep_Preps_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Preps_ManyArgs = {
  updates: Array<Prep_Preps_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_TechnicsArgs = {
  _set?: InputMaybe<Prep_Technics_Set_Input>;
  where: Prep_Technics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Technics_By_PkArgs = {
  _set?: InputMaybe<Prep_Technics_Set_Input>;
  pk_columns: Prep_Technics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Technics_ManyArgs = {
  updates: Array<Prep_Technics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_ToolsArgs = {
  _set?: InputMaybe<Prep_Tools_Set_Input>;
  where: Prep_Tools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Tools_By_PkArgs = {
  _set?: InputMaybe<Prep_Tools_Set_Input>;
  pk_columns: Prep_Tools_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_Tools_ManyArgs = {
  updates: Array<Prep_Tools_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_By_PkArgs = {
  _inc?: InputMaybe<Prep_Inc_Input>;
  _set?: InputMaybe<Prep_Set_Input>;
  pk_columns: Prep_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prep_ManyArgs = {
  updates: Array<Prep_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RecipeArgs = {
  _set?: InputMaybe<Recipe_Set_Input>;
  where: Recipe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_PrepsArgs = {
  _inc?: InputMaybe<Recipe_Preps_Inc_Input>;
  _set?: InputMaybe<Recipe_Preps_Set_Input>;
  where: Recipe_Preps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Preps_By_PkArgs = {
  _inc?: InputMaybe<Recipe_Preps_Inc_Input>;
  _set?: InputMaybe<Recipe_Preps_Set_Input>;
  pk_columns: Recipe_Preps_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Preps_ManyArgs = {
  updates: Array<Recipe_Preps_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_By_PkArgs = {
  _set?: InputMaybe<Recipe_Set_Input>;
  pk_columns: Recipe_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_ManyArgs = {
  updates: Array<Recipe_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SeasonArgs = {
  _set?: InputMaybe<Season_Set_Input>;
  where: Season_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Season_By_PkArgs = {
  _set?: InputMaybe<Season_Set_Input>;
  pk_columns: Season_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Season_ManyArgs = {
  updates: Array<Season_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TechnicArgs = {
  _set?: InputMaybe<Technic_Set_Input>;
  where: Technic_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TechnicTipArgs = {
  _set?: InputMaybe<TechnicTip_Set_Input>;
  where: TechnicTip_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TechnicTip_By_PkArgs = {
  _set?: InputMaybe<TechnicTip_Set_Input>;
  pk_columns: TechnicTip_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TechnicTip_ManyArgs = {
  updates: Array<TechnicTip_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Technic_By_PkArgs = {
  _set?: InputMaybe<Technic_Set_Input>;
  pk_columns: Technic_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Technic_ManyArgs = {
  updates: Array<Technic_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ToolArgs = {
  _inc?: InputMaybe<Tool_Inc_Input>;
  _set?: InputMaybe<Tool_Set_Input>;
  where: Tool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tool_By_PkArgs = {
  _inc?: InputMaybe<Tool_Inc_Input>;
  _set?: InputMaybe<Tool_Set_Input>;
  pk_columns: Tool_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tool_ManyArgs = {
  updates: Array<Tool_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "CookingStyle" */
  CookingStyle: Array<CookingStyle>;
  /** fetch aggregated fields from the table: "CookingStyle" */
  CookingStyle_aggregate: CookingStyle_Aggregate;
  /** fetch data from the table: "CookingStyle" using primary key columns */
  CookingStyle_by_pk?: Maybe<CookingStyle>;
  /** fetch data from the table: "Dish" */
  Dish: Array<Dish>;
  /** fetch data from the table: "DishCategory" */
  DishCategory: Array<DishCategory>;
  /** fetch aggregated fields from the table: "DishCategory" */
  DishCategory_aggregate: DishCategory_Aggregate;
  /** fetch data from the table: "DishCategory" using primary key columns */
  DishCategory_by_pk?: Maybe<DishCategory>;
  /** fetch aggregated fields from the table: "Dish" */
  Dish_aggregate: Dish_Aggregate;
  /** fetch data from the table: "Dish" using primary key columns */
  Dish_by_pk?: Maybe<Dish>;
  /** fetch data from the table: "Ingredient" */
  Ingredient: Array<Ingredient>;
  /** An array relationship */
  Ingredient_CookingStyles: Array<Ingredient_CookingStyles>;
  /** An aggregate relationship */
  Ingredient_CookingStyles_aggregate: Ingredient_CookingStyles_Aggregate;
  /** fetch data from the table: "Ingredient_CookingStyles" using primary key columns */
  Ingredient_CookingStyles_by_pk?: Maybe<Ingredient_CookingStyles>;
  /** An array relationship */
  Ingredient_Seasons: Array<Ingredient_Seasons>;
  /** An aggregate relationship */
  Ingredient_Seasons_aggregate: Ingredient_Seasons_Aggregate;
  /** fetch data from the table: "Ingredient_Seasons" using primary key columns */
  Ingredient_Seasons_by_pk?: Maybe<Ingredient_Seasons>;
  /** fetch aggregated fields from the table: "Ingredient" */
  Ingredient_aggregate: Ingredient_Aggregate;
  /** fetch data from the table: "Ingredient" using primary key columns */
  Ingredient_by_pk?: Maybe<Ingredient>;
  /** fetch data from the table: "Meal" */
  Meal: Array<Meal>;
  /** fetch data from the table: "MealDish" */
  MealDish: Array<MealDish>;
  /** fetch aggregated fields from the table: "MealDish" */
  MealDish_aggregate: MealDish_Aggregate;
  /** fetch data from the table: "MealDish" using primary key columns */
  MealDish_by_pk?: Maybe<MealDish>;
  /** fetch aggregated fields from the table: "Meal" */
  Meal_aggregate: Meal_Aggregate;
  /** fetch data from the table: "Meal" using primary key columns */
  Meal_by_pk?: Maybe<Meal>;
  /** fetch data from the table: "Prep" */
  Prep: Array<Prep>;
  /** fetch data from the table: "PrepStep" */
  PrepStep: Array<PrepStep>;
  /** fetch aggregated fields from the table: "PrepStep" */
  PrepStep_aggregate: PrepStep_Aggregate;
  /** fetch data from the table: "PrepStep" using primary key columns */
  PrepStep_by_pk?: Maybe<PrepStep>;
  /** An array relationship */
  Prep_Ingredients: Array<Prep_Ingredients>;
  /** An aggregate relationship */
  Prep_Ingredients_aggregate: Prep_Ingredients_Aggregate;
  /** fetch data from the table: "Prep_Ingredients" using primary key columns */
  Prep_Ingredients_by_pk?: Maybe<Prep_Ingredients>;
  /** fetch data from the table: "Prep_Preps" */
  Prep_Preps: Array<Prep_Preps>;
  /** fetch aggregated fields from the table: "Prep_Preps" */
  Prep_Preps_aggregate: Prep_Preps_Aggregate;
  /** fetch data from the table: "Prep_Preps" using primary key columns */
  Prep_Preps_by_pk?: Maybe<Prep_Preps>;
  /** fetch data from the table: "Prep_Technics" */
  Prep_Technics: Array<Prep_Technics>;
  /** fetch aggregated fields from the table: "Prep_Technics" */
  Prep_Technics_aggregate: Prep_Technics_Aggregate;
  /** fetch data from the table: "Prep_Technics" using primary key columns */
  Prep_Technics_by_pk?: Maybe<Prep_Technics>;
  /** An array relationship */
  Prep_Tools: Array<Prep_Tools>;
  /** An aggregate relationship */
  Prep_Tools_aggregate: Prep_Tools_Aggregate;
  /** fetch data from the table: "Prep_Tools" using primary key columns */
  Prep_Tools_by_pk?: Maybe<Prep_Tools>;
  /** fetch aggregated fields from the table: "Prep" */
  Prep_aggregate: Prep_Aggregate;
  /** fetch data from the table: "Prep" using primary key columns */
  Prep_by_pk?: Maybe<Prep>;
  /** fetch data from the table: "Recipe" */
  Recipe: Array<Recipe>;
  /** fetch data from the table: "Recipe_Preps" */
  Recipe_Preps: Array<Recipe_Preps>;
  /** fetch aggregated fields from the table: "Recipe_Preps" */
  Recipe_Preps_aggregate: Recipe_Preps_Aggregate;
  /** fetch data from the table: "Recipe_Preps" using primary key columns */
  Recipe_Preps_by_pk?: Maybe<Recipe_Preps>;
  /** fetch aggregated fields from the table: "Recipe" */
  Recipe_aggregate: Recipe_Aggregate;
  /** fetch data from the table: "Recipe" using primary key columns */
  Recipe_by_pk?: Maybe<Recipe>;
  /** fetch data from the table: "Season" */
  Season: Array<Season>;
  /** fetch aggregated fields from the table: "Season" */
  Season_aggregate: Season_Aggregate;
  /** fetch data from the table: "Season" using primary key columns */
  Season_by_pk?: Maybe<Season>;
  /** fetch data from the table: "Technic" */
  Technic: Array<Technic>;
  /** fetch data from the table: "TechnicTip" */
  TechnicTip: Array<TechnicTip>;
  /** fetch aggregated fields from the table: "TechnicTip" */
  TechnicTip_aggregate: TechnicTip_Aggregate;
  /** fetch data from the table: "TechnicTip" using primary key columns */
  TechnicTip_by_pk?: Maybe<TechnicTip>;
  /** fetch aggregated fields from the table: "Technic" */
  Technic_aggregate: Technic_Aggregate;
  /** fetch data from the table: "Technic" using primary key columns */
  Technic_by_pk?: Maybe<Technic>;
  /** fetch data from the table: "Tool" */
  Tool: Array<Tool>;
  /** fetch aggregated fields from the table: "Tool" */
  Tool_aggregate: Tool_Aggregate;
  /** fetch data from the table: "Tool" using primary key columns */
  Tool_by_pk?: Maybe<Tool>;
  /** execute function "aot_preps" which returns "Prep" */
  aot_preps: Array<Prep>;
  /** execute function "aot_preps" and query aggregates on result of table type "Prep" */
  aot_preps_aggregate: Prep_Aggregate;
};

export type Query_RootCookingStyleArgs = {
  distinct_on?: InputMaybe<Array<CookingStyle_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CookingStyle_Order_By>>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

export type Query_RootCookingStyle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CookingStyle_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CookingStyle_Order_By>>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

export type Query_RootCookingStyle_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootDishArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

export type Query_RootDishCategoryArgs = {
  distinct_on?: InputMaybe<Array<DishCategory_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DishCategory_Order_By>>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

export type Query_RootDishCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DishCategory_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DishCategory_Order_By>>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

export type Query_RootDishCategory_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootDish_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

export type Query_RootDish_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootIngredientArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Order_By>>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

export type Query_RootIngredient_CookingStylesArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

export type Query_RootIngredient_CookingStyles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

export type Query_RootIngredient_CookingStyles_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

export type Query_RootIngredient_SeasonsArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

export type Query_RootIngredient_Seasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

export type Query_RootIngredient_Seasons_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

export type Query_RootIngredient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Order_By>>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

export type Query_RootIngredient_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMealArgs = {
  distinct_on?: InputMaybe<Array<Meal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Meal_Order_By>>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

export type Query_RootMealDishArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

export type Query_RootMealDish_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

export type Query_RootMealDish_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Meal_Order_By>>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

export type Query_RootMeal_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPrepArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Query_RootPrepStepArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

export type Query_RootPrepStep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

export type Query_RootPrepStep_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPrep_IngredientsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

export type Query_RootPrep_Ingredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

export type Query_RootPrep_Ingredients_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

export type Query_RootPrep_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

export type Query_RootPrep_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

export type Query_RootPrep_Preps_By_PkArgs = {
  input_prep_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

export type Query_RootPrep_TechnicsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

export type Query_RootPrep_Technics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

export type Query_RootPrep_Technics_By_PkArgs = {
  prep_id: Scalars["uuid"];
  technic_id: Scalars["uuid"];
};

export type Query_RootPrep_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

export type Query_RootPrep_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

export type Query_RootPrep_Tools_By_PkArgs = {
  prep_id: Scalars["uuid"];
  tool_id: Scalars["uuid"];
};

export type Query_RootPrep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Query_RootPrep_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRecipeArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Order_By>>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

export type Query_RootRecipe_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Preps_Order_By>>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

export type Query_RootRecipe_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Preps_Order_By>>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

export type Query_RootRecipe_Preps_By_PkArgs = {
  recipe_id: Scalars["uuid"];
};

export type Query_RootRecipe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Order_By>>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

export type Query_RootRecipe_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSeasonArgs = {
  distinct_on?: InputMaybe<Array<Season_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Season_Order_By>>;
  where?: InputMaybe<Season_Bool_Exp>;
};

export type Query_RootSeason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Season_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Season_Order_By>>;
  where?: InputMaybe<Season_Bool_Exp>;
};

export type Query_RootSeason_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTechnicArgs = {
  distinct_on?: InputMaybe<Array<Technic_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Technic_Order_By>>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

export type Query_RootTechnicTipArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

export type Query_RootTechnicTip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

export type Query_RootTechnicTip_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTechnic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Technic_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Technic_Order_By>>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

export type Query_RootTechnic_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootToolArgs = {
  distinct_on?: InputMaybe<Array<Tool_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tool_Order_By>>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

export type Query_RootTool_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tool_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tool_Order_By>>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

export type Query_RootTool_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootAot_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Query_RootAot_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "CookingStyle" */
  CookingStyle: Array<CookingStyle>;
  /** fetch aggregated fields from the table: "CookingStyle" */
  CookingStyle_aggregate: CookingStyle_Aggregate;
  /** fetch data from the table: "CookingStyle" using primary key columns */
  CookingStyle_by_pk?: Maybe<CookingStyle>;
  /** fetch data from the table in a streaming manner: "CookingStyle" */
  CookingStyle_stream: Array<CookingStyle>;
  /** fetch data from the table: "Dish" */
  Dish: Array<Dish>;
  /** fetch data from the table: "DishCategory" */
  DishCategory: Array<DishCategory>;
  /** fetch aggregated fields from the table: "DishCategory" */
  DishCategory_aggregate: DishCategory_Aggregate;
  /** fetch data from the table: "DishCategory" using primary key columns */
  DishCategory_by_pk?: Maybe<DishCategory>;
  /** fetch data from the table in a streaming manner: "DishCategory" */
  DishCategory_stream: Array<DishCategory>;
  /** fetch aggregated fields from the table: "Dish" */
  Dish_aggregate: Dish_Aggregate;
  /** fetch data from the table: "Dish" using primary key columns */
  Dish_by_pk?: Maybe<Dish>;
  /** fetch data from the table in a streaming manner: "Dish" */
  Dish_stream: Array<Dish>;
  /** fetch data from the table: "Ingredient" */
  Ingredient: Array<Ingredient>;
  /** An array relationship */
  Ingredient_CookingStyles: Array<Ingredient_CookingStyles>;
  /** An aggregate relationship */
  Ingredient_CookingStyles_aggregate: Ingredient_CookingStyles_Aggregate;
  /** fetch data from the table: "Ingredient_CookingStyles" using primary key columns */
  Ingredient_CookingStyles_by_pk?: Maybe<Ingredient_CookingStyles>;
  /** fetch data from the table in a streaming manner: "Ingredient_CookingStyles" */
  Ingredient_CookingStyles_stream: Array<Ingredient_CookingStyles>;
  /** An array relationship */
  Ingredient_Seasons: Array<Ingredient_Seasons>;
  /** An aggregate relationship */
  Ingredient_Seasons_aggregate: Ingredient_Seasons_Aggregate;
  /** fetch data from the table: "Ingredient_Seasons" using primary key columns */
  Ingredient_Seasons_by_pk?: Maybe<Ingredient_Seasons>;
  /** fetch data from the table in a streaming manner: "Ingredient_Seasons" */
  Ingredient_Seasons_stream: Array<Ingredient_Seasons>;
  /** fetch aggregated fields from the table: "Ingredient" */
  Ingredient_aggregate: Ingredient_Aggregate;
  /** fetch data from the table: "Ingredient" using primary key columns */
  Ingredient_by_pk?: Maybe<Ingredient>;
  /** fetch data from the table in a streaming manner: "Ingredient" */
  Ingredient_stream: Array<Ingredient>;
  /** fetch data from the table: "Meal" */
  Meal: Array<Meal>;
  /** fetch data from the table: "MealDish" */
  MealDish: Array<MealDish>;
  /** fetch aggregated fields from the table: "MealDish" */
  MealDish_aggregate: MealDish_Aggregate;
  /** fetch data from the table: "MealDish" using primary key columns */
  MealDish_by_pk?: Maybe<MealDish>;
  /** fetch data from the table in a streaming manner: "MealDish" */
  MealDish_stream: Array<MealDish>;
  /** fetch aggregated fields from the table: "Meal" */
  Meal_aggregate: Meal_Aggregate;
  /** fetch data from the table: "Meal" using primary key columns */
  Meal_by_pk?: Maybe<Meal>;
  /** fetch data from the table in a streaming manner: "Meal" */
  Meal_stream: Array<Meal>;
  /** fetch data from the table: "Prep" */
  Prep: Array<Prep>;
  /** fetch data from the table: "PrepStep" */
  PrepStep: Array<PrepStep>;
  /** fetch aggregated fields from the table: "PrepStep" */
  PrepStep_aggregate: PrepStep_Aggregate;
  /** fetch data from the table: "PrepStep" using primary key columns */
  PrepStep_by_pk?: Maybe<PrepStep>;
  /** fetch data from the table in a streaming manner: "PrepStep" */
  PrepStep_stream: Array<PrepStep>;
  /** An array relationship */
  Prep_Ingredients: Array<Prep_Ingredients>;
  /** An aggregate relationship */
  Prep_Ingredients_aggregate: Prep_Ingredients_Aggregate;
  /** fetch data from the table: "Prep_Ingredients" using primary key columns */
  Prep_Ingredients_by_pk?: Maybe<Prep_Ingredients>;
  /** fetch data from the table in a streaming manner: "Prep_Ingredients" */
  Prep_Ingredients_stream: Array<Prep_Ingredients>;
  /** fetch data from the table: "Prep_Preps" */
  Prep_Preps: Array<Prep_Preps>;
  /** fetch aggregated fields from the table: "Prep_Preps" */
  Prep_Preps_aggregate: Prep_Preps_Aggregate;
  /** fetch data from the table: "Prep_Preps" using primary key columns */
  Prep_Preps_by_pk?: Maybe<Prep_Preps>;
  /** fetch data from the table in a streaming manner: "Prep_Preps" */
  Prep_Preps_stream: Array<Prep_Preps>;
  /** fetch data from the table: "Prep_Technics" */
  Prep_Technics: Array<Prep_Technics>;
  /** fetch aggregated fields from the table: "Prep_Technics" */
  Prep_Technics_aggregate: Prep_Technics_Aggregate;
  /** fetch data from the table: "Prep_Technics" using primary key columns */
  Prep_Technics_by_pk?: Maybe<Prep_Technics>;
  /** fetch data from the table in a streaming manner: "Prep_Technics" */
  Prep_Technics_stream: Array<Prep_Technics>;
  /** An array relationship */
  Prep_Tools: Array<Prep_Tools>;
  /** An aggregate relationship */
  Prep_Tools_aggregate: Prep_Tools_Aggregate;
  /** fetch data from the table: "Prep_Tools" using primary key columns */
  Prep_Tools_by_pk?: Maybe<Prep_Tools>;
  /** fetch data from the table in a streaming manner: "Prep_Tools" */
  Prep_Tools_stream: Array<Prep_Tools>;
  /** fetch aggregated fields from the table: "Prep" */
  Prep_aggregate: Prep_Aggregate;
  /** fetch data from the table: "Prep" using primary key columns */
  Prep_by_pk?: Maybe<Prep>;
  /** fetch data from the table in a streaming manner: "Prep" */
  Prep_stream: Array<Prep>;
  /** fetch data from the table: "Recipe" */
  Recipe: Array<Recipe>;
  /** fetch data from the table: "Recipe_Preps" */
  Recipe_Preps: Array<Recipe_Preps>;
  /** fetch aggregated fields from the table: "Recipe_Preps" */
  Recipe_Preps_aggregate: Recipe_Preps_Aggregate;
  /** fetch data from the table: "Recipe_Preps" using primary key columns */
  Recipe_Preps_by_pk?: Maybe<Recipe_Preps>;
  /** fetch data from the table in a streaming manner: "Recipe_Preps" */
  Recipe_Preps_stream: Array<Recipe_Preps>;
  /** fetch aggregated fields from the table: "Recipe" */
  Recipe_aggregate: Recipe_Aggregate;
  /** fetch data from the table: "Recipe" using primary key columns */
  Recipe_by_pk?: Maybe<Recipe>;
  /** fetch data from the table in a streaming manner: "Recipe" */
  Recipe_stream: Array<Recipe>;
  /** fetch data from the table: "Season" */
  Season: Array<Season>;
  /** fetch aggregated fields from the table: "Season" */
  Season_aggregate: Season_Aggregate;
  /** fetch data from the table: "Season" using primary key columns */
  Season_by_pk?: Maybe<Season>;
  /** fetch data from the table in a streaming manner: "Season" */
  Season_stream: Array<Season>;
  /** fetch data from the table: "Technic" */
  Technic: Array<Technic>;
  /** fetch data from the table: "TechnicTip" */
  TechnicTip: Array<TechnicTip>;
  /** fetch aggregated fields from the table: "TechnicTip" */
  TechnicTip_aggregate: TechnicTip_Aggregate;
  /** fetch data from the table: "TechnicTip" using primary key columns */
  TechnicTip_by_pk?: Maybe<TechnicTip>;
  /** fetch data from the table in a streaming manner: "TechnicTip" */
  TechnicTip_stream: Array<TechnicTip>;
  /** fetch aggregated fields from the table: "Technic" */
  Technic_aggregate: Technic_Aggregate;
  /** fetch data from the table: "Technic" using primary key columns */
  Technic_by_pk?: Maybe<Technic>;
  /** fetch data from the table in a streaming manner: "Technic" */
  Technic_stream: Array<Technic>;
  /** fetch data from the table: "Tool" */
  Tool: Array<Tool>;
  /** fetch aggregated fields from the table: "Tool" */
  Tool_aggregate: Tool_Aggregate;
  /** fetch data from the table: "Tool" using primary key columns */
  Tool_by_pk?: Maybe<Tool>;
  /** fetch data from the table in a streaming manner: "Tool" */
  Tool_stream: Array<Tool>;
  /** execute function "aot_preps" which returns "Prep" */
  aot_preps: Array<Prep>;
  /** execute function "aot_preps" and query aggregates on result of table type "Prep" */
  aot_preps_aggregate: Prep_Aggregate;
};

export type Subscription_RootCookingStyleArgs = {
  distinct_on?: InputMaybe<Array<CookingStyle_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CookingStyle_Order_By>>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

export type Subscription_RootCookingStyle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CookingStyle_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<CookingStyle_Order_By>>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

export type Subscription_RootCookingStyle_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCookingStyle_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<CookingStyle_Stream_Cursor_Input>>;
  where?: InputMaybe<CookingStyle_Bool_Exp>;
};

export type Subscription_RootDishArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

export type Subscription_RootDishCategoryArgs = {
  distinct_on?: InputMaybe<Array<DishCategory_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DishCategory_Order_By>>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

export type Subscription_RootDishCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DishCategory_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<DishCategory_Order_By>>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

export type Subscription_RootDishCategory_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDishCategory_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<DishCategory_Stream_Cursor_Input>>;
  where?: InputMaybe<DishCategory_Bool_Exp>;
};

export type Subscription_RootDish_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Dish_Order_By>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

export type Subscription_RootDish_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDish_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Dish_Stream_Cursor_Input>>;
  where?: InputMaybe<Dish_Bool_Exp>;
};

export type Subscription_RootIngredientArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Order_By>>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

export type Subscription_RootIngredient_CookingStylesArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

export type Subscription_RootIngredient_CookingStyles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_CookingStyles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_CookingStyles_Order_By>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

export type Subscription_RootIngredient_CookingStyles_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

export type Subscription_RootIngredient_CookingStyles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Ingredient_CookingStyles_Stream_Cursor_Input>>;
  where?: InputMaybe<Ingredient_CookingStyles_Bool_Exp>;
};

export type Subscription_RootIngredient_SeasonsArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

export type Subscription_RootIngredient_Seasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Seasons_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Seasons_Order_By>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

export type Subscription_RootIngredient_Seasons_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
};

export type Subscription_RootIngredient_Seasons_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Ingredient_Seasons_Stream_Cursor_Input>>;
  where?: InputMaybe<Ingredient_Seasons_Bool_Exp>;
};

export type Subscription_RootIngredient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Ingredient_Order_By>>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

export type Subscription_RootIngredient_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootIngredient_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Ingredient_Stream_Cursor_Input>>;
  where?: InputMaybe<Ingredient_Bool_Exp>;
};

export type Subscription_RootMealArgs = {
  distinct_on?: InputMaybe<Array<Meal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Meal_Order_By>>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

export type Subscription_RootMealDishArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

export type Subscription_RootMealDish_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MealDish_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<MealDish_Order_By>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

export type Subscription_RootMealDish_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMealDish_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<MealDish_Stream_Cursor_Input>>;
  where?: InputMaybe<MealDish_Bool_Exp>;
};

export type Subscription_RootMeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Meal_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Meal_Order_By>>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

export type Subscription_RootMeal_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMeal_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Meal_Stream_Cursor_Input>>;
  where?: InputMaybe<Meal_Bool_Exp>;
};

export type Subscription_RootPrepArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Subscription_RootPrepStepArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

export type Subscription_RootPrepStep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PrepStep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<PrepStep_Order_By>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

export type Subscription_RootPrepStep_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPrepStep_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<PrepStep_Stream_Cursor_Input>>;
  where?: InputMaybe<PrepStep_Bool_Exp>;
};

export type Subscription_RootPrep_IngredientsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

export type Subscription_RootPrep_Ingredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Ingredients_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Ingredients_Order_By>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

export type Subscription_RootPrep_Ingredients_By_PkArgs = {
  ingredient_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

export type Subscription_RootPrep_Ingredients_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Prep_Ingredients_Stream_Cursor_Input>>;
  where?: InputMaybe<Prep_Ingredients_Bool_Exp>;
};

export type Subscription_RootPrep_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

export type Subscription_RootPrep_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Preps_Order_By>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

export type Subscription_RootPrep_Preps_By_PkArgs = {
  input_prep_id: Scalars["uuid"];
  prep_id: Scalars["uuid"];
};

export type Subscription_RootPrep_Preps_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Prep_Preps_Stream_Cursor_Input>>;
  where?: InputMaybe<Prep_Preps_Bool_Exp>;
};

export type Subscription_RootPrep_TechnicsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

export type Subscription_RootPrep_Technics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Technics_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Technics_Order_By>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

export type Subscription_RootPrep_Technics_By_PkArgs = {
  prep_id: Scalars["uuid"];
  technic_id: Scalars["uuid"];
};

export type Subscription_RootPrep_Technics_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Prep_Technics_Stream_Cursor_Input>>;
  where?: InputMaybe<Prep_Technics_Bool_Exp>;
};

export type Subscription_RootPrep_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

export type Subscription_RootPrep_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Tools_Order_By>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

export type Subscription_RootPrep_Tools_By_PkArgs = {
  prep_id: Scalars["uuid"];
  tool_id: Scalars["uuid"];
};

export type Subscription_RootPrep_Tools_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Prep_Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Prep_Tools_Bool_Exp>;
};

export type Subscription_RootPrep_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Subscription_RootPrep_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPrep_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Prep_Stream_Cursor_Input>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Subscription_RootRecipeArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Order_By>>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

export type Subscription_RootRecipe_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Preps_Order_By>>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

export type Subscription_RootRecipe_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Preps_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Preps_Order_By>>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

export type Subscription_RootRecipe_Preps_By_PkArgs = {
  recipe_id: Scalars["uuid"];
};

export type Subscription_RootRecipe_Preps_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Recipe_Preps_Stream_Cursor_Input>>;
  where?: InputMaybe<Recipe_Preps_Bool_Exp>;
};

export type Subscription_RootRecipe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recipe_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Recipe_Order_By>>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

export type Subscription_RootRecipe_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRecipe_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Recipe_Stream_Cursor_Input>>;
  where?: InputMaybe<Recipe_Bool_Exp>;
};

export type Subscription_RootSeasonArgs = {
  distinct_on?: InputMaybe<Array<Season_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Season_Order_By>>;
  where?: InputMaybe<Season_Bool_Exp>;
};

export type Subscription_RootSeason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Season_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Season_Order_By>>;
  where?: InputMaybe<Season_Bool_Exp>;
};

export type Subscription_RootSeason_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSeason_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Season_Stream_Cursor_Input>>;
  where?: InputMaybe<Season_Bool_Exp>;
};

export type Subscription_RootTechnicArgs = {
  distinct_on?: InputMaybe<Array<Technic_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Technic_Order_By>>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

export type Subscription_RootTechnicTipArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

export type Subscription_RootTechnicTip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TechnicTip_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<TechnicTip_Order_By>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

export type Subscription_RootTechnicTip_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTechnicTip_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<TechnicTip_Stream_Cursor_Input>>;
  where?: InputMaybe<TechnicTip_Bool_Exp>;
};

export type Subscription_RootTechnic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Technic_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Technic_Order_By>>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

export type Subscription_RootTechnic_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTechnic_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Technic_Stream_Cursor_Input>>;
  where?: InputMaybe<Technic_Bool_Exp>;
};

export type Subscription_RootToolArgs = {
  distinct_on?: InputMaybe<Array<Tool_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tool_Order_By>>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

export type Subscription_RootTool_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tool_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Tool_Order_By>>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

export type Subscription_RootTool_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTool_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Tool_Stream_Cursor_Input>>;
  where?: InputMaybe<Tool_Bool_Exp>;
};

export type Subscription_RootAot_PrepsArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

export type Subscription_RootAot_Preps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prep_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Prep_Order_By>>;
  where?: InputMaybe<Prep_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamp"]>;
  _gt?: InputMaybe<Scalars["timestamp"]>;
  _gte?: InputMaybe<Scalars["timestamp"]>;
  _in?: InputMaybe<Array<Scalars["timestamp"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamp"]>;
  _lte?: InputMaybe<Scalars["timestamp"]>;
  _neq?: InputMaybe<Scalars["timestamp"]>;
  _nin?: InputMaybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

export type AddDishMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<Scalars["String"]>;
  category_id?: InputMaybe<Scalars["uuid"]>;
}>;

export type AddDishMutation = {
  __typename?: "mutation_root";
  insert_Dish_one?: { __typename?: "Dish"; id: any } | null;
};

export type UpdateDishMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<Scalars["String"]>;
  category_id?: InputMaybe<Scalars["uuid"]>;
}>;

export type UpdateDishMutation = {
  __typename?: "mutation_root";
  update_Dish?: {
    __typename?: "Dish_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetDishQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetDishQuery = {
  __typename?: "query_root";
  Dish_by_pk?: {
    __typename?: "Dish";
    id: any;
    name?: string | null;
    kind?: string | null;
    DishCategory?: {
      __typename?: "DishCategory";
      id: any;
      name: string;
    } | null;
    Preps: Array<{ __typename?: "Prep"; id: any; name: string }>;
  } | null;
};

export type GetDishCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDishCategoriesQuery = {
  __typename?: "query_root";
  DishCategory: Array<{ __typename?: "DishCategory"; id: any; name: string }>;
};

export type GetDishesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDishesQuery = {
  __typename?: "query_root";
  Dish: Array<{
    __typename?: "Dish";
    id: any;
    name?: string | null;
    updated_at: any;
    DishCategory?: {
      __typename?: "DishCategory";
      id: any;
      name: string;
    } | null;
    Preps_aggregate: {
      __typename?: "Prep_aggregate";
      aggregate?: {
        __typename?: "Prep_aggregate_fields";
        count: number;
      } | null;
    };
  }>;
};

export type SearchDishesQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchDishesQuery = {
  __typename?: "query_root";
  Dish: Array<{ __typename?: "Dish"; id: any; name?: string | null }>;
};

export type AddIngredientMutationVariables = Exact<{
  id: Scalars["uuid"];
  name: Scalars["String"];
  type: Scalars["String"];
  consistency: Scalars["String"];
  measure_unit: Scalars["String"];
  can_split: Scalars["Boolean"];
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
}>;

export type AddIngredientMutation = {
  __typename?: "mutation_root";
  insert_Ingredient_one?: {
    __typename?: "Ingredient";
    id: any;
    name: string;
  } | null;
};

export type UpdateIngredientMutationVariables = Exact<{
  id: Scalars["uuid"];
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  consistency?: InputMaybe<Scalars["String"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  can_split?: InputMaybe<Scalars["Boolean"]>;
  avg_item_quantity?: InputMaybe<Scalars["Int"]>;
  ambient_temp_time?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateIngredientMutation = {
  __typename?: "mutation_root";
  update_Ingredient_by_pk?: { __typename?: "Ingredient"; id: any } | null;
};

export type GetIngredientQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetIngredientQuery = {
  __typename?: "query_root";
  Ingredient_by_pk?: {
    __typename?: "Ingredient";
    id: any;
    name: string;
    type: string;
    measure_unit: string;
    consistency: string;
    can_split: boolean;
    avg_item_quantity?: number | null;
    ambient_temp_time?: number | null;
  } | null;
};

export type GetIngredientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetIngredientsQuery = {
  __typename?: "query_root";
  Ingredient: Array<{
    __typename?: "Ingredient";
    id: any;
    name: string;
    type: string;
    measure_unit: string;
    avg_item_quantity?: number | null;
  }>;
};

export type SearchIngredientQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchIngredientQuery = {
  __typename?: "query_root";
  Ingredient: Array<{ __typename?: "Ingredient"; id: any; name: string }>;
};

export type AddPrepMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  rest_conditions?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  main_prep_id?: InputMaybe<Scalars["uuid"]>;
}>;

export type AddPrepMutation = {
  __typename?: "mutation_root";
  insert_Prep_one?: { __typename?: "Prep"; id: any; name: string } | null;
};

export type AddPrepIngredientsMutationVariables = Exact<{
  ingredients:
    | Array<Prep_Ingredients_Insert_Input>
    | Prep_Ingredients_Insert_Input;
}>;

export type AddPrepIngredientsMutation = {
  __typename?: "mutation_root";
  insert_Prep_Ingredients?: {
    __typename?: "Prep_Ingredients_mutation_response";
    returning: Array<{
      __typename?: "Prep_Ingredients";
      ingredient_id: any;
      prep_id: any;
    }>;
  } | null;
};

export type AddPrepPrepsMutationVariables = Exact<{
  preps: Array<Prep_Preps_Insert_Input> | Prep_Preps_Insert_Input;
}>;

export type AddPrepPrepsMutation = {
  __typename?: "mutation_root";
  insert_Prep_Preps?: {
    __typename?: "Prep_Preps_mutation_response";
    returning: Array<{
      __typename?: "Prep_Preps";
      input_prep_id: any;
      prep_id: any;
    }>;
  } | null;
};

export type AddPrepStepsMutationVariables = Exact<{
  steps: Array<PrepStep_Insert_Input> | PrepStep_Insert_Input;
}>;

export type AddPrepStepsMutation = {
  __typename?: "mutation_root";
  insert_PrepStep?: {
    __typename?: "PrepStep_mutation_response";
    affected_rows: number;
  } | null;
};

export type AddPrepToolsMutationVariables = Exact<{
  tools: Array<Prep_Tools_Insert_Input> | Prep_Tools_Insert_Input;
}>;

export type AddPrepToolsMutation = {
  __typename?: "mutation_root";
  insert_Prep_Tools?: {
    __typename?: "Prep_Tools_mutation_response";
    returning: Array<{ __typename?: "Prep_Tools"; prep_id: any; tool_id: any }>;
  } | null;
};

export type DeletePrepIngredientsMutationVariables = Exact<{
  where: Prep_Ingredients_Bool_Exp;
}>;

export type DeletePrepIngredientsMutation = {
  __typename?: "mutation_root";
  delete_Prep_Ingredients?: {
    __typename?: "Prep_Ingredients_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeletePrepPrepsMutationVariables = Exact<{
  where: Prep_Preps_Bool_Exp;
}>;

export type DeletePrepPrepsMutation = {
  __typename?: "mutation_root";
  delete_Prep_Preps?: {
    __typename?: "Prep_Preps_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeletePrepStepsMutationVariables = Exact<{
  step_ids: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type DeletePrepStepsMutation = {
  __typename?: "mutation_root";
  delete_PrepStep?: {
    __typename?: "PrepStep_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeletePrepToolsMutationVariables = Exact<{
  where: Prep_Tools_Bool_Exp;
}>;

export type DeletePrepToolsMutation = {
  __typename?: "mutation_root";
  delete_Prep_Tools?: {
    __typename?: "Prep_Tools_mutation_response";
    affected_rows: number;
  } | null;
};

export type UpdatePrepMutationVariables = Exact<{
  id: Scalars["uuid"];
  name?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  time_ahead?: InputMaybe<Scalars["Int"]>;
  measure_unit?: InputMaybe<Scalars["String"]>;
  serving_size?: InputMaybe<Scalars["Int"]>;
  rest_time?: InputMaybe<Scalars["Int"]>;
  rest_conditions?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  technic_id?: InputMaybe<Scalars["uuid"]>;
  dish_id?: InputMaybe<Scalars["uuid"]>;
  main_prep_id?: InputMaybe<Scalars["uuid"]>;
}>;

export type UpdatePrepMutation = {
  __typename?: "mutation_root";
  update_Prep_by_pk?: { __typename?: "Prep"; id: any; updated_at: any } | null;
};

export type UpdatePrepIngredientsMutationVariables = Exact<{
  ingredients: Array<Prep_Ingredients_Updates> | Prep_Ingredients_Updates;
}>;

export type UpdatePrepIngredientsMutation = {
  __typename?: "mutation_root";
  update_Prep_Ingredients_many?: Array<{
    __typename?: "Prep_Ingredients_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type UpdatePrepPrepsMutationVariables = Exact<{
  preps: Array<Prep_Preps_Updates> | Prep_Preps_Updates;
}>;

export type UpdatePrepPrepsMutation = {
  __typename?: "mutation_root";
  update_Prep_Preps_many?: Array<{
    __typename?: "Prep_Preps_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type UpdatePrepStepsMutationVariables = Exact<{
  steps: Array<PrepStep_Updates> | PrepStep_Updates;
}>;

export type UpdatePrepStepsMutation = {
  __typename?: "mutation_root";
  update_PrepStep_many?: Array<{
    __typename?: "PrepStep_mutation_response";
    affected_rows: number;
  } | null> | null;
};

export type GetMainPrepsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMainPrepsQuery = {
  __typename?: "query_root";
  Prep: Array<{ __typename?: "Prep"; id: any; name: string }>;
};

export type GetPrepQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPrepQuery = {
  __typename?: "query_root";
  Prep_by_pk?: {
    __typename?: "Prep";
    id: any;
    name: string;
    description?: string | null;
    time_ahead?: number | null;
    measure_unit: string;
    serving_size: number;
    rest_time: number;
    rest_conditions?: string | null;
    duration: number;
    MainPrep?: { __typename?: "Prep"; id: any; name: string } | null;
    AlternativePreps: Array<{ __typename?: "Prep"; id: any; name: string }>;
    Ingredients: Array<{
      __typename?: "Prep_Ingredients";
      quantity: number;
      Ingredient: {
        __typename?: "Ingredient";
        id: any;
        name: string;
        measure_unit: string;
      };
    }>;
    Tools: Array<{
      __typename?: "Prep_Tools";
      Tool: { __typename?: "Tool"; id: any; name: string };
    }>;
    Technic?: {
      __typename?: "Technic";
      id: any;
      name: string;
      type: string;
    } | null;
    InputPreps: Array<{
      __typename?: "Prep_Preps";
      quantity: number;
      Prep: {
        __typename?: "Prep";
        id: any;
        name: string;
        measure_unit: string;
        serving_size: number;
      };
    }>;
    Dish?: { __typename?: "Dish"; id: any; name?: string | null } | null;
    Steps: Array<{
      __typename?: "PrepStep";
      id: any;
      name: string;
      order: number;
      description: string;
      difficulty?: string | null;
    }>;
  } | null;
};

export type GetPrepsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrepsQuery = {
  __typename?: "query_root";
  Prep: Array<{ __typename?: "Prep"; id: any; name: string; updated_at: any }>;
};

export type SearchPrepsQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchPrepsQuery = {
  __typename?: "query_root";
  Prep: Array<{ __typename?: "Prep"; id: any; name: string }>;
};

export type AddRecipeMutationVariables = Exact<{
  recipe: Recipe_Insert_Input;
}>;

export type AddRecipeMutation = {
  __typename?: "mutation_root";
  insert_Recipe_one?: { __typename?: "Recipe"; id: any; name: string } | null;
};

export type GetRecipesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecipesQuery = {
  __typename?: "query_root";
  Recipe: Array<{ __typename?: "Recipe"; id: any; name: string }>;
};

export type AddTechnicMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}>;

export type AddTechnicMutation = {
  __typename?: "mutation_root";
  insert_Technic_one?: {
    __typename?: "Technic";
    id: any;
    name: string;
    type: string;
  } | null;
};

export type UpdateTechnicMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateTechnicMutation = {
  __typename?: "mutation_root";
  update_Technic?: {
    __typename?: "Technic_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetTechnicQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetTechnicQuery = {
  __typename?: "query_root";
  Technic_by_pk?: {
    __typename?: "Technic";
    id: any;
    name: string;
    type: string;
  } | null;
};

export type GetTechnicsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTechnicsQuery = {
  __typename?: "query_root";
  Technic: Array<{
    __typename?: "Technic";
    id: any;
    name: string;
    type: string;
  }>;
};

export type SearchTechnicsQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchTechnicsQuery = {
  __typename?: "query_root";
  Technic: Array<{ __typename?: "Technic"; id: any; name: string }>;
};

export type AddToolMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
}>;

export type AddToolMutation = {
  __typename?: "mutation_root";
  insert_Tool_one?: { __typename?: "Tool"; id: any; name: string } | null;
};

export type UpdateToolMutationVariables = Exact<{
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  pre_heat_time?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateToolMutation = {
  __typename?: "mutation_root";
  update_Tool?: {
    __typename?: "Tool_mutation_response";
    affected_rows: number;
  } | null;
};

export type GetToolQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetToolQuery = {
  __typename?: "query_root";
  Tool_by_pk?: {
    __typename?: "Tool";
    id: any;
    name: string;
    pre_heat_time: number;
  } | null;
};

export type GetToolsQueryVariables = Exact<{ [key: string]: never }>;

export type GetToolsQuery = {
  __typename?: "query_root";
  Tool: Array<{ __typename?: "Tool"; id: any; name: string }>;
};

export type SearchToolsQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchToolsQuery = {
  __typename?: "query_root";
  Tool: Array<{ __typename?: "Tool"; id: any; name: string }>;
};

export const AddDishDocument = gql`
  mutation AddDish(
    $id: uuid
    $name: String
    $kind: String
    $category_id: uuid
  ) {
    insert_Dish_one(
      object: { id: $id, name: $name, kind: $kind, category_id: $category_id }
    ) {
      id
    }
  }
`;
export type AddDishMutationFn = Apollo.MutationFunction<
  AddDishMutation,
  AddDishMutationVariables
>;

/**
 * __useAddDishMutation__
 *
 * To run a mutation, you first call `useAddDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDishMutation, { data, loading, error }] = useAddDishMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useAddDishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDishMutation,
    AddDishMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDishMutation, AddDishMutationVariables>(
    AddDishDocument,
    options
  );
}
export type AddDishMutationHookResult = ReturnType<typeof useAddDishMutation>;
export type AddDishMutationResult = Apollo.MutationResult<AddDishMutation>;
export type AddDishMutationOptions = Apollo.BaseMutationOptions<
  AddDishMutation,
  AddDishMutationVariables
>;
export const UpdateDishDocument = gql`
  mutation UpdateDish(
    $id: uuid
    $name: String
    $kind: String
    $category_id: uuid
  ) {
    update_Dish(
      _set: { name: $name, kind: $kind, category_id: $category_id }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export type UpdateDishMutationFn = Apollo.MutationFunction<
  UpdateDishMutation,
  UpdateDishMutationVariables
>;

/**
 * __useUpdateDishMutation__
 *
 * To run a mutation, you first call `useUpdateDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDishMutation, { data, loading, error }] = useUpdateDishMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *      category_id: // value for 'category_id'
 *   },
 * });
 */
export function useUpdateDishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDishMutation,
    UpdateDishMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDishMutation, UpdateDishMutationVariables>(
    UpdateDishDocument,
    options
  );
}
export type UpdateDishMutationHookResult = ReturnType<
  typeof useUpdateDishMutation
>;
export type UpdateDishMutationResult =
  Apollo.MutationResult<UpdateDishMutation>;
export type UpdateDishMutationOptions = Apollo.BaseMutationOptions<
  UpdateDishMutation,
  UpdateDishMutationVariables
>;
export const GetDishDocument = gql`
  query getDish($id: uuid!) {
    Dish_by_pk(id: $id) {
      id
      name
      kind
      DishCategory {
        id
        name
      }
      Preps {
        id
        name
      }
    }
  }
`;

/**
 * __useGetDishQuery__
 *
 * To run a query within a React component, call `useGetDishQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDishQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDishQuery(
  baseOptions: Apollo.QueryHookOptions<GetDishQuery, GetDishQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDishQuery, GetDishQueryVariables>(
    GetDishDocument,
    options
  );
}
export function useGetDishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDishQuery, GetDishQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDishQuery, GetDishQueryVariables>(
    GetDishDocument,
    options
  );
}
export type GetDishQueryHookResult = ReturnType<typeof useGetDishQuery>;
export type GetDishLazyQueryHookResult = ReturnType<typeof useGetDishLazyQuery>;
export type GetDishQueryResult = Apollo.QueryResult<
  GetDishQuery,
  GetDishQueryVariables
>;
export const GetDishCategoriesDocument = gql`
  query getDishCategories {
    DishCategory {
      id
      name
    }
  }
`;

/**
 * __useGetDishCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDishCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDishCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDishCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDishCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDishCategoriesQuery,
    GetDishCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDishCategoriesQuery,
    GetDishCategoriesQueryVariables
  >(GetDishCategoriesDocument, options);
}
export function useGetDishCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDishCategoriesQuery,
    GetDishCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDishCategoriesQuery,
    GetDishCategoriesQueryVariables
  >(GetDishCategoriesDocument, options);
}
export type GetDishCategoriesQueryHookResult = ReturnType<
  typeof useGetDishCategoriesQuery
>;
export type GetDishCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetDishCategoriesLazyQuery
>;
export type GetDishCategoriesQueryResult = Apollo.QueryResult<
  GetDishCategoriesQuery,
  GetDishCategoriesQueryVariables
>;
export const GetDishesDocument = gql`
  query getDishes {
    Dish(order_by: { updated_at: desc }) {
      id
      name
      updated_at
      DishCategory {
        id
        name
      }
      Preps_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useGetDishesQuery__
 *
 * To run a query within a React component, call `useGetDishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDishesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDishesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDishesQuery, GetDishesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDishesQuery, GetDishesQueryVariables>(
    GetDishesDocument,
    options
  );
}
export function useGetDishesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDishesQuery,
    GetDishesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDishesQuery, GetDishesQueryVariables>(
    GetDishesDocument,
    options
  );
}
export type GetDishesQueryHookResult = ReturnType<typeof useGetDishesQuery>;
export type GetDishesLazyQueryHookResult = ReturnType<
  typeof useGetDishesLazyQuery
>;
export type GetDishesQueryResult = Apollo.QueryResult<
  GetDishesQuery,
  GetDishesQueryVariables
>;
export const SearchDishesDocument = gql`
  query searchDishes($name: String, $limit: Int = 10) {
    Dish(
      where: { name: { _iregex: $name } }
      order_by: { updated_at: desc }
      limit: $limit
    ) {
      id
      name
    }
  }
`;

/**
 * __useSearchDishesQuery__
 *
 * To run a query within a React component, call `useSearchDishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDishesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchDishesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchDishesQuery,
    SearchDishesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchDishesQuery, SearchDishesQueryVariables>(
    SearchDishesDocument,
    options
  );
}
export function useSearchDishesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchDishesQuery,
    SearchDishesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchDishesQuery, SearchDishesQueryVariables>(
    SearchDishesDocument,
    options
  );
}
export type SearchDishesQueryHookResult = ReturnType<
  typeof useSearchDishesQuery
>;
export type SearchDishesLazyQueryHookResult = ReturnType<
  typeof useSearchDishesLazyQuery
>;
export type SearchDishesQueryResult = Apollo.QueryResult<
  SearchDishesQuery,
  SearchDishesQueryVariables
>;
export const AddIngredientDocument = gql`
  mutation AddIngredient(
    $id: uuid!
    $name: String!
    $type: String!
    $consistency: String!
    $measure_unit: String!
    $can_split: Boolean!
    $avg_item_quantity: Int
    $ambient_temp_time: Int
  ) {
    insert_Ingredient_one(
      object: {
        availability: ""
        difficulty: ""
        id: $id
        name: $name
        price: 0
        type: $type
        consistency: $consistency
        measure_unit: $measure_unit
        can_split: $can_split
        avg_item_quantity: $avg_item_quantity
        ambient_temp_time: $ambient_temp_time
      }
    ) {
      id
      name
    }
  }
`;
export type AddIngredientMutationFn = Apollo.MutationFunction<
  AddIngredientMutation,
  AddIngredientMutationVariables
>;

/**
 * __useAddIngredientMutation__
 *
 * To run a mutation, you first call `useAddIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIngredientMutation, { data, loading, error }] = useAddIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      consistency: // value for 'consistency'
 *      measure_unit: // value for 'measure_unit'
 *      can_split: // value for 'can_split'
 *      avg_item_quantity: // value for 'avg_item_quantity'
 *      ambient_temp_time: // value for 'ambient_temp_time'
 *   },
 * });
 */
export function useAddIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIngredientMutation,
    AddIngredientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddIngredientMutation,
    AddIngredientMutationVariables
  >(AddIngredientDocument, options);
}
export type AddIngredientMutationHookResult = ReturnType<
  typeof useAddIngredientMutation
>;
export type AddIngredientMutationResult =
  Apollo.MutationResult<AddIngredientMutation>;
export type AddIngredientMutationOptions = Apollo.BaseMutationOptions<
  AddIngredientMutation,
  AddIngredientMutationVariables
>;
export const UpdateIngredientDocument = gql`
  mutation UpdateIngredient(
    $id: uuid!
    $name: String
    $type: String
    $consistency: String
    $measure_unit: String
    $can_split: Boolean
    $avg_item_quantity: Int
    $ambient_temp_time: Int
  ) {
    update_Ingredient_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        type: $type
        measure_unit: $measure_unit
        consistency: $consistency
        can_split: $can_split
        avg_item_quantity: $avg_item_quantity
        ambient_temp_time: $ambient_temp_time
      }
    ) {
      id
    }
  }
`;
export type UpdateIngredientMutationFn = Apollo.MutationFunction<
  UpdateIngredientMutation,
  UpdateIngredientMutationVariables
>;

/**
 * __useUpdateIngredientMutation__
 *
 * To run a mutation, you first call `useUpdateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngredientMutation, { data, loading, error }] = useUpdateIngredientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      consistency: // value for 'consistency'
 *      measure_unit: // value for 'measure_unit'
 *      can_split: // value for 'can_split'
 *      avg_item_quantity: // value for 'avg_item_quantity'
 *      ambient_temp_time: // value for 'ambient_temp_time'
 *   },
 * });
 */
export function useUpdateIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIngredientMutation,
    UpdateIngredientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIngredientMutation,
    UpdateIngredientMutationVariables
  >(UpdateIngredientDocument, options);
}
export type UpdateIngredientMutationHookResult = ReturnType<
  typeof useUpdateIngredientMutation
>;
export type UpdateIngredientMutationResult =
  Apollo.MutationResult<UpdateIngredientMutation>;
export type UpdateIngredientMutationOptions = Apollo.BaseMutationOptions<
  UpdateIngredientMutation,
  UpdateIngredientMutationVariables
>;
export const GetIngredientDocument = gql`
  query getIngredient($id: uuid!) {
    Ingredient_by_pk(id: $id) {
      id
      name
      type
      measure_unit
      consistency
      can_split
      avg_item_quantity
      ambient_temp_time
    }
  }
`;

/**
 * __useGetIngredientQuery__
 *
 * To run a query within a React component, call `useGetIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIngredientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIngredientQuery,
    GetIngredientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIngredientQuery, GetIngredientQueryVariables>(
    GetIngredientDocument,
    options
  );
}
export function useGetIngredientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIngredientQuery,
    GetIngredientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIngredientQuery, GetIngredientQueryVariables>(
    GetIngredientDocument,
    options
  );
}
export type GetIngredientQueryHookResult = ReturnType<
  typeof useGetIngredientQuery
>;
export type GetIngredientLazyQueryHookResult = ReturnType<
  typeof useGetIngredientLazyQuery
>;
export type GetIngredientQueryResult = Apollo.QueryResult<
  GetIngredientQuery,
  GetIngredientQueryVariables
>;
export const GetIngredientsDocument = gql`
  query getIngredients {
    Ingredient {
      id
      name
      type
      measure_unit
      avg_item_quantity
    }
  }
`;

/**
 * __useGetIngredientsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIngredientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIngredientsQuery,
    GetIngredientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(
    GetIngredientsDocument,
    options
  );
}
export function useGetIngredientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIngredientsQuery,
    GetIngredientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(
    GetIngredientsDocument,
    options
  );
}
export type GetIngredientsQueryHookResult = ReturnType<
  typeof useGetIngredientsQuery
>;
export type GetIngredientsLazyQueryHookResult = ReturnType<
  typeof useGetIngredientsLazyQuery
>;
export type GetIngredientsQueryResult = Apollo.QueryResult<
  GetIngredientsQuery,
  GetIngredientsQueryVariables
>;
export const SearchIngredientDocument = gql`
  query SearchIngredient($name: String, $limit: Int = 10) {
    Ingredient(where: { name: { _iregex: $name } }, limit: $limit) {
      id
      name
    }
  }
`;

/**
 * __useSearchIngredientQuery__
 *
 * To run a query within a React component, call `useSearchIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchIngredientQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchIngredientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchIngredientQuery,
    SearchIngredientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchIngredientQuery, SearchIngredientQueryVariables>(
    SearchIngredientDocument,
    options
  );
}
export function useSearchIngredientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchIngredientQuery,
    SearchIngredientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchIngredientQuery,
    SearchIngredientQueryVariables
  >(SearchIngredientDocument, options);
}
export type SearchIngredientQueryHookResult = ReturnType<
  typeof useSearchIngredientQuery
>;
export type SearchIngredientLazyQueryHookResult = ReturnType<
  typeof useSearchIngredientLazyQuery
>;
export type SearchIngredientQueryResult = Apollo.QueryResult<
  SearchIngredientQuery,
  SearchIngredientQueryVariables
>;
export const AddPrepDocument = gql`
  mutation AddPrep(
    $id: uuid
    $name: String
    $description: String
    $time_ahead: Int
    $measure_unit: String
    $serving_size: Int
    $rest_time: Int
    $rest_conditions: String
    $duration: Int
    $technic_id: uuid
    $dish_id: uuid
    $main_prep_id: uuid
  ) {
    insert_Prep_one(
      object: {
        id: $id
        name: $name
        description: $description
        time_ahead: $time_ahead
        measure_unit: $measure_unit
        serving_size: $serving_size
        rest_time: $rest_time
        rest_conditions: $rest_conditions
        duration: $duration
        technic_id: $technic_id
        dish_id: $dish_id
        main_prep_id: $main_prep_id
      }
    ) {
      id
      name
    }
  }
`;
export type AddPrepMutationFn = Apollo.MutationFunction<
  AddPrepMutation,
  AddPrepMutationVariables
>;

/**
 * __useAddPrepMutation__
 *
 * To run a mutation, you first call `useAddPrepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepMutation, { data, loading, error }] = useAddPrepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      time_ahead: // value for 'time_ahead'
 *      measure_unit: // value for 'measure_unit'
 *      serving_size: // value for 'serving_size'
 *      rest_time: // value for 'rest_time'
 *      rest_conditions: // value for 'rest_conditions'
 *      duration: // value for 'duration'
 *      technic_id: // value for 'technic_id'
 *      dish_id: // value for 'dish_id'
 *      main_prep_id: // value for 'main_prep_id'
 *   },
 * });
 */
export function useAddPrepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrepMutation,
    AddPrepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPrepMutation, AddPrepMutationVariables>(
    AddPrepDocument,
    options
  );
}
export type AddPrepMutationHookResult = ReturnType<typeof useAddPrepMutation>;
export type AddPrepMutationResult = Apollo.MutationResult<AddPrepMutation>;
export type AddPrepMutationOptions = Apollo.BaseMutationOptions<
  AddPrepMutation,
  AddPrepMutationVariables
>;
export const AddPrepIngredientsDocument = gql`
  mutation AddPrepIngredients($ingredients: [Prep_Ingredients_insert_input!]!) {
    insert_Prep_Ingredients(objects: $ingredients) {
      returning {
        ingredient_id
        prep_id
      }
    }
  }
`;
export type AddPrepIngredientsMutationFn = Apollo.MutationFunction<
  AddPrepIngredientsMutation,
  AddPrepIngredientsMutationVariables
>;

/**
 * __useAddPrepIngredientsMutation__
 *
 * To run a mutation, you first call `useAddPrepIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepIngredientsMutation, { data, loading, error }] = useAddPrepIngredientsMutation({
 *   variables: {
 *      ingredients: // value for 'ingredients'
 *   },
 * });
 */
export function useAddPrepIngredientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrepIngredientsMutation,
    AddPrepIngredientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPrepIngredientsMutation,
    AddPrepIngredientsMutationVariables
  >(AddPrepIngredientsDocument, options);
}
export type AddPrepIngredientsMutationHookResult = ReturnType<
  typeof useAddPrepIngredientsMutation
>;
export type AddPrepIngredientsMutationResult =
  Apollo.MutationResult<AddPrepIngredientsMutation>;
export type AddPrepIngredientsMutationOptions = Apollo.BaseMutationOptions<
  AddPrepIngredientsMutation,
  AddPrepIngredientsMutationVariables
>;
export const AddPrepPrepsDocument = gql`
  mutation AddPrepPreps($preps: [Prep_Preps_insert_input!]!) {
    insert_Prep_Preps(objects: $preps) {
      returning {
        input_prep_id
        prep_id
      }
    }
  }
`;
export type AddPrepPrepsMutationFn = Apollo.MutationFunction<
  AddPrepPrepsMutation,
  AddPrepPrepsMutationVariables
>;

/**
 * __useAddPrepPrepsMutation__
 *
 * To run a mutation, you first call `useAddPrepPrepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepPrepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepPrepsMutation, { data, loading, error }] = useAddPrepPrepsMutation({
 *   variables: {
 *      preps: // value for 'preps'
 *   },
 * });
 */
export function useAddPrepPrepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrepPrepsMutation,
    AddPrepPrepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPrepPrepsMutation,
    AddPrepPrepsMutationVariables
  >(AddPrepPrepsDocument, options);
}
export type AddPrepPrepsMutationHookResult = ReturnType<
  typeof useAddPrepPrepsMutation
>;
export type AddPrepPrepsMutationResult =
  Apollo.MutationResult<AddPrepPrepsMutation>;
export type AddPrepPrepsMutationOptions = Apollo.BaseMutationOptions<
  AddPrepPrepsMutation,
  AddPrepPrepsMutationVariables
>;
export const AddPrepStepsDocument = gql`
  mutation AddPrepSteps($steps: [PrepStep_insert_input!]!) {
    insert_PrepStep(objects: $steps) {
      affected_rows
    }
  }
`;
export type AddPrepStepsMutationFn = Apollo.MutationFunction<
  AddPrepStepsMutation,
  AddPrepStepsMutationVariables
>;

/**
 * __useAddPrepStepsMutation__
 *
 * To run a mutation, you first call `useAddPrepStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepStepsMutation, { data, loading, error }] = useAddPrepStepsMutation({
 *   variables: {
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useAddPrepStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrepStepsMutation,
    AddPrepStepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPrepStepsMutation,
    AddPrepStepsMutationVariables
  >(AddPrepStepsDocument, options);
}
export type AddPrepStepsMutationHookResult = ReturnType<
  typeof useAddPrepStepsMutation
>;
export type AddPrepStepsMutationResult =
  Apollo.MutationResult<AddPrepStepsMutation>;
export type AddPrepStepsMutationOptions = Apollo.BaseMutationOptions<
  AddPrepStepsMutation,
  AddPrepStepsMutationVariables
>;
export const AddPrepToolsDocument = gql`
  mutation AddPrepTools($tools: [Prep_Tools_insert_input!]!) {
    insert_Prep_Tools(objects: $tools) {
      returning {
        prep_id
        tool_id
      }
    }
  }
`;
export type AddPrepToolsMutationFn = Apollo.MutationFunction<
  AddPrepToolsMutation,
  AddPrepToolsMutationVariables
>;

/**
 * __useAddPrepToolsMutation__
 *
 * To run a mutation, you first call `useAddPrepToolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepToolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepToolsMutation, { data, loading, error }] = useAddPrepToolsMutation({
 *   variables: {
 *      tools: // value for 'tools'
 *   },
 * });
 */
export function useAddPrepToolsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrepToolsMutation,
    AddPrepToolsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPrepToolsMutation,
    AddPrepToolsMutationVariables
  >(AddPrepToolsDocument, options);
}
export type AddPrepToolsMutationHookResult = ReturnType<
  typeof useAddPrepToolsMutation
>;
export type AddPrepToolsMutationResult =
  Apollo.MutationResult<AddPrepToolsMutation>;
export type AddPrepToolsMutationOptions = Apollo.BaseMutationOptions<
  AddPrepToolsMutation,
  AddPrepToolsMutationVariables
>;
export const DeletePrepIngredientsDocument = gql`
  mutation DeletePrepIngredients($where: Prep_Ingredients_bool_exp!) {
    delete_Prep_Ingredients(where: $where) {
      affected_rows
    }
  }
`;
export type DeletePrepIngredientsMutationFn = Apollo.MutationFunction<
  DeletePrepIngredientsMutation,
  DeletePrepIngredientsMutationVariables
>;

/**
 * __useDeletePrepIngredientsMutation__
 *
 * To run a mutation, you first call `useDeletePrepIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrepIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrepIngredientsMutation, { data, loading, error }] = useDeletePrepIngredientsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePrepIngredientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrepIngredientsMutation,
    DeletePrepIngredientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrepIngredientsMutation,
    DeletePrepIngredientsMutationVariables
  >(DeletePrepIngredientsDocument, options);
}
export type DeletePrepIngredientsMutationHookResult = ReturnType<
  typeof useDeletePrepIngredientsMutation
>;
export type DeletePrepIngredientsMutationResult =
  Apollo.MutationResult<DeletePrepIngredientsMutation>;
export type DeletePrepIngredientsMutationOptions = Apollo.BaseMutationOptions<
  DeletePrepIngredientsMutation,
  DeletePrepIngredientsMutationVariables
>;
export const DeletePrepPrepsDocument = gql`
  mutation DeletePrepPreps($where: Prep_Preps_bool_exp!) {
    delete_Prep_Preps(where: $where) {
      affected_rows
    }
  }
`;
export type DeletePrepPrepsMutationFn = Apollo.MutationFunction<
  DeletePrepPrepsMutation,
  DeletePrepPrepsMutationVariables
>;

/**
 * __useDeletePrepPrepsMutation__
 *
 * To run a mutation, you first call `useDeletePrepPrepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrepPrepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrepPrepsMutation, { data, loading, error }] = useDeletePrepPrepsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePrepPrepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrepPrepsMutation,
    DeletePrepPrepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrepPrepsMutation,
    DeletePrepPrepsMutationVariables
  >(DeletePrepPrepsDocument, options);
}
export type DeletePrepPrepsMutationHookResult = ReturnType<
  typeof useDeletePrepPrepsMutation
>;
export type DeletePrepPrepsMutationResult =
  Apollo.MutationResult<DeletePrepPrepsMutation>;
export type DeletePrepPrepsMutationOptions = Apollo.BaseMutationOptions<
  DeletePrepPrepsMutation,
  DeletePrepPrepsMutationVariables
>;
export const DeletePrepStepsDocument = gql`
  mutation DeletePrepSteps($step_ids: [uuid!]!) {
    delete_PrepStep(where: { id: { _in: $step_ids } }) {
      affected_rows
    }
  }
`;
export type DeletePrepStepsMutationFn = Apollo.MutationFunction<
  DeletePrepStepsMutation,
  DeletePrepStepsMutationVariables
>;

/**
 * __useDeletePrepStepsMutation__
 *
 * To run a mutation, you first call `useDeletePrepStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrepStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrepStepsMutation, { data, loading, error }] = useDeletePrepStepsMutation({
 *   variables: {
 *      step_ids: // value for 'step_ids'
 *   },
 * });
 */
export function useDeletePrepStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrepStepsMutation,
    DeletePrepStepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrepStepsMutation,
    DeletePrepStepsMutationVariables
  >(DeletePrepStepsDocument, options);
}
export type DeletePrepStepsMutationHookResult = ReturnType<
  typeof useDeletePrepStepsMutation
>;
export type DeletePrepStepsMutationResult =
  Apollo.MutationResult<DeletePrepStepsMutation>;
export type DeletePrepStepsMutationOptions = Apollo.BaseMutationOptions<
  DeletePrepStepsMutation,
  DeletePrepStepsMutationVariables
>;
export const DeletePrepToolsDocument = gql`
  mutation DeletePrepTools($where: Prep_Tools_bool_exp!) {
    delete_Prep_Tools(where: $where) {
      affected_rows
    }
  }
`;
export type DeletePrepToolsMutationFn = Apollo.MutationFunction<
  DeletePrepToolsMutation,
  DeletePrepToolsMutationVariables
>;

/**
 * __useDeletePrepToolsMutation__
 *
 * To run a mutation, you first call `useDeletePrepToolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrepToolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrepToolsMutation, { data, loading, error }] = useDeletePrepToolsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePrepToolsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePrepToolsMutation,
    DeletePrepToolsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePrepToolsMutation,
    DeletePrepToolsMutationVariables
  >(DeletePrepToolsDocument, options);
}
export type DeletePrepToolsMutationHookResult = ReturnType<
  typeof useDeletePrepToolsMutation
>;
export type DeletePrepToolsMutationResult =
  Apollo.MutationResult<DeletePrepToolsMutation>;
export type DeletePrepToolsMutationOptions = Apollo.BaseMutationOptions<
  DeletePrepToolsMutation,
  DeletePrepToolsMutationVariables
>;
export const UpdatePrepDocument = gql`
  mutation UpdatePrep(
    $id: uuid!
    $name: String
    $description: String
    $time_ahead: Int
    $measure_unit: String
    $serving_size: Int
    $rest_time: Int
    $rest_conditions: String
    $duration: Int
    $technic_id: uuid
    $dish_id: uuid
    $main_prep_id: uuid
  ) {
    update_Prep_by_pk(
      pk_columns: { id: $id }
      _set: {
        dish_id: $dish_id
        name: $name
        description: $description
        time_ahead: $time_ahead
        measure_unit: $measure_unit
        serving_size: $serving_size
        rest_time: $rest_time
        rest_conditions: $rest_conditions
        duration: $duration
        technic_id: $technic_id
        main_prep_id: $main_prep_id
      }
    ) {
      id
      updated_at
    }
  }
`;
export type UpdatePrepMutationFn = Apollo.MutationFunction<
  UpdatePrepMutation,
  UpdatePrepMutationVariables
>;

/**
 * __useUpdatePrepMutation__
 *
 * To run a mutation, you first call `useUpdatePrepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrepMutation, { data, loading, error }] = useUpdatePrepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      time_ahead: // value for 'time_ahead'
 *      measure_unit: // value for 'measure_unit'
 *      serving_size: // value for 'serving_size'
 *      rest_time: // value for 'rest_time'
 *      rest_conditions: // value for 'rest_conditions'
 *      duration: // value for 'duration'
 *      technic_id: // value for 'technic_id'
 *      dish_id: // value for 'dish_id'
 *      main_prep_id: // value for 'main_prep_id'
 *   },
 * });
 */
export function useUpdatePrepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrepMutation,
    UpdatePrepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePrepMutation, UpdatePrepMutationVariables>(
    UpdatePrepDocument,
    options
  );
}
export type UpdatePrepMutationHookResult = ReturnType<
  typeof useUpdatePrepMutation
>;
export type UpdatePrepMutationResult =
  Apollo.MutationResult<UpdatePrepMutation>;
export type UpdatePrepMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrepMutation,
  UpdatePrepMutationVariables
>;
export const UpdatePrepIngredientsDocument = gql`
  mutation UpdatePrepIngredients($ingredients: [Prep_Ingredients_updates!]!) {
    update_Prep_Ingredients_many(updates: $ingredients) {
      affected_rows
    }
  }
`;
export type UpdatePrepIngredientsMutationFn = Apollo.MutationFunction<
  UpdatePrepIngredientsMutation,
  UpdatePrepIngredientsMutationVariables
>;

/**
 * __useUpdatePrepIngredientsMutation__
 *
 * To run a mutation, you first call `useUpdatePrepIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrepIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrepIngredientsMutation, { data, loading, error }] = useUpdatePrepIngredientsMutation({
 *   variables: {
 *      ingredients: // value for 'ingredients'
 *   },
 * });
 */
export function useUpdatePrepIngredientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrepIngredientsMutation,
    UpdatePrepIngredientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrepIngredientsMutation,
    UpdatePrepIngredientsMutationVariables
  >(UpdatePrepIngredientsDocument, options);
}
export type UpdatePrepIngredientsMutationHookResult = ReturnType<
  typeof useUpdatePrepIngredientsMutation
>;
export type UpdatePrepIngredientsMutationResult =
  Apollo.MutationResult<UpdatePrepIngredientsMutation>;
export type UpdatePrepIngredientsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrepIngredientsMutation,
  UpdatePrepIngredientsMutationVariables
>;
export const UpdatePrepPrepsDocument = gql`
  mutation UpdatePrepPreps($preps: [Prep_Preps_updates!]!) {
    update_Prep_Preps_many(updates: $preps) {
      affected_rows
    }
  }
`;
export type UpdatePrepPrepsMutationFn = Apollo.MutationFunction<
  UpdatePrepPrepsMutation,
  UpdatePrepPrepsMutationVariables
>;

/**
 * __useUpdatePrepPrepsMutation__
 *
 * To run a mutation, you first call `useUpdatePrepPrepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrepPrepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrepPrepsMutation, { data, loading, error }] = useUpdatePrepPrepsMutation({
 *   variables: {
 *      preps: // value for 'preps'
 *   },
 * });
 */
export function useUpdatePrepPrepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrepPrepsMutation,
    UpdatePrepPrepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrepPrepsMutation,
    UpdatePrepPrepsMutationVariables
  >(UpdatePrepPrepsDocument, options);
}
export type UpdatePrepPrepsMutationHookResult = ReturnType<
  typeof useUpdatePrepPrepsMutation
>;
export type UpdatePrepPrepsMutationResult =
  Apollo.MutationResult<UpdatePrepPrepsMutation>;
export type UpdatePrepPrepsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrepPrepsMutation,
  UpdatePrepPrepsMutationVariables
>;
export const UpdatePrepStepsDocument = gql`
  mutation UpdatePrepSteps($steps: [PrepStep_updates!]!) {
    update_PrepStep_many(updates: $steps) {
      affected_rows
    }
  }
`;
export type UpdatePrepStepsMutationFn = Apollo.MutationFunction<
  UpdatePrepStepsMutation,
  UpdatePrepStepsMutationVariables
>;

/**
 * __useUpdatePrepStepsMutation__
 *
 * To run a mutation, you first call `useUpdatePrepStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrepStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrepStepsMutation, { data, loading, error }] = useUpdatePrepStepsMutation({
 *   variables: {
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdatePrepStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrepStepsMutation,
    UpdatePrepStepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrepStepsMutation,
    UpdatePrepStepsMutationVariables
  >(UpdatePrepStepsDocument, options);
}
export type UpdatePrepStepsMutationHookResult = ReturnType<
  typeof useUpdatePrepStepsMutation
>;
export type UpdatePrepStepsMutationResult =
  Apollo.MutationResult<UpdatePrepStepsMutation>;
export type UpdatePrepStepsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrepStepsMutation,
  UpdatePrepStepsMutationVariables
>;
export const GetMainPrepsDocument = gql`
  query getMainPreps {
    Prep(
      where: { main_prep_id: { _is_null: true } }
      order_by: { updated_at: desc }
    ) {
      id
      name
    }
  }
`;

/**
 * __useGetMainPrepsQuery__
 *
 * To run a query within a React component, call `useGetMainPrepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainPrepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainPrepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMainPrepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMainPrepsQuery,
    GetMainPrepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMainPrepsQuery, GetMainPrepsQueryVariables>(
    GetMainPrepsDocument,
    options
  );
}
export function useGetMainPrepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMainPrepsQuery,
    GetMainPrepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMainPrepsQuery, GetMainPrepsQueryVariables>(
    GetMainPrepsDocument,
    options
  );
}
export type GetMainPrepsQueryHookResult = ReturnType<
  typeof useGetMainPrepsQuery
>;
export type GetMainPrepsLazyQueryHookResult = ReturnType<
  typeof useGetMainPrepsLazyQuery
>;
export type GetMainPrepsQueryResult = Apollo.QueryResult<
  GetMainPrepsQuery,
  GetMainPrepsQueryVariables
>;
export const GetPrepDocument = gql`
  query getPrep($id: uuid!) {
    Prep_by_pk(id: $id) {
      id
      name
      description
      time_ahead
      measure_unit
      serving_size
      rest_time
      rest_conditions
      duration
      MainPrep {
        id
        name
      }
      AlternativePreps {
        id
        name
      }
      Ingredients {
        quantity
        Ingredient {
          id
          name
          measure_unit
        }
      }
      Tools {
        Tool {
          id
          name
        }
      }
      Technic {
        id
        name
        type
      }
      InputPreps {
        quantity
        Prep {
          id
          name
          measure_unit
          serving_size
        }
      }
      Dish {
        id
        name
      }
      Steps {
        id
        name
        order
        description
        difficulty
      }
    }
  }
`;

/**
 * __useGetPrepQuery__
 *
 * To run a query within a React component, call `useGetPrepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrepQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrepQuery, GetPrepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrepQuery, GetPrepQueryVariables>(
    GetPrepDocument,
    options
  );
}
export function useGetPrepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrepQuery, GetPrepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrepQuery, GetPrepQueryVariables>(
    GetPrepDocument,
    options
  );
}
export type GetPrepQueryHookResult = ReturnType<typeof useGetPrepQuery>;
export type GetPrepLazyQueryHookResult = ReturnType<typeof useGetPrepLazyQuery>;
export type GetPrepQueryResult = Apollo.QueryResult<
  GetPrepQuery,
  GetPrepQueryVariables
>;
export const GetPrepsDocument = gql`
  query getPreps {
    Prep(order_by: { updated_at: desc }) {
      id
      name
      updated_at
    }
  }
`;

/**
 * __useGetPrepsQuery__
 *
 * To run a query within a React component, call `useGetPrepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrepsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPrepsQuery, GetPrepsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrepsQuery, GetPrepsQueryVariables>(
    GetPrepsDocument,
    options
  );
}
export function useGetPrepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrepsQuery,
    GetPrepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrepsQuery, GetPrepsQueryVariables>(
    GetPrepsDocument,
    options
  );
}
export type GetPrepsQueryHookResult = ReturnType<typeof useGetPrepsQuery>;
export type GetPrepsLazyQueryHookResult = ReturnType<
  typeof useGetPrepsLazyQuery
>;
export type GetPrepsQueryResult = Apollo.QueryResult<
  GetPrepsQuery,
  GetPrepsQueryVariables
>;
export const SearchPrepsDocument = gql`
  query searchPreps($name: String, $limit: Int = 10) {
    Prep(
      where: { name: { _iregex: $name } }
      order_by: { updated_at: desc }
      limit: $limit
    ) {
      id
      name
    }
  }
`;

/**
 * __useSearchPrepsQuery__
 *
 * To run a query within a React component, call `useSearchPrepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPrepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPrepsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchPrepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchPrepsQuery,
    SearchPrepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPrepsQuery, SearchPrepsQueryVariables>(
    SearchPrepsDocument,
    options
  );
}
export function useSearchPrepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPrepsQuery,
    SearchPrepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPrepsQuery, SearchPrepsQueryVariables>(
    SearchPrepsDocument,
    options
  );
}
export type SearchPrepsQueryHookResult = ReturnType<typeof useSearchPrepsQuery>;
export type SearchPrepsLazyQueryHookResult = ReturnType<
  typeof useSearchPrepsLazyQuery
>;
export type SearchPrepsQueryResult = Apollo.QueryResult<
  SearchPrepsQuery,
  SearchPrepsQueryVariables
>;
export const AddRecipeDocument = gql`
  mutation AddRecipe($recipe: Recipe_insert_input!) {
    insert_Recipe_one(object: $recipe) {
      id
      name
    }
  }
`;
export type AddRecipeMutationFn = Apollo.MutationFunction<
  AddRecipeMutation,
  AddRecipeMutationVariables
>;

/**
 * __useAddRecipeMutation__
 *
 * To run a mutation, you first call `useAddRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipeMutation, { data, loading, error }] = useAddRecipeMutation({
 *   variables: {
 *      recipe: // value for 'recipe'
 *   },
 * });
 */
export function useAddRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecipeMutation,
    AddRecipeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRecipeMutation, AddRecipeMutationVariables>(
    AddRecipeDocument,
    options
  );
}
export type AddRecipeMutationHookResult = ReturnType<
  typeof useAddRecipeMutation
>;
export type AddRecipeMutationResult = Apollo.MutationResult<AddRecipeMutation>;
export type AddRecipeMutationOptions = Apollo.BaseMutationOptions<
  AddRecipeMutation,
  AddRecipeMutationVariables
>;
export const GetRecipesDocument = gql`
  query getRecipes {
    Recipe {
      id
      name
    }
  }
`;

/**
 * __useGetRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecipesQuery,
    GetRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipesQuery, GetRecipesQueryVariables>(
    GetRecipesDocument,
    options
  );
}
export function useGetRecipesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipesQuery,
    GetRecipesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipesQuery, GetRecipesQueryVariables>(
    GetRecipesDocument,
    options
  );
}
export type GetRecipesQueryHookResult = ReturnType<typeof useGetRecipesQuery>;
export type GetRecipesLazyQueryHookResult = ReturnType<
  typeof useGetRecipesLazyQuery
>;
export type GetRecipesQueryResult = Apollo.QueryResult<
  GetRecipesQuery,
  GetRecipesQueryVariables
>;
export const AddTechnicDocument = gql`
  mutation AddTechnic($id: uuid, $name: String, $type: String) {
    insert_Technic_one(
      object: { id: $id, name: $name, type: $type, difficulty: "" }
    ) {
      id
      name
      type
    }
  }
`;
export type AddTechnicMutationFn = Apollo.MutationFunction<
  AddTechnicMutation,
  AddTechnicMutationVariables
>;

/**
 * __useAddTechnicMutation__
 *
 * To run a mutation, you first call `useAddTechnicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTechnicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTechnicMutation, { data, loading, error }] = useAddTechnicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddTechnicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTechnicMutation,
    AddTechnicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTechnicMutation, AddTechnicMutationVariables>(
    AddTechnicDocument,
    options
  );
}
export type AddTechnicMutationHookResult = ReturnType<
  typeof useAddTechnicMutation
>;
export type AddTechnicMutationResult =
  Apollo.MutationResult<AddTechnicMutation>;
export type AddTechnicMutationOptions = Apollo.BaseMutationOptions<
  AddTechnicMutation,
  AddTechnicMutationVariables
>;
export const UpdateTechnicDocument = gql`
  mutation UpdateTechnic($id: uuid, $name: String, $type: String) {
    update_Technic(
      _set: { name: $name, type: $type }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export type UpdateTechnicMutationFn = Apollo.MutationFunction<
  UpdateTechnicMutation,
  UpdateTechnicMutationVariables
>;

/**
 * __useUpdateTechnicMutation__
 *
 * To run a mutation, you first call `useUpdateTechnicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnicMutation, { data, loading, error }] = useUpdateTechnicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateTechnicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTechnicMutation,
    UpdateTechnicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTechnicMutation,
    UpdateTechnicMutationVariables
  >(UpdateTechnicDocument, options);
}
export type UpdateTechnicMutationHookResult = ReturnType<
  typeof useUpdateTechnicMutation
>;
export type UpdateTechnicMutationResult =
  Apollo.MutationResult<UpdateTechnicMutation>;
export type UpdateTechnicMutationOptions = Apollo.BaseMutationOptions<
  UpdateTechnicMutation,
  UpdateTechnicMutationVariables
>;
export const GetTechnicDocument = gql`
  query getTechnic($id: uuid!) {
    Technic_by_pk(id: $id) {
      id
      name
      type
    }
  }
`;

/**
 * __useGetTechnicQuery__
 *
 * To run a query within a React component, call `useGetTechnicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTechnicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTechnicQuery,
    GetTechnicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTechnicQuery, GetTechnicQueryVariables>(
    GetTechnicDocument,
    options
  );
}
export function useGetTechnicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTechnicQuery,
    GetTechnicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTechnicQuery, GetTechnicQueryVariables>(
    GetTechnicDocument,
    options
  );
}
export type GetTechnicQueryHookResult = ReturnType<typeof useGetTechnicQuery>;
export type GetTechnicLazyQueryHookResult = ReturnType<
  typeof useGetTechnicLazyQuery
>;
export type GetTechnicQueryResult = Apollo.QueryResult<
  GetTechnicQuery,
  GetTechnicQueryVariables
>;
export const GetTechnicsDocument = gql`
  query getTechnics {
    Technic {
      id
      name
      type
    }
  }
`;

/**
 * __useGetTechnicsQuery__
 *
 * To run a query within a React component, call `useGetTechnicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTechnicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTechnicsQuery,
    GetTechnicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTechnicsQuery, GetTechnicsQueryVariables>(
    GetTechnicsDocument,
    options
  );
}
export function useGetTechnicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTechnicsQuery,
    GetTechnicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTechnicsQuery, GetTechnicsQueryVariables>(
    GetTechnicsDocument,
    options
  );
}
export type GetTechnicsQueryHookResult = ReturnType<typeof useGetTechnicsQuery>;
export type GetTechnicsLazyQueryHookResult = ReturnType<
  typeof useGetTechnicsLazyQuery
>;
export type GetTechnicsQueryResult = Apollo.QueryResult<
  GetTechnicsQuery,
  GetTechnicsQueryVariables
>;
export const SearchTechnicsDocument = gql`
  query searchTechnics($name: String, $limit: Int = 10) {
    Technic(where: { name: { _iregex: $name } }, limit: $limit) {
      id
      name
    }
  }
`;

/**
 * __useSearchTechnicsQuery__
 *
 * To run a query within a React component, call `useSearchTechnicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTechnicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTechnicsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchTechnicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchTechnicsQuery,
    SearchTechnicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTechnicsQuery, SearchTechnicsQueryVariables>(
    SearchTechnicsDocument,
    options
  );
}
export function useSearchTechnicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTechnicsQuery,
    SearchTechnicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTechnicsQuery, SearchTechnicsQueryVariables>(
    SearchTechnicsDocument,
    options
  );
}
export type SearchTechnicsQueryHookResult = ReturnType<
  typeof useSearchTechnicsQuery
>;
export type SearchTechnicsLazyQueryHookResult = ReturnType<
  typeof useSearchTechnicsLazyQuery
>;
export type SearchTechnicsQueryResult = Apollo.QueryResult<
  SearchTechnicsQuery,
  SearchTechnicsQueryVariables
>;
export const AddToolDocument = gql`
  mutation AddTool($id: uuid, $name: String, $pre_heat_time: Int) {
    insert_Tool_one(
      object: {
        availability: ""
        difficulty: ""
        id: $id
        name: $name
        pre_heat_time: $pre_heat_time
        type: ""
      }
    ) {
      id
      name
    }
  }
`;
export type AddToolMutationFn = Apollo.MutationFunction<
  AddToolMutation,
  AddToolMutationVariables
>;

/**
 * __useAddToolMutation__
 *
 * To run a mutation, you first call `useAddToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToolMutation, { data, loading, error }] = useAddToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pre_heat_time: // value for 'pre_heat_time'
 *   },
 * });
 */
export function useAddToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToolMutation,
    AddToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToolMutation, AddToolMutationVariables>(
    AddToolDocument,
    options
  );
}
export type AddToolMutationHookResult = ReturnType<typeof useAddToolMutation>;
export type AddToolMutationResult = Apollo.MutationResult<AddToolMutation>;
export type AddToolMutationOptions = Apollo.BaseMutationOptions<
  AddToolMutation,
  AddToolMutationVariables
>;
export const UpdateToolDocument = gql`
  mutation UpdateTool($id: uuid, $name: String, $pre_heat_time: Int) {
    update_Tool(
      _set: { name: $name, pre_heat_time: $pre_heat_time }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export type UpdateToolMutationFn = Apollo.MutationFunction<
  UpdateToolMutation,
  UpdateToolMutationVariables
>;

/**
 * __useUpdateToolMutation__
 *
 * To run a mutation, you first call `useUpdateToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToolMutation, { data, loading, error }] = useUpdateToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pre_heat_time: // value for 'pre_heat_time'
 *   },
 * });
 */
export function useUpdateToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateToolMutation,
    UpdateToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateToolMutation, UpdateToolMutationVariables>(
    UpdateToolDocument,
    options
  );
}
export type UpdateToolMutationHookResult = ReturnType<
  typeof useUpdateToolMutation
>;
export type UpdateToolMutationResult =
  Apollo.MutationResult<UpdateToolMutation>;
export type UpdateToolMutationOptions = Apollo.BaseMutationOptions<
  UpdateToolMutation,
  UpdateToolMutationVariables
>;
export const GetToolDocument = gql`
  query getTool($id: uuid!) {
    Tool_by_pk(id: $id) {
      id
      name
      pre_heat_time
    }
  }
`;

/**
 * __useGetToolQuery__
 *
 * To run a query within a React component, call `useGetToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetToolQuery(
  baseOptions: Apollo.QueryHookOptions<GetToolQuery, GetToolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetToolQuery, GetToolQueryVariables>(
    GetToolDocument,
    options
  );
}
export function useGetToolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetToolQuery, GetToolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetToolQuery, GetToolQueryVariables>(
    GetToolDocument,
    options
  );
}
export type GetToolQueryHookResult = ReturnType<typeof useGetToolQuery>;
export type GetToolLazyQueryHookResult = ReturnType<typeof useGetToolLazyQuery>;
export type GetToolQueryResult = Apollo.QueryResult<
  GetToolQuery,
  GetToolQueryVariables
>;
export const GetToolsDocument = gql`
  query getTools {
    Tool {
      id
      name
    }
  }
`;

/**
 * __useGetToolsQuery__
 *
 * To run a query within a React component, call `useGetToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetToolsQuery, GetToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetToolsQuery, GetToolsQueryVariables>(
    GetToolsDocument,
    options
  );
}
export function useGetToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolsQuery,
    GetToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetToolsQuery, GetToolsQueryVariables>(
    GetToolsDocument,
    options
  );
}
export type GetToolsQueryHookResult = ReturnType<typeof useGetToolsQuery>;
export type GetToolsLazyQueryHookResult = ReturnType<
  typeof useGetToolsLazyQuery
>;
export type GetToolsQueryResult = Apollo.QueryResult<
  GetToolsQuery,
  GetToolsQueryVariables
>;
export const SearchToolsDocument = gql`
  query SearchTools($name: String, $limit: Int = 10) {
    Tool(where: { name: { _iregex: $name } }, limit: $limit) {
      id
      name
    }
  }
`;

/**
 * __useSearchToolsQuery__
 *
 * To run a query within a React component, call `useSearchToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchToolsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchToolsQuery,
    SearchToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchToolsQuery, SearchToolsQueryVariables>(
    SearchToolsDocument,
    options
  );
}
export function useSearchToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchToolsQuery,
    SearchToolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchToolsQuery, SearchToolsQueryVariables>(
    SearchToolsDocument,
    options
  );
}
export type SearchToolsQueryHookResult = ReturnType<typeof useSearchToolsQuery>;
export type SearchToolsLazyQueryHookResult = ReturnType<
  typeof useSearchToolsLazyQuery
>;
export type SearchToolsQueryResult = Apollo.QueryResult<
  SearchToolsQuery,
  SearchToolsQueryVariables
>;
