import { PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";

export default function Home() {
  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Home"
        subTitle="Everyone needs one 🤗"
      />
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        Welcome !
      </Content>
    </>
  );
}
