import { useNavigate, useParams } from "react-router-dom";
import {
  GetTechnicDocument,
  GetTechnicsDocument,
  useGetTechnicQuery,
  useUpdateTechnicMutation,
} from "../generated/graphql";
import TechnicForm from "./components/TechnicForm";

export default function EditTechnic() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, data } = useGetTechnicQuery({ variables: { id } });
  const [updateTechnic, { loading: updateLoading }] = useUpdateTechnicMutation({
    onError: (err) => {},
    refetchQueries: [
      { query: GetTechnicsDocument },
      { query: GetTechnicDocument, variables: { id } },
    ],
  });

  const handleOnSave = async (name: string, type: string) => {
    const { data } = await updateTechnic({
      variables: { id, name, type },
    });

    if (data?.update_Technic?.affected_rows) {
      navigate(-1);
    }
  };

  if (loading || !data?.Technic_by_pk) {
    return <div>Loading technic</div>;
  }

  return (
    <TechnicForm
      technic={data.Technic_by_pk}
      loading={updateLoading}
      onSave={handleOnSave}
    />
  );
}
