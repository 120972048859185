import { Button, Input, PageHeader, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { GetTechnicsQuery, useGetTechnicsQuery } from "../generated/graphql";
import AddTechnic from "./AddTechnic";
import EditTechnic from "./EditTechnic";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import Table from "../components/NavAwareTable";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate("/")}
        title="Technics"
        subTitle="It's not that complicated"
        extra={[
          <Button key="1" type="primary" onClick={() => navigate("add")}>
            Add Technic
          </Button>,
        ]}
      />
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Space
          size="large"
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          {children}
        </Space>
      </Content>
    </>
  );
};

const columns: ColumnsType<GetTechnicsQuery["Technic"][0]> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (_, record) => (
      <div>
        {record.type === "cook" ? "🔥" : "🥒"} {record.type}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => <Link to={`edit/${record.id}`}>Edit</Link>,
  },
];

export default function Technics() {
  const { loading, error, data } = useGetTechnicsQuery();
  const [nameFilter, setNameFilter] = useState("");

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return <PageLayout>{`Error : ${error}`}</PageLayout>;
  }

  const dataSource = data?.Technic.map((technic) => ({
    key: `${data.Technic.indexOf(technic) + 1}`,
    ...technic,
  })).filter(
    (technic) =>
      !nameFilter ||
      technic.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Input
              placeholder="Filter by name"
              autoFocus
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Table dataSource={dataSource} columns={columns} />
          </PageLayout>
        }
      />
      <Route path="add" element={<AddTechnic />} />
      <Route path="edit/:id" element={<EditTechnic />} />
    </Routes>
  );
}
