import { Button, Form, Input, Select, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  GetDishesDocument,
  useAddDishMutation,
  useGetDishCategoriesQuery,
} from "../generated/graphql";

const { Option } = Select;

export default function AddDish() {
  const navigate = useNavigate();
  const { data: dishCategories } = useGetDishCategoriesQuery();
  const [addDish, { data, loading, error }] = useAddDishMutation({
    onError: (err) => {},
    refetchQueries: [{ query: GetDishesDocument }],
  });
  const onFinish = ({ name, kind, category_id }: any) => {
    addDish({ variables: { id: uuidv4(), name, kind, category_id } });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.insert_Dish_one) {
      navigate(-1);
    }
  }, [data, navigate]);

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input a name for the dish !" },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="kind"
        label="Kind"
        rules={[
          { required: true, message: "Please select a kind for the dish !" },
        ]}
      >
        <Select placeholder="Select a kind for this dish">
          <Option value="main">Main</Option>
          <Option value="side">Side</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="category_id"
        label="Category"
        rules={[
          {
            required: true,
            message: "Please select a category for the dish !",
          },
        ]}
      >
        <Select placeholder="Select a category for this dish">
          {dishCategories?.DishCategory.map((dishCategory) => (
            <Option value={dishCategory.id}>{dishCategory.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add
        </Button>
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}
