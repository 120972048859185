import { Table as AntTable } from "antd";
import { TableProps } from "antd/es/table";
import { useSearchParams } from "react-router-dom";

export default function Table<RecordType extends object = any>(
  props: TableProps<RecordType>
) {
  let [searchParams, setSearchParams] = useSearchParams();
  let current = searchParams.has("page")
    ? parseInt(searchParams.get("page")!)
    : undefined;

  return (
    <AntTable
      {...props}
      pagination={{
        ...props.pagination,
        current,
        onChange: (page, _) =>
          setSearchParams({ page: `${page}` }, { replace: true }),
      }}
    />
  );
}
