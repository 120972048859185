import { Button, Modal } from "antd";
import { useCallback, useState } from "react";

import {
  GetMainPrepsDocument,
  GetPrepDocument,
  GetPrepsDocument,
  Prep_Ingredients,
  useAddPrepIngredientsMutation,
  useDeletePrepIngredientsMutation,
  useUpdatePrepIngredientsMutation,
} from "../../generated/graphql";
import IngredientsPicker, { PickedIngredients } from "./IngredientsPicker";

type IngredientWithQuantity = {
  quantity: number;
  Ingredient: Item;
};

type Item = {
  id: string;
};

const getItemDiff = (
  initialItems: IngredientWithQuantity[] = [],
  newItems: IngredientWithQuantity[] = []
) => {
  return [
    newItems?.filter(
      (item) =>
        !initialItems
          .map((item) => item.Ingredient.id)
          .includes(item.Ingredient.id)
    ),
    newItems?.filter((item) => {
      const initItem = initialItems.find(
        (initialItem) => initialItem.Ingredient.id === item.Ingredient.id
      );

      return initItem && initItem.quantity !== item.quantity;
    }),
    initialItems.filter(
      (item) =>
        !newItems.map((item) => item.Ingredient.id).includes(item.Ingredient.id)
    ),
  ];
};

type IngredientsModalProps = {
  prepId: string;
  initialIngredients?: Prep_Ingredients[];
};

export default function IngredientsModal({
  prepId,
  initialIngredients,
}: IngredientsModalProps) {
  const [open, setOpen] = useState(false);
  const [ingredients, setIngredients] = useState<
    PickedIngredients | undefined
  >();

  const refetchQueries = [
    { query: GetPrepsDocument },
    { query: GetMainPrepsDocument },
    { query: GetPrepDocument, variables: { id: prepId } },
  ];

  const [addPrepIngredients, { loading: addLoading }] =
    useAddPrepIngredientsMutation({
      refetchQueries,
    });
  const [updatePrepIngredients, { loading: updateLoading }] =
    useUpdatePrepIngredientsMutation({ refetchQueries });
  const [deletePrepIngredients, { loading: deleteLoading }] =
    useDeletePrepIngredientsMutation({
      refetchQueries,
    });

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    const [addedIngredients, updatedIngredients, deletedIngredients] =
      getItemDiff(initialIngredients, ingredients);

    if (addedIngredients.length) {
      await addPrepIngredients({
        variables: {
          ingredients: addedIngredients?.map((ingredient) => ({
            ingredient_id: ingredient.Ingredient.id,
            prep_id: prepId,
            quantity: ingredient.quantity,
          })),
        },
      });
    }

    if (updatedIngredients.length) {
      await updatePrepIngredients({
        variables: {
          ingredients: updatedIngredients.map((ingredient) => ({
            where: {
              prep_id: { _eq: prepId },
              ingredient_id: { _eq: ingredient.Ingredient.id },
            },
            _set: { quantity: ingredient.quantity },
          })),
        },
      });
    }

    if (deletedIngredients.length) {
      await deletePrepIngredients({
        variables: {
          where: {
            _and: [
              { prep_id: { _eq: prepId } },
              {
                ingredient_id: {
                  _in: deletedIngredients.map(
                    (ingredient) => ingredient.Ingredient.id
                  ),
                },
              },
            ],
          },
        },
      });
    }

    setOpen(false);
  };

  const handleIngredientsUpdated = useCallback(
    (ingredients?: PickedIngredients) => {
      setIngredients(ingredients);
    },
    []
  );

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit ingredients
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Edit prep ingredients"
        open={open}
        onOk={handleOk}
        confirmLoading={addLoading || updateLoading || deleteLoading}
        onCancel={handleCancel}
      >
        <IngredientsPicker
          initialIngredients={initialIngredients}
          onPickedIngredientsUpdated={handleIngredientsUpdated}
        />
      </Modal>
    </>
  );
}
