import { Button, Form, Input, Select, message } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetDishDocument,
  useGetDishCategoriesQuery,
  useGetDishQuery,
  useUpdateDishMutation,
} from "../generated/graphql";

const { Option } = Select;

export default function EditDish() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, data } = useGetDishQuery({ variables: { id } });
  const { data: dishCategories } = useGetDishCategoriesQuery();
  const [updateDish, { data: updateData, error: updateError }] =
    useUpdateDishMutation({
      onError: (err) => {},
      refetchQueries: [{ query: GetDishDocument, variables: { id } }],
    });
  const onFinish = ({ name, kind, category_id }: any) => {
    updateDish({ variables: { id, name, kind, category_id } });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (updateError) {
      console.error(updateError);
      message.error(updateError.message);
    }
  }, [updateError]);

  useEffect(() => {
    if (updateData?.update_Dish?.affected_rows) {
      navigate(-1);
    }
  }, [updateData, navigate]);

  if (loading) {
    return <>Loading</>;
  }

  if (error) {
    return <>{`Error : ${error}`}</>;
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={data?.Dish_by_pk?.name}
        rules={[
          { required: true, message: "Please input a name for the dish !" },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="kind"
        label="Kind"
        initialValue={data?.Dish_by_pk?.kind}
        rules={[
          { required: true, message: "Please select a kind for the dish !" },
        ]}
      >
        <Select placeholder="Select a kind for this dish">
          <Option value="main">Main</Option>
          <Option value="side">Side</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="category_id"
        label="Category"
        initialValue={data?.Dish_by_pk?.DishCategory?.id}
        rules={[
          {
            required: true,
            message: "Please select a category for the dish !",
          },
        ]}
      >
        <Select placeholder="Select a category for this dish">
          {dishCategories?.DishCategory.map((dishCategory) => (
            <Option value={dishCategory.id}>{dishCategory.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}
