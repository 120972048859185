import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Routes, Route, Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Ingredients from "./Ingredients";
import Home from "./Home/Home";
import "./App.css";
import { Layout, Menu, MenuProps } from "antd";
import Sider from "antd/lib/layout/Sider";
import Tools from "./Tools";
import Preps from "./Preps";
import Technics from "./Technics";
import Recipes from "./Recipes";
import Dishes from "./Dishes";
import { auth, useCurrentUser } from "./components/auth/AuthGuard";

const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URI });
const authLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser?.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-hasura-role": "admin",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const menuItems: MenuProps["items"] = [
  { key: 1, label: <Link to="ingredients">Ingredients</Link> },
  { key: 2, label: <Link to="tools">Tools</Link> },
  { key: 3, label: <Link to="technics">Technics</Link> },
  { key: 4, label: <Link to="preps">Preps</Link> },
  { key: 5, label: <Link to="dishes">Dishes</Link> },
];

function App() {
  const { user, signOut } = useCurrentUser();

  return (
    <ApolloProvider client={client}>
      <DndProvider backend={HTML5Backend}>
        <Layout style={{ minHeight: "100vh" }} hasSider>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div className="logo">Chopchop-cms</div>
            <Menu items={menuItems} theme="dark" mode="inline" />
            <div className="user">
              <span>{user!.email}</span>&nbsp;
              <button onClick={signOut}>Signout</button>
            </div>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/ingredients/*" element={<Ingredients />} />
              <Route path="/tools/*" element={<Tools />} />
              <Route path="/technics/*" element={<Technics />} />
              <Route path="/preps/*" element={<Preps />} />
              <Route path="/recipes/*" element={<Recipes />} />
              <Route path="/dishes/*" element={<Dishes />} />
            </Routes>
          </Layout>
        </Layout>
      </DndProvider>
    </ApolloProvider>
  );
}

export default App;
