import { useCallback, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "antd";
import {
  GetIngredientsDocument,
  Prep_Ingredients,
  SearchIngredientDocument,
  SearchIngredientQuery,
  useAddIngredientMutation,
  useSearchIngredientQuery,
} from "../../generated/graphql";

import QuantifiedItemPicker, {
  QuantifiedItem,
} from "../../components/QuantifiedItemPicker";
import IngredientForm from "../../Ingredients/components/IngredientForm";

export type PickedIngredients = Array<{
  quantity: number;
  Ingredient: SearchIngredientQuery["Ingredient"][0];
}>;

type CreateIngredientModalProps = {
  name: string;
  onCreated: (ingredientId: string) => void;
};

function CreateIngredientModal({
  name,
  onCreated,
}: CreateIngredientModalProps) {
  const [open, setOpen] = useState(false);
  const [addIngredient, { loading }] = useAddIngredientMutation({
    refetchQueries: [
      { query: GetIngredientsDocument },
      { query: SearchIngredientDocument, variables: { name } },
    ],
  });

  const handleSave = async (
    name: string,
    type: string,
    measureUnit: string,
    consistency: string,
    canSplit: boolean,
    avgItemQuantity: number | null,
    ambientTempTime: number | null
  ) => {
    const result = await addIngredient({
      variables: {
        id: uuidv4(),
        name,
        type,
        consistency,
        measure_unit: measureUnit,
        can_split: canSplit,
        avg_item_quantity: avgItemQuantity,
        ambient_temp_time: ambientTempTime,
      },
    });

    onCreated(result.data?.insert_Ingredient_one?.id);
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Create ingredient {name}
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Create ingredient"
        open={open}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            form="IngredientForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Create
          </Button>,
        ]}
      >
        <IngredientForm
          ingredient={{ name }}
          loading={loading}
          showButtons={false}
          onSave={handleSave}
        />
      </Modal>
    </>
  );
}

type IngredientPickerProps = {
  initialIngredients?: Prep_Ingredients[];
  onPickedIngredientsUpdated: (ingredients?: PickedIngredients) => void;
};

export default function IngredientPicker({
  initialIngredients,
  onPickedIngredientsUpdated,
}: IngredientPickerProps) {
  const [name, setName] = useState("");

  const { data } = useSearchIngredientQuery({
    variables: { name },
  });

  const handleFilterChange = (filter: string) => setName(filter);

  const handleSelectedItemsUpdate = useCallback(
    (selectedItems?: QuantifiedItem[]) => {
      onPickedIngredientsUpdated(
        selectedItems?.map(({ item, quantity }) => ({
          quantity,
          Ingredient: item,
        }))
      );
    },
    [onPickedIngredientsUpdated]
  );

  return (
    <div className="IngredientsPicker">
      <QuantifiedItemPicker
        items={data?.Ingredient}
        selectedItems={initialIngredients?.map((prepIngredient) => ({
          item: prepIngredient.Ingredient,
          quantity: prepIngredient.quantity,
        }))}
        onSelectedItemsUpdated={handleSelectedItemsUpdate}
        onItemFilterUpdated={handleFilterChange}
      />
      {!data?.Ingredient.length && (
        <CreateIngredientModal name={name} onCreated={() => {}} />
      )}
    </div>
  );
}
