import React, { useState } from "react";
import { Button, Select, Spin } from "antd";
import type { DefaultOptionType, SelectProps } from "antd/es/select";
import { useDebouncedCallback } from "use-debounce";
import { PlusOutlined } from "@ant-design/icons";

export interface SearchSelectProps
  extends Omit<SelectProps, "options" | "children"> {
  fetchOptions: (search: string) => Promise<DefaultOptionType[]>;
  addItem?: (itemValue: string) => void;
}

export default function SearchSelect({
  fetchOptions,
  addItem,
  ...props
}: SearchSelectProps) {
  const [searchValue, setSearchValue] = useState<string>();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const searchHandler = useDebouncedCallback(async (value: string) => {
    setSearchValue(value);
    if (!value) return;

    setOptions([]);
    setFetching(true);

    const newOptions = await fetchOptions(value);

    setFetching(false);
    setOptions(newOptions);
  }, 200);

  return (
    <Select
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      onSearch={searchHandler}
      onClear={() => setOptions([])}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : searchValue && addItem ? (
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => addItem(searchValue)}
          >
            Add {searchValue}
          </Button>
        ) : null
      }
      {...props}
      options={options}
    />
  );
}
