import { Input, PageHeader, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import { GetPrepsQuery, useGetPrepsQuery } from "../generated/graphql";
import ViewPrep from "./ViewPrep";
import { ColumnsType } from "antd/lib/table";
import EditPrep from "./EditPrep";
import AddPrepModal from "./components/AddPrepModal";
import { useState } from "react";
import { differenceInDays, format, formatDistance } from "date-fns";
import ViewFlatPrep from "./ViewFlatPrep";
import Table from "../components/NavAwareTable";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate("/")}
        title="Preps"
        subTitle="Be ready !"
        extra={
          <AddPrepModal onCreated={(id) => navigate(`/preps/view/${id}`)} />
        }
      />
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Space
          size="large"
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          {children}
        </Space>
      </Content>
    </>
  );
};

const columns: ColumnsType<GetPrepsQuery["Prep"][0]> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Last updated",
    dataIndex: "updated_at",
    key: "updated_at",
    width: "30%",
    align: "right",
    render: (record) => {
      const updatedAt = new Date(`${record}Z`);
      if (differenceInDays(Date.now(), updatedAt) <= 1) {
        return (
          <div>
            {formatDistance(updatedAt, Date.now(), { addSuffix: true })}
          </div>
        );
      }
      return <div>{format(updatedAt, "dd/MM/yyyy")}</div>;
    },
  },
];

export default function Preps() {
  const navigate = useNavigate();
  const { loading, error, data } = useGetPrepsQuery();
  const [nameFilter, setNameFilter] = useState("");

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return <PageLayout>{`Error : ${error}`}</PageLayout>;
  }

  const dataSource = data?.Prep.map((prep) => ({
    key: `${data.Prep.indexOf(prep) + 1}`,
    ...prep,
  })).filter(
    (prep) =>
      !nameFilter || prep.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Input
              placeholder="Filter by name"
              autoFocus
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize: 25 }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate(`view/${record.id}`);
                  },
                };
              }}
            />
          </PageLayout>
        }
      />
      <Route path="edit/:id" element={<EditPrep />} />
      <Route path="view/:id" element={<ViewPrep />} />
      <Route path="view-flat/:id" element={<ViewFlatPrep />} />
    </Routes>
  );
}
