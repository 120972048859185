import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Ingredient } from "../../generated/graphql";
import MeasureSelect from "./MeasureSelect";
import TimeInput from "../../components/TimeInput";
import TypeSelect from "./TypeSelect";
import ConsistencySelect from "./ConsitencySelect";

type IngredientFormProps = {
  ingredient?: Partial<Ingredient>;
  loading: boolean;
  showButtons?: boolean;
  onSave: (
    name: string,
    type: string,
    measureUnit: string,
    consistency: string,
    canSplit: boolean,
    avgItemQuantity: number | null,
    ambientTempTime: number | null
  ) => void;
};

export default function IngredientForm({
  ingredient,
  loading,
  showButtons = true,
  onSave,
}: IngredientFormProps) {
  const navigate = useNavigate();
  const onFinish = ({
    name,
    type,
    measure_unit,
    consistency,
    can_split,
    avg_item_quantity,
    ambient_temp_time,
  }: any) => {
    console.log({
      name,
      type,
      measure_unit,
      consistency,
      can_split,
      avg_item_quantity,
      ambient_temp_time,
    });
    onSave(
      name,
      type,
      measure_unit,
      consistency,
      can_split,
      Boolean(avg_item_quantity) ? avg_item_quantity : null,
      Boolean(ambient_temp_time) ? ambient_temp_time : null
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      id="IngredientForm"
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={ingredient?.name}
        rules={[{ required: true }]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Type"
        name="type"
        initialValue={ingredient?.type}
        rules={[{ required: true }]}
      >
        <TypeSelect />
      </Form.Item>

      <Form.Item
        label="Consistency"
        name="consistency"
        initialValue={ingredient?.consistency}
        rules={[{ required: true }]}
      >
        <ConsistencySelect />
      </Form.Item>

      <Form.Item
        label="Measure unit"
        name="measure_unit"
        initialValue={ingredient?.measure_unit}
        rules={[{ required: true }]}
      >
        <MeasureSelect />
      </Form.Item>

      <Form.Item
        label="Average quantity"
        name="avg_item_quantity"
        initialValue={ingredient?.avg_item_quantity}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Can split"
        name="can_split"
        initialValue={ingredient?.can_split}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        label="Ambient temp rest time"
        name="ambient_temp_time"
        initialValue={ingredient?.ambient_temp_time}
      >
        <TimeInput />
      </Form.Item>

      {showButtons && (
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {!!ingredient ? "Update" : "Add"}
          </Button>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
        </Form.Item>
      )}
    </Form>
  );
}
