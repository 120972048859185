import { useCallback, useState } from "react";
import {
  Prep_Preps,
  SearchPrepsQuery,
  useSearchPrepsQuery,
} from "../../generated/graphql";

import QuantifiedItemPicker, {
  QuantifiedItem,
} from "../../components/QuantifiedItemPicker";
import AddPrepModal from "./AddPrepModal";

export type PickedPreps = Array<{
  quantity: number;
  Prep: SearchPrepsQuery["Prep"][0];
}>;

type PrepPickerProps = {
  initialPreps?: Prep_Preps[];
  onPickedPrepsUpdated: (preps?: PickedPreps) => void;
};

export default function PrepPicker({
  initialPreps,
  onPickedPrepsUpdated,
}: PrepPickerProps) {
  const [name, setName] = useState("");

  const { data, refetch } = useSearchPrepsQuery({
    variables: { name },
    fetchPolicy: "no-cache",
  });

  const handleFilterChange = (filter: string) => setName(filter);

  const handleSelectedItemsUpdate = useCallback(
    (selectedItems?: QuantifiedItem[]) => {
      onPickedPrepsUpdated(
        selectedItems?.map(({ item, quantity }) => ({
          quantity,
          Prep: item,
        }))
      );
    },
    [onPickedPrepsUpdated]
  );

  return (
    <div className="PrepsPicker">
      <QuantifiedItemPicker
        items={data?.Prep}
        selectedItems={initialPreps?.map((prepPrep) => ({
          item: prepPrep.Prep,
          quantity: prepPrep.quantity,
        }))}
        onSelectedItemsUpdated={handleSelectedItemsUpdate}
        onItemFilterUpdated={handleFilterChange}
      />
      {!data?.Prep.length && (
        <AddPrepModal name={name} onCreated={(_) => refetch()} />
      )}
    </div>
  );
}
