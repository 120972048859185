import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  GetIngredientsDocument,
  SearchIngredientDocument,
  useAddIngredientMutation,
} from "../generated/graphql";
import IngredientForm from "./components/IngredientForm";

export default function AddIngredient() {
  const navigate = useNavigate();
  const [addIngredient, { data, loading, error }] = useAddIngredientMutation({
    refetchQueries: [
      { query: GetIngredientsDocument },
      { query: SearchIngredientDocument, variables: { name: "" } },
    ],
  });

  const onFinish = (
    name: string,
    type: string,
    measureUnit: string,
    consistency: string,
    canSplit: boolean,
    avgItemQuantity: number | null,
    ambientTempTime: number | null
  ) => {
    addIngredient({
      variables: {
        id: uuidv4(),
        name,
        type,
        consistency,
        measure_unit: measureUnit,
        can_split: canSplit,
        avg_item_quantity: avgItemQuantity,
        ambient_temp_time: ambientTempTime,
      },
    });
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.insert_Ingredient_one) {
      navigate(-1);
    }
  }, [data, navigate]);

  return <IngredientForm loading={loading} onSave={onFinish} />;
}
