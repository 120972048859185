import { Select, SelectProps } from "antd";
import { useGetMainPrepsQuery } from "../../generated/graphql";

export default function MainPrepsSelect(props: SelectProps) {
  const { loading, error, data } = useGetMainPrepsQuery();

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <Select {...props} placeholder="Select a main prep" allowClear>
      {data?.Prep.map((prep) => (
        <Select.Option key={prep.id} value={prep.id}>
          {prep.name}
        </Select.Option>
      ))}
    </Select>
  );
}
