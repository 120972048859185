import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { GetRecipesDocument, useAddRecipeMutation } from "../generated/graphql";

export default function AddRecipe() {
  const navigate = useNavigate();
  const [addRecipe, { data, loading, error }] = useAddRecipeMutation({
    onError: (err) => {},
    refetchQueries: [{ query: GetRecipesDocument }],
  });
  const onFinish = ({ name }: any) => {
    addRecipe({ variables: { recipe: { id: uuidv4(), name } } });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.insert_Recipe_one) {
      navigate(-1);
    }
  }, [data, navigate]);

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input a name for the recipe !" },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add
        </Button>
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}
