import { Select, SelectProps } from "antd";

export default function MeasureSelect(props: SelectProps) {
  return (
    <Select {...props} placeholder="Select a unit">
      <Select.Option key="gram" value="gram">
        Gram
      </Select.Option>
      <Select.Option key="milliliter" value="milliliter">
        Milliliter
      </Select.Option>
    </Select>
  );
}
