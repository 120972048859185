import { Button, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import TechnicSelect from "./TechnicSelect";
import DishSelect from "./DishSelect";
import { v4 as uuidv4 } from "uuid";
import {
  Dish,
  GetMainPrepsDocument,
  GetPrepsDocument,
  SearchPrepsDocument,
  useAddPrepMutation,
} from "../../generated/graphql";
import TextArea from "antd/lib/input/TextArea";
import MeasureSelect from "../../Ingredients/components/MeasureSelect";
import TimeInput from "../../components/TimeInput";

type AddPrepModalProps = {
  name?: string;
  dish?: Partial<Dish>;
  triggerComponent?: (onClick: () => void) => React.ReactNode;
  onCreated: (prepId: string) => void;
};

export default function AddPrepModal({
  name,
  dish,
  triggerComponent,
  onCreated,
}: AddPrepModalProps) {
  const [open, setOpen] = useState(false);
  const [addPrep, { loading }] = useAddPrepMutation({
    refetchQueries: [
      { query: GetPrepsDocument },
      { query: GetMainPrepsDocument },
      { query: SearchPrepsDocument, variables: { name: "" } },
    ],
  });

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFinish = async ({
    name,
    description,
    time_ahead,
    measure_unit,
    serving_size,
    duration,
    rest_time,
    rest_conditions,
    technic,
    dish,
  }: any) => {
    const id = uuidv4();
    const prep = await addPrep({
      variables: {
        id,
        name,
        description,
        time_ahead,
        measure_unit,
        serving_size,
        duration,
        rest_time,
        rest_conditions,
        technic_id: technic ? technic.value : undefined,
        dish_id: dish ? dish.value : undefined,
      },
    });

    if (prep.errors) {
      return message.error(prep.errors.toString());
    }

    onCreated(id);
    setOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Some needed fields are missing");
  };

  return (
    <>
      {triggerComponent ? (
        triggerComponent(showModal)
      ) : (
        <Button type="primary" onClick={showModal}>
          Add Prep
        </Button>
      )}
      <Modal
        title="Add new Prep"
        open={open}
        confirmLoading={loading}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            form="AddPrepForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="AddPrepForm"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            initialValue={name}
            rules={[
              { required: true, message: "Please input a name for the prep !" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false }]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            label="Time ahead"
            name="time_ahead"
            rules={[{ required: false }]}
          >
            <TimeInput />
          </Form.Item>

          <Form.Item
            label="Measure unit"
            name="measure_unit"
            rules={[{ required: true }]}
          >
            <MeasureSelect />
          </Form.Item>

          <Form.Item
            label="Serving size"
            name="serving_size"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Duration"
            name="duration"
            rules={[{ required: true }]}
          >
            <TimeInput />
          </Form.Item>

          <Form.Item label="Rest time" initialValue={0} name="rest_time">
            <TimeInput />
          </Form.Item>

          <Form.Item label="Rest conditions" name="rest_conditions">
            <Input />
          </Form.Item>

          <Form.Item
            label="Technic"
            name="technic"
            rules={[{ required: false }]}
          >
            <TechnicSelect />
          </Form.Item>

          <Form.Item
            label="Dish"
            name="dish"
            initialValue={
              dish ? { label: dish.name, value: dish.id } : undefined
            }
            rules={[{ required: false }]}
          >
            <DishSelect />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
