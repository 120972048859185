import { SelectProps } from "antd";
import { useSearchDishesLazyQuery } from "../../generated/graphql";
import SearchSelect from "../../components/SelectSearch";

export default function DishSelect(props: SelectProps) {
  const [searchDishes] = useSearchDishesLazyQuery();

  const handleOptionsFetching = async (value: string) => {
    const { data: dishes } = await searchDishes({
      variables: { name: value },
    });

    return (
      dishes?.Dish.map((dish) => ({
        label: dish.name,
        value: dish.id,
      })) || []
    );
  };

  return (
    <SearchSelect
      {...props}
      placeholder="Select a dish"
      fetchOptions={handleOptionsFetching}
    />
  );
}
