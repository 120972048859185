import { Button, Input, Modal } from "antd";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  GetMainPrepsDocument,
  GetPrepDocument,
  GetPrepsDocument,
  PrepStep,
  useAddPrepStepsMutation,
  useDeletePrepStepsMutation,
  useUpdatePrepStepsMutation,
} from "../../generated/graphql";
import TextArea from "antd/lib/input/TextArea";
import {
  CloseCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import update from "immutability-helper";

type StepProps = {
  step: PrepStep;
  isFirst: boolean;
  isLast: boolean;
  onStepUpdate: (step: PrepStep) => void;
  onStepOrderChange: (direction: number) => void;
  onStepDelete: () => void;
};

function Step({
  step,
  isFirst,
  isLast,
  onStepUpdate,
  onStepOrderChange,
  onStepDelete,
}: StepProps) {
  return (
    <div>
      <div>{step.order}</div>
      <div>
        <Input
          value={step.name}
          onChange={(event) =>
            onStepUpdate({ ...step, name: event.currentTarget.value })
          }
        />
        <TextArea
          value={step.description}
          onChange={(event) =>
            onStepUpdate({ ...step, description: event.currentTarget.value })
          }
        />
      </div>
      <Button
        onClick={onStepDelete}
        shape="circle"
        icon={<CloseCircleOutlined />}
      />
      <Button
        onClick={() => onStepOrderChange(-1)}
        disabled={isFirst}
        shape="circle"
        icon={<CaretUpOutlined />}
      />
      <Button
        onClick={() => onStepOrderChange(1)}
        disabled={isLast}
        shape="circle"
        icon={<CaretDownOutlined />}
      />
    </div>
  );
}

const getStepsDiff = (initialSteps: PrepStep[] = [], steps: PrepStep[]) => {
  return [
    steps.filter(
      (step) => !initialSteps.map((initStep) => initStep.id).includes(step.id)
    ),
    steps.filter((step) => {
      const initStep = initialSteps.find((initStep) => initStep.id === step.id);
      return (
        initStep &&
        (initStep.name !== step.name ||
          initStep.description !== step.description ||
          initStep.order !== step.order)
      );
    }),
    initialSteps.filter(
      (initStep) => !steps.map((step) => step.id).includes(initStep.id)
    ),
  ];
};

type StepsModalProps = {
  prepId: string;
  initialSteps?: PrepStep[];
};

export default function StepsModal({
  initialSteps = [],
  prepId,
}: StepsModalProps) {
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState(initialSteps);
  const refetchQueries = [
    { query: GetPrepsDocument },
    { query: GetMainPrepsDocument },
    { query: GetPrepDocument, variables: { id: prepId } },
  ];

  const [addPrepSteps] = useAddPrepStepsMutation({ refetchQueries });
  const [updatePrepSteps] = useUpdatePrepStepsMutation({ refetchQueries });
  const [deletePrepSteps] = useDeletePrepStepsMutation({ refetchQueries });

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    const [addSteps, updateSteps, deleteSteps] = getStepsDiff(
      initialSteps,
      steps
    );

    if (addSteps) {
      await addPrepSteps({
        variables: {
          steps: addSteps.map((step) => ({
            id: step.id,
            name: step.name,
            description: step.description,
            order: step.order,
            prep_id: prepId,
          })),
        },
      });
    }

    if (updateSteps) {
      await updatePrepSteps({
        variables: {
          steps: updateSteps.map((step) => ({
            _set: {
              name: step.name,
              description: step.description,
              order: step.order,
            },
            where: { id: { _eq: step.id } },
          })),
        },
      });
    }

    if (deleteSteps) {
      await deletePrepSteps({
        variables: { step_ids: deleteSteps.map((step) => step.id) },
      });
    }

    setOpen(false);
  };

  const handleCancel = () => {
    setSteps(initialSteps);
    setOpen(false);
  };

  const handleAddStep = () => {
    setSteps([
      ...steps,
      {
        id: uuidv4(),
        name: "",
        description: "",
        order: steps.length,
        prep_id: prepId,
      },
    ]);
  };

  const handleStepUpdate = (step: PrepStep, idx: number) => {
    setSteps(update(steps, { [idx]: { $set: step } }));
  };

  const handleStepOrderChange = (idx: number, direction: number) => {
    setSteps(
      update(steps, {
        [idx]: { order: { $set: steps[idx].order + direction } },
        [idx + direction]: {
          order: { $set: steps[idx + direction].order - direction },
        },
      }).sort((step1, step2) => step1.order - step2.order)
    );
  };

  const handleStepDelete = (idx: number) => {
    setSteps([...steps.slice(0, idx), ...steps.slice(idx + 1, steps.length)]);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit steps
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Edit prep steps"
        open={open}
        onOk={handleOk}
        confirmLoading={false}
        onCancel={handleCancel}
      >
        {steps.map((step, idx) => (
          <Step
            key={step.id}
            step={step}
            isFirst={step.order === 0}
            isLast={step.order === steps.length - 1}
            onStepUpdate={(step) => handleStepUpdate(step, idx)}
            onStepOrderChange={(direction) =>
              handleStepOrderChange(idx, direction)
            }
            onStepDelete={() => handleStepDelete(idx)}
          />
        ))}
        <Button onClick={handleAddStep}>AddStep</Button>
      </Modal>
    </>
  );
}
