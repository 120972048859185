import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  GetTechnicsDocument,
  useAddTechnicMutation,
} from "../generated/graphql";
import TechnicForm from "./components/TechnicForm";

export default function AddTechnic() {
  const navigate = useNavigate();
  const [addTechnic, { loading }] = useAddTechnicMutation({
    refetchQueries: [{ query: GetTechnicsDocument }],
  });

  const handleOnSave = async (name: string, type: string) => {
    const { data } = await addTechnic({
      variables: { id: uuidv4(), name, type },
    });

    if (data?.insert_Technic_one) {
      navigate(-1);
    }
  };

  return <TechnicForm loading={loading} onSave={handleOnSave} />;
}
