import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";

const { Option } = Select;

type Unit = "seconds" | "minutes" | "hours" | "days";

interface TimeInputProps {
  value?: number | null;
  onChange?: (value: number) => void;
}

const getTimeInMinutes = (number: number, unit: Unit) => {
  if (unit === "seconds") return number;
  if (unit === "minutes") return number * 60;
  if (unit === "hours") return number * 3600;
  return number * 3600 * 24;
};

export const getHumanFriendlyTime = (time: number) => {
  if (time === 0) {
    return "None";
  }

  if (time >= 3600 * 24) {
    const days = time / (3600 * 24);
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (time >= 3600) {
    const hours = time / 3600;
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  } else if (time >= 60) {
    const minutes = time / 60;
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  return `${time} second${time > 1 ? "s" : ""}`;
};

export default function TimeInput({ value, onChange }: TimeInputProps) {
  const [number, setNumber] = useState(0);
  const [unit, setUnit] = useState<Unit>("seconds");

  useEffect(() => {
    if (typeof value !== "number") return;

    if (value >= 3600 * 24) {
      setUnit("days");
      setNumber(value / (3600 * 24));
    } else if (value >= 3600) {
      setUnit("hours");
      setNumber(value / 3600);
    } else if (value >= 60) {
      setUnit("minutes");
      setNumber(value / 60);
    } else {
      setUnit("seconds");
      setNumber(value);
    }
  }, [value]);

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value);
    setNumber(newNumber);
    onChange?.(getTimeInMinutes(newNumber, unit));
  };

  const onUnitChange = (newUnit: Unit) => {
    setUnit(newUnit);
    onChange?.(getTimeInMinutes(number, newUnit));
  };

  return (
    <span>
      <Input
        type="number"
        value={number}
        onChange={onNumberChange}
        style={{ width: 100 }}
      />
      <Select
        value={unit}
        style={{ width: 200, margin: "0 8px" }}
        onChange={onUnitChange}
      >
        <Option value="seconds">Second{number > 1 ? "s" : ""}</Option>
        <Option value="minutes">Minute{number > 1 ? "s" : ""}</Option>
        <Option value="hours">Hour{number > 1 ? "s" : ""}</Option>
        <Option value="days">Day{number > 1 ? "s" : ""}</Option>
      </Select>
    </span>
  );
}
