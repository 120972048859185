import { Button, Modal, SelectProps } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  GetTechnicsDocument,
  useAddTechnicMutation,
  useSearchTechnicsLazyQuery,
} from "../../generated/graphql";
import SearchSelect from "../../components/SelectSearch";
import { useState } from "react";
import TechnicForm from "../../Technics/components/TechnicForm";
import { DefaultOptionType } from "antd/lib/select";

type TechnicCreateModalProps = {
  name?: string;
  open: boolean;
  onSave: (id: string) => void;
  onCancel: () => void;
};

function TechnicCreateModal({
  open,
  name,
  onSave,
  onCancel,
}: TechnicCreateModalProps) {
  const [addTechnic, { loading }] = useAddTechnicMutation({
    refetchQueries: [{ query: GetTechnicsDocument }],
  });

  const handleSave = async (name: string, type: string) => {
    const id = uuidv4();
    const { data } = await addTechnic({ variables: { id, name, type } });

    if (data?.insert_Technic_one?.id) return onSave(id);
  };

  return (
    <Modal
      title="Add new technic"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="back" onClick={onCancel}>
          Return
        </Button>,
        <Button
          form="TechnicForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          Submit
        </Button>,
      ]}
    >
      <TechnicForm
        technic={{ name }}
        showButtons={false}
        loading={false}
        onSave={handleSave}
      />
    </Modal>
  );
}

export default function TechnicSelect(props: SelectProps) {
  const [searchTechnics] = useSearchTechnicsLazyQuery();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newTechnicName, setNewTechnicName] = useState<string>();
  const [value, setValue] = useState<DefaultOptionType>();

  const handleOptionsFetching = async (value: string) => {
    const { data: technics } = await searchTechnics({
      variables: { name: value },
    });

    return (
      technics?.Technic.map((technic) => ({
        label: technic.name,
        value: technic.id,
      })) || []
    );
  };

  const handleAddingItem = async (value: string) => {
    setNewTechnicName(value);
    setCreateModalOpen(true);
  };

  const handleCreationCancel = () => {
    setNewTechnicName(undefined);
    setCreateModalOpen(false);
  };

  const handleNewItemCreated = (id: string) => {
    setValue({ value: id, label: newTechnicName });
    props.onChange && props.onChange({ value: id, label: newTechnicName }, []);
    handleCreationCancel();
  };

  const handleChange = (
    value: DefaultOptionType,
    options: DefaultOptionType | DefaultOptionType[]
  ) => {
    setValue(value);
    props.onChange && props.onChange(value, options);
  };

  return (
    <>
      <SearchSelect
        {...props}
        value={value || props.value}
        onChange={handleChange}
        placeholder="Select a technic"
        fetchOptions={handleOptionsFetching}
        addItem={handleAddingItem}
      />
      <TechnicCreateModal
        name={newTechnicName}
        open={createModalOpen}
        onSave={handleNewItemCreated}
        onCancel={handleCreationCancel}
      />
    </>
  );
}
