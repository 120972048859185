import { Select, SelectProps } from "antd";

export default function ConsistencySelect(props: SelectProps) {
  return (
    <Select {...props} placeholder="Select a consistency">
      <Select.Option key="solid" value="solid">
        Solid
      </Select.Option>
      <Select.Option key="semi-solid" value="semi-solid">
        Semi-solid
      </Select.Option>
      <Select.Option key="powdered" value="powdered">
        Powdered
      </Select.Option>
      <Select.Option key="liquid" value="liquid">
        Liquid
      </Select.Option>
    </Select>
  );
}
