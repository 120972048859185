import { Badge, Button, Input, PageHeader, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Content } from "antd/lib/layout/layout";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AddDish from "./AddDish";
import {
  Dish,
  GetDishesQuery,
  useGetDishCategoriesQuery,
  useGetDishesQuery,
} from "../generated/graphql";
import EditDish from "./EditDish";
import { useState } from "react";
import { differenceInDays, format, formatDistance } from "date-fns";
import AddPrepModal from "../Preps/components/AddPrepModal";
import Table from "../components/NavAwareTable";
import ViewDish from "./ViewDish";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate("/")}
        title="Dishes"
        subTitle="That's what you'll eat !"
        extra={[
          <Button key="1" type="primary" onClick={() => navigate("add")}>
            Add Dish
          </Button>,
        ]}
      />
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Space
          size="large"
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          {children}
        </Space>
      </Content>
    </>
  );
};

export default function Dishes() {
  const navigate = useNavigate();
  const { loading, error, data } = useGetDishesQuery();
  const { data: dishCategories } = useGetDishCategoriesQuery();
  const [nameFilter, setNameFilter] = useState("");

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return <PageLayout>{`Error : ${error}`}</PageLayout>;
  }

  const columns: ColumnsType<GetDishesQuery["Dish"][0]> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
      render: (text: string, record) => (
        <Link to={`/dishes/view/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "category_id",
      key: "category",
      render: (_, record) => record.DishCategory?.name,
      filterMultiple: false,
      onFilter: (value, record) =>
        record.DishCategory?.name.indexOf(value.toString()) === 0,
    },
    {
      title: "Preps count",
      render: (_, record) => (
        <Badge
          showZero
          count={record.Preps_aggregate.aggregate?.count || 0}
          color={
            record.Preps_aggregate.aggregate?.count ? "#52c41a" : "#f5222d"
          }
        />
      ),
    },
    {
      title: "Last updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record) => {
        const updatedAt = new Date(`${record}Z`);
        if (differenceInDays(Date.now(), updatedAt) <= 1) {
          return (
            <div>
              {formatDistance(updatedAt, Date.now(), { addSuffix: true })}
            </div>
          );
        }
        return <div>{format(updatedAt, "dd/MM/yyyy")}</div>;
      },
    },
    {
      title: "Actions",
      key: "action",
      align: "right",
      render: (_, record) => (
        <>
          <Link to={`edit/${record.id}`}>Edit</Link> •{" "}
          <AddPrepModal
            name={record.name!}
            dish={record as Dish}
            triggerComponent={(onClick) => <a onClick={onClick}>Add Prep</a>}
            onCreated={(id) => navigate(`/preps/view/${id}`)}
          />
        </>
      ),
    },
  ];

  if (dishCategories) {
    columns[1].filters = dishCategories.DishCategory.map((dishCategory) => ({
      text: dishCategory.name,
      value: dishCategory.name,
    }));
  }

  const dataSource = data?.Dish.map((dish) => ({
    key: `${data.Dish.indexOf(dish) + 1}`,
    ...dish,
  })).filter(
    (dish) =>
      !nameFilter || dish.name?.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Input
              placeholder="Filter by name"
              autoFocus
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Table dataSource={dataSource} columns={columns} />
          </PageLayout>
        }
      />
      <Route path="add" element={<AddDish />} />

      <Route path="view/:id" element={<ViewDish />} />
      <Route path="edit/:id" element={<EditDish />} />
    </Routes>
  );
}
