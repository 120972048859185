import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetMainPrepsDocument,
  GetPrepDocument,
  GetPrepsDocument,
  useGetPrepQuery,
  useUpdatePrepMutation,
} from "../generated/graphql";
import DishSelect from "./components/DishSelect";
import TechnicSelect from "./components/TechnicSelect";
import MainPrepsSelect from "./components/MainPrepsSelect";
import TextArea from "antd/lib/input/TextArea";
import MeasureSelect from "../Ingredients/components/MeasureSelect";
import TimeInput from "../components/TimeInput";

export default function EditPrep() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, data: prep } = useGetPrepQuery({ variables: { id } });
  const [updatePrep] = useUpdatePrepMutation();

  const handleFinish = async ({
    name,
    description,
    time_ahead,
    measure_unit,
    serving_size,
    duration,
    rest_time,
    rest_conditions,
    technic,
    dish,
    main_prep_id,
  }: any) => {
    const refetchQueries =
      main_prep_id || (!main_prep_id && !!prep?.Prep_by_pk?.MainPrep)
        ? [
            { query: GetPrepsDocument },
            { query: GetPrepDocument, variables: { id } },
            { query: GetMainPrepsDocument },
            {
              query: GetPrepDocument,
              variables: { id: main_prep_id || prep?.Prep_by_pk?.MainPrep?.id },
            },
          ]
        : [
            { query: GetPrepsDocument },
            { query: GetPrepDocument, variables: { id } },
          ];

    const prepUpdate = await updatePrep({
      variables: {
        id,
        name,
        description,
        time_ahead,
        measure_unit,
        serving_size,
        duration,
        rest_time,
        rest_conditions,
        technic_id: technic ? technic.value : undefined,
        dish_id: dish ? dish.value : undefined,
        main_prep_id,
      },
      refetchQueries,
    });

    if (prepUpdate.errors) {
      return message.error(prepUpdate.errors.toString());
    }

    navigate(-1);
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error(errorInfo);
  };

  if (loading) {
    return <div>Loading prep</div>;
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={prep?.Prep_by_pk?.name}
        rules={[
          { required: true, message: "Please input a name for the prep !" },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        initialValue={prep?.Prep_by_pk?.description}
        rules={[{ required: false }]}
      >
        <TextArea />
      </Form.Item>

      <Form.Item
        label="Time ahead"
        name="time_ahead"
        initialValue={prep?.Prep_by_pk?.time_ahead}
        rules={[{ required: false }]}
      >
        <TimeInput />
      </Form.Item>

      <Form.Item
        label="Measure unit"
        name="measure_unit"
        initialValue={prep?.Prep_by_pk?.measure_unit}
        rules={[{ required: true }]}
      >
        <MeasureSelect />
      </Form.Item>

      <Form.Item
        label="Serving size"
        name="serving_size"
        initialValue={prep?.Prep_by_pk?.serving_size}
        rules={[{ required: true }]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Duration"
        name="duration"
        initialValue={prep?.Prep_by_pk?.duration}
        rules={[{ required: true }]}
      >
        <TimeInput />
      </Form.Item>

      <Form.Item
        label="Rest time"
        name="rest_time"
        initialValue={prep?.Prep_by_pk?.rest_time}
      >
        <TimeInput />
      </Form.Item>

      <Form.Item
        label="Rest conditions"
        name="rest_conditions"
        initialValue={prep?.Prep_by_pk?.rest_conditions}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Technic"
        name="technic"
        initialValue={
          prep?.Prep_by_pk?.Technic
            ? {
                label: prep.Prep_by_pk.Technic.name,
                value: prep.Prep_by_pk.Technic.id,
              }
            : undefined
        }
        rules={[{ required: false }]}
      >
        <TechnicSelect />
      </Form.Item>

      <Form.Item
        label="Dish"
        name="dish"
        initialValue={
          prep?.Prep_by_pk?.Dish
            ? {
                label: prep.Prep_by_pk.Dish.name,
                value: prep.Prep_by_pk.Dish.id,
              }
            : undefined
        }
        rules={[{ required: false }]}
      >
        <DishSelect />
      </Form.Item>

      <Form.Item
        label="Main prep"
        name="main_prep_id"
        initialValue={prep?.Prep_by_pk?.MainPrep?.id}
        rules={[{ required: false }]}
      >
        <MainPrepsSelect />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          {!!prep ? "Update" : "Add"}
        </Button>
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}
