import { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Input } from "antd";
import { useDrag, useDrop } from "react-dnd";
import Title from "antd/lib/typography/Title";
import { CloseCircleOutlined } from "@ant-design/icons";

interface ItemCardProps {
  id: string;
  name: string;
  itemType: string;
  onDrop: (arg0: string) => void;
  canDelete?: boolean;
  onDelete?: () => void;
}

interface DropResult {
  name: string;
}

const ItemCard = ({
  id,
  name,
  itemType,
  canDelete = false,
  onDrop,
  onDelete,
}: ItemCardProps) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: itemType,
      item: { id },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>();
        if (item && dropResult) {
          onDrop(item.id);
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );

  return (
    <div ref={dragRef} style={{ opacity }}>
      <Card>
        <div>{name}</div>
        {canDelete && <CloseCircleOutlined onClick={onDelete} />}
      </Card>
    </div>
  );
};

type Item = {
  id: string;
  name: string;
};

interface SelectedAreaProps {
  items: Item[];
  itemType: string;
  onDelete: (id: string) => void;
}

const SelectedArea = ({ items, itemType, onDelete }: SelectedAreaProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: itemType,
    drop: () => ({ name: `${itemType}-selected-area` }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = "#222";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  return (
    <div ref={drop} style={{ backgroundColor }}>
      {isActive ? "Release to drop" : "Drag a box here"}
      <div>
        {items.map((item) => (
          <ItemCard
            id={item.id}
            name={item.name}
            itemType={itemType}
            key={item.id}
            onDrop={() => {}}
            canDelete={true}
            onDelete={() => {
              onDelete(item.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

type ItemPickerProps = {
  items?: Item[];
  itemType: string;
  selectedItems?: Item[];
  onItemFilterUpdated: (filter: string) => void;
  onSelectedItemsUpdated: (items?: Item[]) => void;
};

export default function ItemPicker({
  items,
  itemType,
  selectedItems = [],
  onItemFilterUpdated,
  onSelectedItemsUpdated,
}: ItemPickerProps) {
  const [available, setAvailable] = useState<Item[] | undefined>([]);
  const [picked, setPicked] = useState<Item[]>(selectedItems);

  useEffect(() => {
    const notPicked = items?.filter(
      (item) => !picked.find((pickedItem) => pickedItem.id === item.id)
    );
    setAvailable(notPicked);
    onSelectedItemsUpdated(picked);
  }, [items, picked, onSelectedItemsUpdated]);

  const onCardDropped = (itemId: string) => {
    const item = items?.find((item) => item.id === itemId);

    if (item) {
      setPicked((picked) => [...picked, item]);
    }
  };

  const onCardDeleted = (itemId: string) => {
    const item = picked.findIndex((item) => item.id === itemId);

    if (item > -1) {
      setPicked((picked) => [
        ...picked.slice(0, item),
        ...picked.slice(item + 1, picked.length),
      ]);
    }
  };

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    onItemFilterUpdated(e.currentTarget.value);
  };

  return (
    <div className="ItemsPicker">
      <Row>
        <Col offset={2}>
          <Title level={3}>{itemType}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={2}>
          <Input className="ItemsPickerSearch" onChange={onInputChange}></Input>
        </Col>
      </Row>
      <Row>
        <Col span={10} offset={2}>
          {available?.map((item) => (
            <ItemCard
              id={item.id}
              name={item.name}
              itemType={itemType}
              key={item.id}
              onDrop={onCardDropped}
            />
          ))}
        </Col>
        <Col span={10}>
          <SelectedArea
            items={picked}
            itemType={itemType}
            onDelete={onCardDeleted}
          />
        </Col>
      </Row>
    </div>
  );
}
