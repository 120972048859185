import { message } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetIngredientDocument,
  GetIngredientsDocument,
  Ingredient,
  SearchIngredientDocument,
  useGetIngredientQuery,
  useUpdateIngredientMutation,
} from "../generated/graphql";
import IngredientForm from "./components/IngredientForm";

export default function EditIngredient() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading: ingredientLoading, data: ingredient } =
    useGetIngredientQuery({ variables: { id } });
  const [updateIngredient, { data, loading, error }] =
    useUpdateIngredientMutation({
      refetchQueries: [
        { query: GetIngredientDocument, variables: { id } },
        { query: GetIngredientsDocument },
        { query: SearchIngredientDocument, variables: { name: "" } },
      ],
    });

  const onFinish = (
    name: string,
    type: string,
    measureUnit: string,
    consistency: string,
    canSplit: boolean,
    avgItemQuantity: number | null,
    ambientTempTime: number | null
  ) => {
    updateIngredient({
      variables: {
        id,
        name,
        type,
        consistency,
        measure_unit: measureUnit,
        can_split: canSplit,
        avg_item_quantity: avgItemQuantity,
        ambient_temp_time: ambientTempTime,
      },
    });
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.update_Ingredient_by_pk) {
      navigate(-1);
    }
  }, [data, navigate]);

  if (ingredientLoading) {
    return <div>Loading ingredient</div>;
  }

  return (
    <IngredientForm
      ingredient={ingredient?.Ingredient_by_pk as Ingredient}
      loading={loading}
      onSave={onFinish}
    />
  );
}
