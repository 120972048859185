import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Tool } from "../../generated/graphql";
import TimeInput from "../../components/TimeInput";

type ToolFormProps = {
  tool?: Partial<Tool>;
  loading: boolean;
  showButtons?: boolean;
  onSave: (name: string, pre_heat_time?: number) => void;
};

export default function ToolForm({
  tool,
  loading,
  showButtons = true,
  onSave,
}: ToolFormProps) {
  const navigate = useNavigate();
  const onFinish = ({ name, pre_heat_time = 0 }: any) => {
    onSave(name, pre_heat_time);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  console.log(tool);

  return (
    <Form
      id="ToolForm"
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={tool?.name}
        rules={[
          { required: true, message: "Please input a name for the tool !" },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        label="Pre heat time"
        name="pre_heat_time"
        initialValue={tool?.pre_heat_time}
        rules={[{ required: false }]}
      >
        <TimeInput />
      </Form.Item>

      {showButtons && (
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {!!tool ? "Update" : "Add"}
          </Button>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
        </Form.Item>
      )}
    </Form>
  );
}
