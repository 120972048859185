import { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { Input } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import update from "immutability-helper";

type Item = {
  id: string;
  name: string;
};

export type QuantifiedItem = {
  item: Item;
  quantity: number;
};

type AvailableItemsProps = {
  items?: Item[];
  onSelect: (id: string) => void;
};

const AvailableItems = ({ items, onSelect }: AvailableItemsProps) => {
  return (
    <div>
      {items?.map((item) => (
        <div key={item.id} onClick={() => onSelect(item.id)}>
          {item.name}
        </div>
      ))}
    </div>
  );
};

type PickedItemsProps = {
  items?: QuantifiedItem[];
  onQuantityUpdate: (id: string, quantity: number) => void;
  onDelete: (id: string) => void;
};

const PickedItems = ({
  items,
  onQuantityUpdate,
  onDelete,
}: PickedItemsProps) => {
  const quantitySum = items?.reduce((sum, item) => sum + item.quantity, 0);
  return (
    <div>
      <div>Total quantity: {quantitySum}</div>
      {items?.map((item) => (
        <Card key={item.item.id}>
          <div>{item.item.name}</div>
          <Input
            type="number"
            value={item.quantity}
            onChange={(e) => {
              if (parseInt(e.currentTarget.value) <= 0) {
                return e.preventDefault();
              }

              onQuantityUpdate(item.item.id, parseInt(e.currentTarget.value));
            }}
          />
          <CloseCircleOutlined onClick={() => onDelete(item.item.id)} />
        </Card>
      ))}
    </div>
  );
};

type ItemPickerProps = {
  items?: Item[];
  selectedItems?: QuantifiedItem[];
  onItemFilterUpdated: (filter: string) => void;
  onSelectedItemsUpdated: (items?: QuantifiedItem[]) => void;
};

export default function QuantifiedItemPicker({
  items,
  selectedItems = [],
  onItemFilterUpdated,
  onSelectedItemsUpdated,
}: ItemPickerProps) {
  const [available, setAvailable] = useState<Item[] | undefined>([]);
  const [picked, setPicked] = useState<QuantifiedItem[]>(selectedItems);

  useEffect(() => {
    const notPicked = items?.filter(
      (item) => !picked.find((pickedItem) => pickedItem.item.id === item.id)
    );
    setAvailable(notPicked);
    onSelectedItemsUpdated(picked);
  }, [items, onSelectedItemsUpdated, picked]);

  const onItemAdded = (itemId: string) => {
    const item = items?.find((item) => item.id === itemId);

    if (item) {
      setPicked((picked) => [...picked, { quantity: 1, item }]);
    }
  };

  const onPickedItemQuantityUpdate = (itemId: string, quantity: number) => {
    const item = picked.findIndex((item) => item.item.id === itemId);
    setPicked(update(picked, { [item]: { quantity: { $set: quantity } } }));
  };

  const onPickedItemDeleted = (itemId: string) => {
    const item = picked.findIndex((item) => item.item.id === itemId);

    if (item > -1) {
      setPicked((picked) => [
        ...picked.slice(0, item),
        ...picked.slice(item + 1, picked.length),
      ]);
    }
  };

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    onItemFilterUpdated(e.currentTarget.value);
  };

  return (
    <div className="ItemsPicker">
      <Row>
        <Col span={24}>
          <PickedItems
            items={picked}
            onQuantityUpdate={onPickedItemQuantityUpdate}
            onDelete={onPickedItemDeleted}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input className="ItemsPickerSearch" onChange={onInputChange}></Input>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AvailableItems items={available} onSelect={onItemAdded} />
        </Col>
      </Row>
    </div>
  );
}
