import { Link, useParams } from "react-router-dom";
import { useGetPrepQuery } from "../generated/graphql";
import { getHumanFriendlyTime } from "../components/TimeInput";
import { useState } from "react";
import { Select } from "antd";
import useGetAggregatedPrep, {
  getPrepDuration,
} from "./hooks/UseGetAggregatedPrep";
const { Option } = Select;

export default function ViewFlatPrep() {
  const { id } = useParams();
  const { data, loading } = useGetPrepQuery({ variables: { id } });
  const [servings, setServings] = useState(1);
  const aggregatedPrep = useGetAggregatedPrep(data?.Prep_by_pk, servings);

  if (loading || !aggregatedPrep) {
    return <div>Loading prep</div>;
  }

  if (!data?.Prep_by_pk) {
    return <div>Prep not found</div>;
  }

  const prep = data.Prep_by_pk;
  const { totalTime, ingredients, technics, tools, allPreps } = aggregatedPrep;

  return (
    <div>
      <Link to={`/preps/edit/${prep.id}`}>Edit</Link>
      <div>
        <label>Servings : </label>
        <Select
          value={servings}
          style={{ width: 200, margin: "0 8px" }}
          onChange={(val) => setServings(val)}
        >
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="4">4</Option>
          <Option value="8">8</Option>
        </Select>
      </div>
      <div>Name: {prep.name}</div>
      <div>
        Serving size : {servings * prep.serving_size} {prep.measure_unit}{" "}
      </div>
      <div>Total time : {getHumanFriendlyTime(totalTime)}</div>
      <div>Rest time : {getHumanFriendlyTime(prep.rest_time)}</div>
      {!!prep.rest_conditions && (
        <div>Rest conditions : {prep.rest_conditions}</div>
      )}
      <div>
        <h3>Skills ({technics.size})</h3>
        <ul>
          {Array.from(technics).map((technic) => (
            <li key={technic}>{technic}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Tools ({tools.size})</h3>
        <ul>
          {Array.from(tools).map((tool) => (
            <li key={tool}>{tool}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Ingredients ({ingredients.size})</h3>
        <ul>
          {Array.from(ingredients).map(([name, quantity]) => (
            <li key={name}>
              <strong>{name}</strong> - Quantity: {quantity}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Preps ({allPreps.length + 1})</h3>
        <ul>
          {allPreps.map((flatPrep) => (
            <li key={flatPrep.prep.id}>
              <strong>{flatPrep.prep.name}</strong> - Duration:{" "}
              <span style={{ color: !flatPrep.prep.duration ? "red" : "auto" }}>
                {getHumanFriendlyTime(
                  getPrepDuration(prep, flatPrep, servings)
                )}
              </span>
              {" • "}
              <span>
                Quantity: {flatPrep.ratio * servings * prep.serving_size}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
