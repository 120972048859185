import { Link, useParams } from "react-router-dom";
import {
  PrepStep,
  Prep_Ingredients,
  Prep_Preps,
  Tool,
  useGetPrepQuery,
} from "../generated/graphql";
import IngredientsModal from "./components/IngredientsModal";
import ToolsModal from "./components/ToolsModal";
import PrepsModal from "./components/PrepsModal";
import StepsModal from "./components/StepsModal";
import { getHumanFriendlyTime } from "../components/TimeInput";

export default function ViewPrep() {
  const { id } = useParams();
  const { data, loading } = useGetPrepQuery({ variables: { id } });

  if (loading) {
    return <div>Loading prep</div>;
  }

  if (!data?.Prep_by_pk) {
    return <div>Prep not found</div>;
  }

  const prep = data.Prep_by_pk;

  return (
    <div>
      <Link to={`/preps/edit/${prep.id}`}>Edit</Link>
      <div>Name: {prep.name}</div>
      <div>Duration: {getHumanFriendlyTime(prep.duration)}</div>
      {prep.time_ahead !== null && prep.time_ahead !== undefined && (
        <div>Time ahead: {getHumanFriendlyTime(prep.time_ahead)}</div>
      )}
      <div>Measure unit : {prep?.measure_unit}</div>
      <div>Serving size : {prep?.serving_size}</div>
      <div>Rest time : {getHumanFriendlyTime(prep.rest_time)}</div>
      {!!prep.rest_conditions && (
        <div>Rest conditions : {prep.rest_conditions}</div>
      )}
      {prep.Dish && <div>Dish: {prep.Dish.name}</div>}
      {prep.MainPrep && (
        <div>
          Main prep :{" "}
          <Link to={`/preps/view/${prep.MainPrep.id}`}>
            {prep.MainPrep.name}
          </Link>
        </div>
      )}
      {!!prep.AlternativePreps.length && (
        <div>
          Alternative preps :{" "}
          <ul>
            {prep.AlternativePreps.map((prep) => (
              <li key={prep.id}>
                <Link to={`/preps/view/${prep.id}`}>{prep.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        Ingredients :
        <IngredientsModal
          prepId={prep.id}
          initialIngredients={prep.Ingredients as Prep_Ingredients[]}
        />
        <ul>
          {[...prep.Ingredients]
            .sort((ingr1, ingr2) => ingr2.quantity - ingr1.quantity)
            .map((ingredient) => (
              <li key={ingredient.Ingredient.id}>
                {(prep.serving_size * ingredient.quantity) / 100}{" "}
                {ingredient.Ingredient.measure_unit}{" "}
                {ingredient.Ingredient.name} ({ingredient.quantity}%)
              </li>
            ))}
        </ul>
      </div>
      {!!prep.Technic && <div>Technic: {prep.Technic.name}</div>}

      <div>
        Tools:
        <ToolsModal
          prepId={prep.id}
          initialTools={prep.Tools.map((tool) => tool.Tool) as Tool[]}
        />
        <ul>
          {prep.Tools.map((tool) => (
            <li key={tool.Tool.id}>
              <Link to={`/preps/view/${tool.Tool.id}`}>{tool.Tool.name}</Link>
            </li>
          ))}
        </ul>
      </div>

      <div>
        Preps :
        <PrepsModal
          prepId={prep.id}
          initialPreps={prep.InputPreps as Prep_Preps[]}
        />
        <ul>
          {[...prep.InputPreps]
            .sort((prep1, prep2) => prep2.quantity - prep1.quantity)
            .map((inputPrep) => (
              <li key={inputPrep.Prep.id}>
                {(prep.serving_size * inputPrep.quantity) / 100}{" "}
                {inputPrep.Prep.measure_unit}{" "}
                <Link to={`/preps/view/${inputPrep.Prep.id}`}>
                  {inputPrep.Prep.name}
                </Link>{" "}
                ({inputPrep.quantity}%)
              </li>
            ))}
        </ul>
      </div>

      {!!prep.description && <div>Description : {prep.description}</div>}

      <div>
        Steps:
        <StepsModal prepId={prep.id} initialSteps={prep.Steps as PrepStep[]} />
        <ul>
          {!!prep.Steps &&
            [...prep.Steps]
              .sort((step1, step2) => step1.order - step2.order)
              .map((step) => (
                <li key={step.id}>
                  {step.order} • {step.name} : {step.description}
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
}
