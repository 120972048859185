import { message } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetToolDocument,
  GetToolsDocument,
  Tool,
  useGetToolQuery,
  useUpdateToolMutation,
} from "../generated/graphql";
import ToolForm from "./components/ToolForm";

export default function EditTool() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading: toolLoading, data } = useGetToolQuery({ variables: { id } });
  const [updateTool, { data: updateData, loading, error: updateError }] =
    useUpdateToolMutation({
      refetchQueries: [
        { query: GetToolsDocument },
        { query: GetToolDocument, variables: { id } },
      ],
    });
  const onSave = (name: string, pre_heat_time?: number) => {
    updateTool({ variables: { id, name, pre_heat_time } });
  };

  useEffect(() => {
    if (updateError) {
      console.error(updateError);
      message.error(updateError.message);
    }
  }, [updateError]);

  useEffect(() => {
    if (updateData?.update_Tool?.affected_rows) {
      navigate(-1);
    }
  }, [updateData, navigate]);

  if (toolLoading) {
    return <div>Loading tool</div>;
  }

  return (
    <ToolForm
      tool={data?.Tool_by_pk as Tool}
      loading={loading}
      onSave={onSave}
    />
  );
}
