import { Button, PageHeader, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddRecipe from "./AddRecipe";
import { useGetRecipesQuery } from "../generated/graphql";

type PageLayoutProps = {
  children: React.ReactNode;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
  <>
    <PageHeader
      className="site-page-header"
      onBack={() => navigate('/')}
      title="Recipes"
        subTitle="It all comes down to that"
        extra={[
          <Button key="1" type="primary" onClick={() => navigate('add')}>Add Recipe</Button>
        ]}
    />
    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
      {children}
    </Content>
    </>
  );
}

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }];

export default function Recipes() {
  const { loading, error, data } = useGetRecipesQuery();

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return (<PageLayout>{`Error : ${error}`}</PageLayout>);
  }

  const dataSource = data?.Recipe.map(recipe => ({
    key: `${data.Recipe.indexOf(recipe) + 1}`,
    ...recipe
  }));

  return (
    <Routes>
      <Route path="/" element={<PageLayout><Table dataSource={dataSource} columns={columns} /></PageLayout>} />
      <Route path="add" element={<AddRecipe />} />
    </Routes>
  );
}
