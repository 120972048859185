import { Select, SelectProps } from "antd";

export default function TypeSelect(props: SelectProps) {
  return (
    <Select {...props} placeholder="Select a type">
      <Select.Option key="grains" value="grains">
        Grains
      </Select.Option>
      <Select.Option key="legumes" value="legumes">
        Legumes
      </Select.Option>
      <Select.Option key="fishes" value="fishes">
        Fishes
      </Select.Option>
      <Select.Option key="dairy" value="dairy">
        Dairy
      </Select.Option>
      <Select.Option key="eggs" value="eggs">
        Eggs
      </Select.Option>
      <Select.Option key="meat" value="meat">
        Meat
      </Select.Option>
      <Select.Option key="poultry" value="poultry">
        Poultry
      </Select.Option>
      <Select.Option key="nuts" value="nuts">
        Nuts
      </Select.Option>
      <Select.Option key="seeds" value="seeds">
        Seeds
      </Select.Option>
      <Select.Option key="fruits" value="fruits">
        Fruits
      </Select.Option>
      <Select.Option key="vegetables" value="vegetables">
        Vegetables
      </Select.Option>
      <Select.Option key="herbs" value="herbs">
        Herbs
      </Select.Option>
      <Select.Option key="spices" value="spices">
        Spices
      </Select.Option>
      <Select.Option key="oils" value="oils">
        Oils
      </Select.Option>
      <Select.Option key="sauces" value="sauces">
        Sauces
      </Select.Option>
      <Select.Option key="condiments" value="condiments">
        Condiments
      </Select.Option>
      <Select.Option key="baking" value="baking">
        Baking
      </Select.Option>
      <Select.Option key="other" value="other">
        Other
      </Select.Option>
    </Select>
  );
}
