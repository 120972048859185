import { useCallback, useEffect, useState } from "react";
import { GetPrepQuery, useGetPrepLazyQuery } from "../../generated/graphql";

type PrepWithRatio = {
  prep: NonNullable<GetPrepQuery["Prep_by_pk"]>;
  ratio: number;
};

export const getPrepDuration = (
  prep: NonNullable<GetPrepQuery["Prep_by_pk"]>,
  flatPrep: PrepWithRatio,
  servings: number
) => {
  if (flatPrep.prep.Technic?.type !== "raw") return flatPrep.prep.duration;

  return (
    flatPrep.prep.duration *
    ((flatPrep.ratio * servings * prep.serving_size) /
      flatPrep.prep.serving_size)
  );
};

export default function useGetAggregatedPrep(
  prep: GetPrepQuery["Prep_by_pk"],
  servings: number = 1
) {
  const [loadPrep] = useGetPrepLazyQuery();

  const [allPreps, setAllPreps] = useState<Array<PrepWithRatio>>();

  const getDeepPreps = useCallback(
    async (
      preps: NonNullable<GetPrepQuery["Prep_by_pk"]>["InputPreps"],
      ratio: number
    ): Promise<Array<PrepWithRatio>> => {
      const deepPreps = [];

      for (let prep of preps) {
        let fullPrep = await loadPrep({
          variables: { id: prep.Prep.id },
        });
        console.log(
          `Base ratio for ${fullPrep.data?.Prep_by_pk?.name}: ${ratio} and qty : ${prep.quantity}`
        );

        if (!fullPrep.data?.Prep_by_pk) {
          console.error("Failed loading prep", fullPrep.error);
          continue;
        }

        if (fullPrep.data.Prep_by_pk.InputPreps.length) {
          const deeperPreps = await getDeepPreps(
            fullPrep.data.Prep_by_pk.InputPreps,
            ratio * (prep.quantity / 100)
          );
          deepPreps.push(...deeperPreps);
        }

        deepPreps.push({
          ratio: ratio * (prep.quantity / 100),
          prep: fullPrep.data.Prep_by_pk,
        });
      }
      return deepPreps;
    },
    [loadPrep]
  );

  useEffect(() => {
    async function fetch() {
      if (!prep) {
        return;
      }

      const deepPreps = await getDeepPreps(prep.InputPreps, 1);

      setAllPreps(deepPreps);
    }

    fetch();
  }, [prep, getDeepPreps]);

  if (!prep || !allPreps) return null;

  const totalTime =
    getPrepDuration(prep, { prep, ratio: 1 }, servings) +
    allPreps.reduce(
      (acc, flatPrep) => acc + getPrepDuration(prep, flatPrep, servings),
      0
    );

  const ingredients = new Map();
  const technics = new Set<string>();
  const tools = new Set<string>();

  for (let flatPrep of allPreps) {
    flatPrep.prep.Ingredients.forEach((prepIngredient) => {
      if (ingredients.has(prepIngredient.Ingredient.name)) {
        ingredients.set(
          prepIngredient.Ingredient.name,
          ingredients.get(prepIngredient.Ingredient.name) +
            prepIngredient.quantity
        );
      } else {
        ingredients.set(
          prepIngredient.Ingredient.name,
          (flatPrep.ratio *
            servings *
            prep.serving_size *
            prepIngredient.quantity) /
            100
        );
      }
    });
    if (flatPrep.prep.Technic) technics.add(flatPrep.prep.Technic.name);
    flatPrep.prep.Tools.forEach((prepTool) => tools.add(prepTool.Tool.name));
  }

  return {
    totalTime,
    ingredients,
    technics,
    tools,
    allPreps,
  };
}
