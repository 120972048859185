import { Link, useParams } from "react-router-dom";
import { useGetDishQuery } from "../generated/graphql";

export default function ViewDish() {
  const { id } = useParams();
  const { data, loading } = useGetDishQuery({ variables: { id } });

  if (loading) {
    return <div>Loading dish</div>;
  }

  if (!data?.Dish_by_pk) {
    return <div>Failed loading dish</div>;
  }

  const { Dish_by_pk: dish } = data;

  return (
    <div>
      <Link to={`/dishes/edit/${dish.id}`}>Edit</Link>
      <div>Name: {dish.name}</div>
      <div>Kind: {dish.kind}</div>
      <div>Category: {dish.DishCategory?.name}</div>
      {dish.Preps.length > 0 && (
        <div>
          Preps:{" "}
          <ul>
            {dish.Preps.map((dishPrep) => (
              <li key={dishPrep.id}>
                <Link to={`/preps/view/${dishPrep.id}`}>{dishPrep.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
