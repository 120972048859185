import { Button, Modal } from "antd";
import { useCallback, useState } from "react";

import {
  GetMainPrepsDocument,
  GetPrepDocument,
  GetPrepsDocument,
  Prep_Preps,
  useAddPrepPrepsMutation,
  useDeletePrepPrepsMutation,
  useUpdatePrepPrepsMutation,
} from "../../generated/graphql";

import PrepPicker, { PickedPreps } from "./PrepsPicker";

type PrepWithQuantity = {
  quantity: number;
  Prep: Item;
};

type Item = {
  id: string;
};

const getItemDiff = (
  initialItems: PrepWithQuantity[] = [],
  newItems: PrepWithQuantity[] = []
) => {
  return [
    newItems?.filter(
      (item) => !initialItems.map((item) => item.Prep.id).includes(item.Prep.id)
    ),
    newItems?.filter((item) => {
      const initItem = initialItems.find(
        (initialItem) => initialItem.Prep.id === item.Prep.id
      );

      return initItem && initItem.quantity !== item.quantity;
    }),
    initialItems.filter(
      (item) => !newItems.map((item) => item.Prep.id).includes(item.Prep.id)
    ),
  ];
};

type PrepsModalProps = {
  prepId: string;
  initialPreps?: Prep_Preps[];
};

export default function PrepsModal({ prepId, initialPreps }: PrepsModalProps) {
  const [open, setOpen] = useState(false);
  const [preps, setPreps] = useState<PickedPreps | undefined>();
  const refetchQueries = [
    { query: GetPrepsDocument },
    { query: GetMainPrepsDocument },
    { query: GetPrepDocument, variables: { id: prepId } },
  ];

  const [addPrepPreps, { loading: addLoading }] = useAddPrepPrepsMutation({
    refetchQueries,
  });
  const [updatePrepPreps, { loading: updateLoading }] =
    useUpdatePrepPrepsMutation({ refetchQueries });
  const [deletePrepPreps, { loading: deleteLoading }] =
    useDeletePrepPrepsMutation({
      refetchQueries,
    });

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    const [addedPreps, updatedPreps, deletedPreps] = getItemDiff(
      initialPreps,
      preps
    );

    if (addedPreps.length) {
      await addPrepPreps({
        variables: {
          preps: addedPreps?.map((prep) => ({
            input_prep_id: prep.Prep.id,
            prep_id: prepId,
            quantity: prep.quantity,
          })),
        },
      });
    }

    if (updatedPreps.length) {
      await updatePrepPreps({
        variables: {
          preps: updatedPreps.map((prep) => ({
            where: {
              prep_id: { _eq: prepId },
              input_prep_id: { _eq: prep.Prep.id },
            },
            _set: { quantity: prep.quantity },
          })),
        },
      });
    }

    if (deletedPreps.length) {
      await deletePrepPreps({
        variables: {
          where: {
            _and: [
              { prep_id: { _eq: prepId } },
              {
                input_prep_id: {
                  _in: deletedPreps.map((prep) => prep.Prep.id),
                },
              },
            ],
          },
        },
      });
    }

    setOpen(false);
  };

  const handlePrepsUpdated = useCallback((preps?: PickedPreps) => {
    setPreps(preps);
  }, []);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit preps
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Edit prep preps"
        open={open}
        onOk={handleOk}
        confirmLoading={addLoading || updateLoading || deleteLoading}
        onCancel={handleCancel}
      >
        <PrepPicker
          initialPreps={initialPreps}
          onPickedPrepsUpdated={handlePrepsUpdated}
        />
      </Modal>
    </>
  );
}
