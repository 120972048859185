import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4oGwAhI0TgOwQtBMmo2p2HSsY29q8KuU",
  authDomain: "manifest-zephyr-339214.firebaseapp.com",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
  }
};

type UserContext = {
  user: User | null;
  signOut: () => void;
};

const signUserOut = () => signOut(auth);
export const CurrentUserContext = createContext<UserContext>({
  user: null,
  signOut: signUserOut,
});
export const useCurrentUser = () => useContext(CurrentUserContext);

type CurrentUserProviderProps = {
  user: User | null;
  children: React.ReactNode;
};

function CurrentUserProvider({ user, children }: CurrentUserProviderProps) {
  return (
    <CurrentUserContext.Provider value={{ user, signOut: signUserOut }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const [authLoading, setAuthLoading] = useState(
    process.env.NODE_ENV === "production" ? true : false
  );
  const [user, setUser] = useState<User | null>(
    process.env.NODE_ENV === "production"
      ? null
      : {
          uid: "v7NWEIEZmCQCAxz6nOacc4rw4Uu2",
          email: "abarreir@gmail.com",
          emailVerified: true,
          displayName: "Alexandre Barreira",
          isAnonymous: false,
          photoURL:
            "https://lh3.googleusercontent.com/a/AAcHTtcgxxkDRm4FUphj2b2kkIn1K8Of-49yxPoifwduag=s96-c",
          providerData: [
            {
              providerId: "google.com",
              uid: "113887877055957823289",
              displayName: "Alexandre Barreira",
              email: "abarreir@gmail.com",
              phoneNumber: null,
              photoURL:
                "https://lh3.googleusercontent.com/a/AAcHTtcgxxkDRm4FUphj2b2kkIn1K8Of-49yxPoifwduag=s96-c",
            },
          ],
          metadata: {},
          tenantId: null,
          refreshToken:
            "APZUo0Rl85jejqD3ALDFrhlhtyx0AtMxaY76wAH6E72csbAYsvZLgoV7VnE-_6cz8wbhqLWH30pU9hPOzLso4SeQmq61VxMcB1E2rvt-HEPMWLpXqAGkvm5XYDRlzZK2pdHdy82Kn9vwEd1TEe8-9Qtk5IhsB4kpcB-E-Zhr8WicmREm3x5P4AS3p9JuYqYDBUATrOjegJt_mT_cXpn0GIdjvZAgS7DoqxpGHHjvhbyTrkz1cy7muHbZbEGazytP1C4N69UQBPm_oqONLhWVZra1mwvBbaWedwVSUgnrrLR4hE1-_CE9nw13d3VR61ij3Nhnu6Z9ST_yLG1El9SDTTJfrkw22fDNARhm06aiQ26PR-1B_lvcGPYtMIILjrbQP3IMOMod9y2qEUZHUXIMmvX9IjRaEzKAVki4HrapicmEzdDzChtS5wLjuetrdADnnyCbBhYTHCJE",
          delete: () => new Promise(() => {}),
          getIdToken: () => Promise.resolve("coucou"),
          getIdTokenResult: () => new Promise(() => {}),
          reload: () => new Promise(() => {}),
          phoneNumber: null,
          toJSON: () => new Promise(() => {}),
          providerId: "firebase",
        }
  );

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setAuthLoading(false);
      setUser(user);
    });
  }, []);

  if (authLoading) {
    return (
      <CurrentUserProvider user={user}>Checking auth...</CurrentUserProvider>
    );
  }

  if (!user) {
    return (
      <CurrentUserProvider user={user}>
        <button onClick={signInWithGoogle}> Signin with google</button>
      </CurrentUserProvider>
    );
  }

  return <CurrentUserProvider user={user}>{children}</CurrentUserProvider>;
}
