import { Button, Modal } from "antd";
import { useState } from "react";

import {
  GetMainPrepsDocument,
  GetPrepDocument,
  GetPrepsDocument,
  SearchToolsQuery,
  Tool,
  useAddPrepToolsMutation,
  useDeletePrepToolsMutation,
} from "../../generated/graphql";

import ToolPicker from "../../components/ToolPicker";

type Item = {
  id: string;
};

const getItemDiff = (initialItems: Item[] = [], newItems: Item[] = []) => {
  return [
    newItems?.filter(
      (item) => !initialItems.map((item) => item.id).includes(item.id)
    ),
    initialItems.filter(
      (item) => !newItems.map((item) => item.id).includes(item.id)
    ),
  ];
};

type ToolsModalProps = {
  prepId: string;
  initialTools?: Tool[];
};

export default function ToolsModal({ prepId, initialTools }: ToolsModalProps) {
  const [open, setOpen] = useState(false);
  const [tools, setTools] = useState<SearchToolsQuery["Tool"] | undefined>();
  const refetchQueries = [
    { query: GetPrepsDocument },
    { query: GetMainPrepsDocument },
    { query: GetPrepDocument, variables: { id: prepId } },
  ];
  const [addPrepTools, { loading: addLoading }] = useAddPrepToolsMutation({
    refetchQueries,
  });
  const [deletePrepTools, { loading: deleteLoading }] =
    useDeletePrepToolsMutation({
      refetchQueries,
    });

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    const [addedTools, deletedTools] = getItemDiff(initialTools, tools);

    if (addedTools.length) {
      await addPrepTools({
        variables: {
          tools: addedTools?.map((tool) => ({
            tool_id: tool.id,
            prep_id: prepId,
          })),
        },
      });
    }

    if (deletedTools.length) {
      await deletePrepTools({
        variables: {
          where: {
            _and: [
              { prep_id: { _eq: prepId } },
              {
                tool_id: {
                  _in: deletedTools.map((tool) => tool.id),
                },
              },
            ],
          },
        },
      });
    }

    setOpen(false);
  };

  const handleToolsUpdated = (tools?: SearchToolsQuery["Tool"]) => {
    setTools(tools);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit tools
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Edit prep tools"
        open={open}
        onOk={handleOk}
        confirmLoading={addLoading || deleteLoading}
        onCancel={handleCancel}
      >
        <ToolPicker
          initialTools={initialTools}
          onPickedToolsUpdated={handleToolsUpdated}
        />
      </Modal>
    </>
  );
}
