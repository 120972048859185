import { Button, Input, PageHeader, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AddTool from "./AddTool";
import { GetToolsQuery, useGetToolsQuery } from "../generated/graphql";
import { ColumnsType } from "antd/lib/table";
import EditTool from "./EditTool";
import { useState } from "react";
import Table from "../components/NavAwareTable";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate("/")}
        title="Tools"
        subTitle="Don't be one !"
        extra={[
          <Button key="1" type="primary" onClick={() => navigate("add")}>
            Add Tool
          </Button>,
        ]}
      />
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <Space
          size="large"
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          {children}
        </Space>
      </Content>
    </>
  );
};

const columns: ColumnsType<GetToolsQuery["Tool"][0]> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => <Link to={`edit/${record.id}`}>Edit</Link>,
  },
];

export default function Tools() {
  const { loading, error, data } = useGetToolsQuery();
  const [nameFilter, setNameFilter] = useState("");

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return <PageLayout>{`Error : ${error}`}</PageLayout>;
  }

  const dataSource = data?.Tool.map((tool) => ({
    key: `${data.Tool.indexOf(tool) + 1}`,
    ...tool,
  })).filter(
    (tool) =>
      !nameFilter || tool.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Input
              placeholder="Filter by name"
              autoFocus
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />

            <Table dataSource={dataSource} columns={columns} />
          </PageLayout>
        }
      />
      <Route path="add" element={<AddTool />} />
      <Route path="edit/:id" element={<EditTool />} />
    </Routes>
  );
}
