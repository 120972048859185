import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { GetToolsDocument, useAddToolMutation } from "../generated/graphql";
import ToolForm from "./components/ToolForm";

export default function AddTool() {
  const navigate = useNavigate();
  const [addIngredient, { data, loading, error }] = useAddToolMutation({
    refetchQueries: [{ query: GetToolsDocument }],
  });
  const onSave = (name: string, pre_heat_time?: number) => {
    addIngredient({ variables: { id: uuidv4(), name, pre_heat_time } });
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.insert_Tool_one) {
      navigate(-1);
    }
  }, [data, navigate]);

  return <ToolForm loading={loading} onSave={onSave} />;
}
