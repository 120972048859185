import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  GetToolsDocument,
  SearchToolsDocument,
  SearchToolsQuery,
  Tool,
  useAddToolMutation,
  useSearchToolsQuery,
} from "../../generated/graphql";

import ItemPicker from "../ItemPicker";
import { Button, Modal } from "antd";
import ToolForm from "../../Tools/components/ToolForm";

type CreateToolModalProps = {
  name: string;
  onCreated: (toolId: string) => void;
};

function CreateToolModal({ name, onCreated }: CreateToolModalProps) {
  const [open, setOpen] = useState(false);
  const [addTool, { loading }] = useAddToolMutation({
    refetchQueries: [
      { query: GetToolsDocument },
      { query: SearchToolsDocument, variables: { name } },
    ],
  });

  const handleSave = async (name: string, pre_heat_time?: number) => {
    const result = await addTool({
      variables: {
        id: uuidv4(),
        name,
        pre_heat_time,
      },
    });

    onCreated(result.data?.insert_Tool_one?.id);
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Create tool {name}
      </Button>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        title="Create tool"
        open={open}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            form="ToolForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Create
          </Button>,
        ]}
      >
        <ToolForm
          tool={{ name }}
          loading={loading}
          showButtons={false}
          onSave={handleSave}
        />
      </Modal>
    </>
  );
}

type ToolsPickerProps = {
  initialTools?: Tool[];
  onPickedToolsUpdated: (tools?: SearchToolsQuery["Tool"]) => void;
};

export default function ToolPicker({
  initialTools,
  onPickedToolsUpdated,
}: ToolsPickerProps) {
  const [name, setName] = useState("");

  const { data } = useSearchToolsQuery({
    variables: { name },
  });

  const handleFilterChange = (filter: string) => setName(filter);

  return (
    <div className="ToolsPicker">
      <ItemPicker
        items={data?.Tool}
        itemType="tool"
        selectedItems={initialTools}
        onSelectedItemsUpdated={onPickedToolsUpdated}
        onItemFilterUpdated={handleFilterChange}
      />
      {!data?.Tool.length && (
        <CreateToolModal name={name} onCreated={() => {}} />
      )}
    </div>
  );
}
