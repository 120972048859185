import { Button, Input, PageHeader, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AddIngredient from "./AddIngredient";
import {
  GetIngredientsQuery,
  useGetIngredientsQuery,
} from "../generated/graphql";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import EditIngredient from "./EditIngredient";
import TypeTag from "../components/TypeTag";
import Table from "../components/NavAwareTable";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate("/")}
        title="Ingredients"
        subTitle="Can't cook without them !"
        extra={[
          <Button key="1" type="primary" onClick={() => navigate("add")}>
            Add Ingredient
          </Button>,
        ]}
      />
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Space
          size="large"
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          {children}
        </Space>
      </Content>
    </>
  );
};

const columns: ColumnsType<GetIngredientsQuery["Ingredient"][0]> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "40%",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "20%",
    render: (_, record) => <TypeTag type={record.type} />,
  },
  {
    title: "Unit",
    dataIndex: "measure_unit",
    key: "unit",
    width: "20%",
  },
  {
    title: "Avg/item",
    dataIndex: "avg_item_quantity",
    key: "unit",
    width: "10%",
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "actions",
    align: "right",
    render: (record) => {
      return <Link to={`edit/${record}`}>Edit</Link>;
    },
  },
];

export default function Ingredients() {
  const { loading, error, data } = useGetIngredientsQuery();
  const [nameFilter, setNameFilter] = useState("");

  if (loading) {
    return <PageLayout>Loading</PageLayout>;
  }

  if (error) {
    return <PageLayout>{`Error : ${error}`}</PageLayout>;
  }

  const dataSource = data?.Ingredient.map((ingredient) => ({
    key: `${data.Ingredient.indexOf(ingredient) + 1}`,
    ...ingredient,
  })).filter(
    (ingredient) =>
      !nameFilter ||
      ingredient.name.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageLayout>
            <Input
              placeholder="Filter by name"
              autoFocus
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Table dataSource={dataSource} columns={columns} />
          </PageLayout>
        }
      />
      <Route path="add" element={<AddIngredient />} />
      <Route path="edit/:id" element={<EditIngredient />} />
    </Routes>
  );
}
